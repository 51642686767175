import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/core/service/config.service';
import { ItemRequestsService } from 'src/app/features/items-requests/core/service/item-requests.service';
import { SideNavConfigService } from 'src/app/layout/main-layout/side-nav-config.service';

@Component({
  selector: 'app-request-log',
  templateUrl: './request-log.component.html',
  styles: [
  ]
})
export class RequestLogComponent implements OnInit {
  RequestId!: any;
  loading: boolean = false;
  requestLog: any;
  constructor(private _sideNavService: SideNavConfigService,
    private _ItemRequestsService: ItemRequestsService,
    private _config: ConfigService) {

  }
  ngOnInit(): void {
    this._sideNavService.getSideNav().subscribe(data => {
      if (data.status && data.type == 'history') {
        // let Url = this._config._router.url
        // if (Url.includes("/users/profile/")) {
        //   this.userID = Url.split('/').reverse()[0]
        // } else {
        //   this.userID = JSON.parse(this.authUser)?.user?.id
        // }
        this.RequestId = data.id;
        this.getRequestLog(this.RequestId)
      }
    })
  }

  getRequestLog(RequestId: string) {
    this.loading = true;
    this._ItemRequestsService.GetRequestLog(RequestId).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.requestLog = res.data;
      },
      error: (err: any) => {
        this.loading = false;
        this._config.showErrors(err);
      }
    })
  }

  onClose() {
    // this.menu.ToggleWallet(false)
    this._sideNavService.ChangeSideNav({ status: false, type: 'history' })

  }

}
