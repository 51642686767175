import { Component, OnInit } from '@angular/core';
import { SocketService } from 'src/app/features/bidders/core/service/socket.service';
import { MenuService } from '../adminpanel/menu.service';
import { ConfigService } from 'src/app/core/service/config.service';

@Component({
  selector: 'app-biders',
  templateUrl: './biders.component.html',
  styles: []
})
export class BidersComponent implements OnInit {
  toggleDrawer: boolean = false;
  isHome: boolean = false;
  constructor(
    private menu: MenuService,
    private _config: ConfigService,
  ) {

  }

  ngOnInit() {
    this.menu.getWalletStatus().subscribe((res: boolean) => {
      this.toggleDrawer = res
    })
    if (this._config._router.url == "/" || this._config._router.url == "/pages/terms" || this._config._router.url == "/pages/about-us") { 
      this.isHome = true
    }
    else { 
      this.isHome = false
    }
  }





}
