<div class="d-flex" style="height: 100vh">
    <div class="data-empty p-0 m-auto">
        <img src="./assets/images/icons/404.png" />
        <div class="w-100 text-center">
            <h1>{{'items-requests.Something went wrong' | translate}}</h1>
            <p>{{'items-requests.Your request could not be processed' | translate}}</p>

            <button mat-button class="btnLight_fill my-4" routerLink="/"
                *ngIf="_config.getAuthUserInfo()?.user?.role_code=='General'">{{'items-requests.Go To Home' | translate}}</button>
            <button mat-button class="btnLight_fill my-4" routerLink="/admin"
                *ngIf="_config.getAuthUserInfo()?.user?.role_code!='General'">{{'items-requests.Go To Dashboard' | translate}}</button>
        </div>
    </div>
</div>