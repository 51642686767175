import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { WalletService } from '../../services/wallet.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PaymentConfirmationComponent } from '../payment-confirmation/payment-confirmation.component';

@Component({
  selector: 'app-payment-confirmation-page',
  templateUrl: './payment-confirmation-page.component.html',
  styles: [
  ]
})
export class PaymentConfirmationPageComponent implements OnInit {

  dataLoading = false;
  paymentId = null;
  paymentToken = null;

  showErrorMessage: boolean = false;

  constructor(
    private WalletService: WalletService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public translate: TranslateService,
    public location: Location,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // this.location.
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.id) {
        this.paymentId = params.id;
      }
    });
    this.paymentToken = this.route.snapshot.params['token'];
    console.log(this.paymentId, this.paymentToken);
    this.getDataToPaymentGetway()
  }

  getDataToPaymentGetway() {
    this.dataLoading = true;
    this.WalletService.paymentConfirmation(this.paymentToken).subscribe(
      (res) => {
        if (res) {
          const dialogRef = this.dialog.open(PaymentConfirmationComponent, {
            width: '550px',
            panelClass: 'customDialogStyle',
            data: res.data
          });

          dialogRef.afterClosed().subscribe((res: any) => { })
          // this.showSuccess(this.translate.instant('items-requests.Top-up Completed Successfully'));
        }
        this.router.navigate(['/myProfile']);
      },
      (err) => {
        this.dataLoading = false;
        // this.showError(this.translate.instant('pages.something wrong'))
        this.showErrorMessage = true;
      }
    );

  }

  showSuccess(msg: any) {
    this.toastr.success(msg);
  }
  showError(msg: any) {
    this.toastr.error(msg);
  }

}
