<div class="dialog-title">
    <h3 *ngIf="!data"> Add New Staff</h3>
    <h3 *ngIf="data"> Update Staff</h3>
    <span mat-button mat-dialog-close>
        <svg xmlns="http://www.w3.org/2000/svg" width="13.998" height="13.998" viewBox="0 0 13.998 13.998">
            <path id="close-btn"
                d="M8.177-11.911,6.768-13.32l-5.59,5.59-5.59-5.59L-5.82-11.911l5.59,5.59L-5.82-.732-4.411.677l5.59-5.59L6.768.677,8.177-.732l-5.59-5.59Z"
                transform="translate(5.82 13.32)" fill="#bbb" />
        </svg>
    </span>
</div>
<mat-dialog-content>

    <form (ngSubmit)="OnSubmit()" class="register-user" [formGroup]="StaffForm" *ngIf="!loadingData">
        <div class="container-fluid">
            <div class="row">
                <div class="inputs-form-section col-md-6 col-12">
                    <mat-form-field appearance="outline" class="form-group">
                        <mat-label>{{'auth.First Name' | translate}}</mat-label>
                        <input formControlName="firstname" matInput maxlength="20" minlength="3" />
                        <mat-error *ngIf="hasError('firstname', 'required')">
                            {{'validations.required' | translate}}
                        </mat-error>
                        <mat-error *ngIf="hasError('firstname', 'minlength')">
                            {{'validations.minlength' | translate}} 3
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="inputs-form-section col-md-6 col-12">
                    <mat-form-field appearance="outline" class="form-group">
                        <mat-label>{{'auth.Last Name' | translate}}</mat-label>
                        <input formControlName="lastname" matInput maxlength="20" minlength="3" />
                        <mat-error *ngIf="hasError('lastname', 'required')">
                            {{'validations.required' | translate}}
                        </mat-error>
                        <mat-error *ngIf="hasError('lastname', 'minlength')">
                            {{'validations.minlength' | translate}} 3
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>


            <div class="inputs-form-section">
                <mat-form-field appearance="outline" class="form-group">
                    <mat-label>{{'auth.email' | translate}}</mat-label>
                    <input formControlName="email" matInput maxlength="50" />
                    <mat-error *ngIf="hasError('email', 'required')">
                        {{'validations.required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="hasError('email', 'pattern')">
                        {{'validations.invalidEmail' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputs-form-section teleInput">
                <label for="phone" class="text-start pb-2 w-100">{{'auth.phone' | translate}}</label>
                <ngx-intl-tel-input [cssClass]="'tel-input'" ariaLabel="phone"
                    customPlaceholder="{{'auth.phone' | translate}}" [enableAutoCountrySelect]="true"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [maxLength]="20"
                    [numberFormat]="PhoneNumberFormat.International" [phoneValidation]="true" [searchCountryField]="[
                          SearchCountryField.Iso2,
                          SearchCountryField.Name
                        ]" [searchCountryFlag]="true" [searchCountryFlag]="true" [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO" [separateDialCode]="true" formControlName="phone" name="phone">
                </ngx-intl-tel-input>
                <mat-error class="mat-intl-error" *ngIf="StaffForm.controls['phone'].invalid &&
                 !hasError('phone', 'required') ">
                    {{'validations.Invalid Phone Format' | translate}}
                </mat-error>
                <mat-error
                    *ngIf="hasError('phone', 'required') && (StaffForm.controls['phone'].touched ||formSubmitAttempt)">
                    {{'validations.required' | translate}}
                </mat-error>
            </div>


            <div class="inputs-form-section">
                <p class="radioTitle">Identification</p>
                <mat-radio-group class="horizontal_list" formControlName="is_national_number" id="radio"
                    aria-label="Select an option" color="primary">
                    <mat-radio-button [value]="true">National ID</mat-radio-button>
                    <mat-radio-button [value]="false">Resident ID (Iqama)</mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="inputs-form-section" [hidden]="!StaffForm.value.is_national_number">
                <mat-form-field appearance="outline" class="form-group">
                    <mat-label>National ID</mat-label>
                    <input type="text" formControlName="national_number" matInput
                        [required]="StaffForm.value.is_national_number"
                        [maxlength]="StaffForm.value.is_national_number?20:null"
                        [minlength]="StaffForm.value.is_national_number?8:null" appOnlynumber />
                    <mat-error *ngIf="hasError('national_number', 'required')">
                        {{'validations.required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="hasError('national_number', 'minlength')">
                        {{'validations.minlength' | translate}} 8
                    </mat-error>
                    <mat-error *ngIf="hasError('national_number', 'pattern')">
                        {{'validations.number' | translate}}
                    </mat-error>
                </mat-form-field>

            </div>


            <div class="inputs-form-section" [hidden]="StaffForm.value.is_national_number">
                <mat-form-field appearance="outline" class="form-group">
                    <mat-label>Resident ID (Iqama)</mat-label>
                    <input type="text" formControlName="resident_number" matInput
                        [required]="!StaffForm.value.is_national_number"
                        [maxlength]="!StaffForm.value.is_national_number?20:null"
                        [minlength]="!StaffForm.value.is_national_number?8:null" appOnlynumber />
                    <mat-error *ngIf="hasError('resident_number', 'required')">
                        {{'validations.required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="hasError('resident_number', 'minlength')">
                        {{'validations.minlength' | translate}} 8
                    </mat-error>
                    <mat-error *ngIf="hasError('resident_number', 'pattern')">
                        {{'validations.number' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>


            <div class="inputs-form-section">
                <mat-form-field appearance="outline" class="form-group">
                    <mat-label>{{'users.role' | translate}}</mat-label>
                    <mat-select class="profile-inputs_c-grey-6" formControlName="role">
                        <mat-option *ngFor="let item of roles" [value]="item.value">{{item.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="hasError('role', 'required')">
                        {{'validations.required' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="inputs-form-section">
                <!-- <mat-form-field appearance="outline" class="form-group" [class.loading]="loadingAuctions">
                    <mat-label>Auctions</mat-label>
                    <mat-select class="profile-inputs_c-grey-6" multiple formControlName="rooms">
                        <div class="w-100 px-2" style="position: sticky; top: 0px;z-index: 6;">
                            <input class="w-100 p-2" type="text" maxlength="100" [(ngModel)]="AuctionsModal.search"
                                 (ngModelChange)="SearchInAuctions()" [ngModelOptions]="{standalone:true}">
                        </div>
                        <mat-option [value]="item.id" *ngFor="let item of AuctionsList" style="z-index: 5;">
                            <p class="pt-2" style="word-break: normal;">
                                {{item.name}}</p>
                        </mat-option>

                        <div disabled *ngIf="!AuctionsList.length" style="z-index: 5;">
                            <p class="pt-2 text-center">
                                No Auctions found</p>
                        </div>

                        <div class="text-center" *ngIf="CanLoadMore">
                            <hr class="m-0">
                            <a (click)="getNextPage()" class="btn-link" type="button"><small>Load
                                    More</small></a>
                        </div>
                    </mat-select>
                    <mat-error *ngIf=" hasError('rooms', 'required' )">
                        {{'validations.required' | translate}}
                    </mat-error>
                </mat-form-field> -->


                <mat-form-field class="example-chip-list" appearance="outline">
                    <mat-label>Auctions</mat-label>
                    <mat-chip-grid #chipGrid aria-label="Auctions selection">
                        <mat-chip-row *ngFor="let item of selectedAuctions" (removed)="remove(item)">
                            {{item.name.length>10?(item.name | slice:0:10)+'...':item.name}}
                            <button matChipRemove [attr.aria-label]="'remove ' + item.name">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                    <input placeholder="Search for auctions..." #auctionSearchInput [(ngModel)]="AuctionsModal.search"
                        [ngModelOptions]="{standalone:true}" (matChipInputTokenEnd)="SearchInAuctions()" maxlength="100"
                        (ngModelChange)="$event.length==0?SearchInAuctions():null" [matChipInputFor]="chipGrid"
                        [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
                    <mat-autocomplete #auto="matAutocomplete">
                        <div style="height: 100%;overflow-y: scroll;" (scrolled)="getNextPage()"
                            [infiniteScrollDistance]="2" [infiniteScrollThrottle]="AuctionsPagesCount"
                            [scrollWindow]="false" infiniteScroll>
                            <mat-option *ngFor="let item of AuctionsList" (click)="selected(item)">
                                {{item.name}}
                            </mat-option>
                            <mat-option disabled *ngIf="!AuctionsList.length && !loadingAuctions">Not found
                                data</mat-option>
                            <mat-option *ngIf="!AuctionsList.length && loadingAuctions"><span
                                    [appLoading]="true">Loading</span></mat-option>
                        </div>
                    </mat-autocomplete>
                </mat-form-field>

            </div>

            <div class="group_btn">
                <button *ngIf="!loading" mat-button mat-dialog-close class="btnLight_fill" [appLoading]="loading">
                    {{'users.CANCEL' | translate}}
                </button>
                <button mat-button class="btn_fill" type="submit" [appLoading]="loading">
                    {{data?"Update Staff":"Add Staff"}}
                </button>
            </div>
        </div>
    </form>

    <app-loading *ngIf="loadingData"></app-loading>

</mat-dialog-content>