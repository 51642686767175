import { Component, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { ConfigService } from 'src/app/core/service/config.service';

@Component({
  selector: 'app-biderFooter',
  templateUrl: './biderFooter.component.html',
  styleUrls: []
})
export class BiderFooterComponent implements OnInit {
  isHome: boolean = false
  year = new Date().getFullYear();

  constructor(private _config: ConfigService) { }

  ngOnInit() {
    this._config._router.url == "/" || this._config._router.url == "/pages/terms" || this._config._router.url == "/pages/about-us" ? this.isHome = true : this.isHome = false

    this._config._router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        // this.currentRoute = event.url;
        event.url == "/" ? this.isHome = true : this.isHome = false
      }
    });
  }

}
