import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kFormat'
})
export class KFormatPipe implements PipeTransform {

  // transform(value: number): string {
  //   // const suffixes = ['', 'k', 'M', 'B', 'T'];
  //   // let suffixIndex = 0;
  //   // value = Number(value);
  //   // debugger
  //   // while (value >= 1000 && suffixIndex < suffixes.length - 1) {
  //   //   value /= 1000;
  //   //   suffixIndex++;
  //   // }

  //   // const numbersAfterDot = value.toString().split('.')[1]
  //   // return value.toFixed((numbersAfterDot ? numbersAfterDot.toString().length : 0)) + suffixes[suffixIndex];
  //   return value.toString();
  // }

  transform(number: number, args?: any): any {
    // if (isNaN(number)) return null; // will only work value is a number
    // if (number === null) return null;
    // if (number === 0) return null;
    // let abs = Math.abs(number);
    // const rounder = Math.pow(10, 1);
    // const isNegative = number < 0; // will also work for Negative numbers
    // let key = '';

    // const powers = [
    //   { key: 'Q', value: Math.pow(10, 15) },
    //   { key: 'T', value: Math.pow(10, 12) },
    //   { key: 'B', value: Math.pow(10, 9) },
    //   { key: 'M', value: Math.pow(10, 6) },
    //   { key: 'K', value: 1000 }
    // ];

    // for (let i = 0; i < powers.length; i++) {
    //   let reduced = abs / powers[i].value;
    //   reduced = (reduced * rounder) / rounder;
    //   debugger
    //   if (reduced >= 1) {
    //     abs = reduced;
    //     key = powers[i].key;
    //     // break;
    //   }
    // }
    // return (isNegative ? '-' : '') + abs + key;
    return number
  }

}
