<nav class="navbar navbar-expand-lg navbar-light" id="nav" [ngClass]="backgroundColor">
  <div class="container-fluid p-0">
    <a class="navbar-brand p-0" [routerLink]="[ '/' ]">
      <img [src]="logoPath" alt="">
    </a>
    <button class="navbar-toggler" type="button" (click)="collapse=!collapse;" data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        <li class="nav-item" [class.main_color]="!isHome">
          <a class="nav-link" [routerLink]="[ '/' ]" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact: true}">{{"Home.Home"|translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="[ '/pages/about-us' ]" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact: true}">{{'pages.About Us' | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="[ '/pages/terms' ]" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact: true}">{{'pages.Terms' | translate}}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="[ '/pages/contact-us' ]" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact: true}">{{'pages.Contact Us' | translate}}</a>
        </li>
      </ul>
      <div>


        <ng-container *ngIf="!infoUser">
          <!-- <a class="lang_link" [class.main_color]="!isHome">

            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <defs>

              </defs>
              <path fill="#fff" id="language-icon" class="cls-1"
                d="M8.993,2A7,7,0,1,0,16,9,7,7,0,0,0,8.993,2Zm4.851,4.2H11.779a10.954,10.954,0,0,0-.966-2.492A5.621,5.621,0,0,1,13.844,6.2ZM9,3.428A9.861,9.861,0,0,1,10.337,6.2H7.663A9.861,9.861,0,0,1,9,3.428ZM3.582,10.4a5.476,5.476,0,0,1,0-2.8H5.948A11.561,11.561,0,0,0,5.85,9a11.561,11.561,0,0,0,.1,1.4Zm.574,1.4H6.221a10.954,10.954,0,0,0,.966,2.492A5.591,5.591,0,0,1,4.156,11.8ZM6.221,6.2H4.156A5.591,5.591,0,0,1,7.187,3.708,10.954,10.954,0,0,0,6.221,6.2ZM9,14.572A9.861,9.861,0,0,1,7.663,11.8h2.674A9.861,9.861,0,0,1,9,14.572ZM10.638,10.4H7.362A10.3,10.3,0,0,1,7.25,9a10.209,10.209,0,0,1,.112-1.4h3.276A10.209,10.209,0,0,1,10.75,9,10.3,10.3,0,0,1,10.638,10.4Zm.175,3.892a10.954,10.954,0,0,0,.966-2.492h2.065A5.621,5.621,0,0,1,10.813,14.292ZM12.052,10.4a11.561,11.561,0,0,0,.1-1.4,11.561,11.561,0,0,0-.1-1.4h2.366a5.476,5.476,0,0,1,0,2.8Z"
                transform="translate(-2 -2)" />
            </svg>
            {{ 'general.language' | translate }}

          </a> -->
          <button mat-button class="login_btn" [class.background_btn]="!isHome " routerLink="/auth/login">
            {{"auth.login"|translate}}</button>
          <!-- <button mat-button class="register_btn" routerLink="/auth/register">{{"auth.register"|translate}}</button> -->
          <a class="login_btn change-lang" href="javascript:void(0)" (click)="changeLanguage()"
            [class.background_btn]="!isHome ">
            {{"general.EN"|translate}}</a>
        </ng-container>

        <ng-container *ngIf="infoUser">

          <a class="notification dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
            (click)="MarkAllNotificationAsSeen()">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21">
              <path id="notifications-icon"
                d="M269.347,364.745l-1.974-1.955v-3.331a7.314,7.314,0,0,0-6.575-7.241v-1.449h-1.462v1.449a7.374,7.374,0,0,0-6.576,7.241v3.331l-1.973,1.955a.66.66,0,0,0-.219.507v2.172a.687.687,0,0,0,.731.725h5.115a3.653,3.653,0,0,0,7.306,0h5.115a.684.684,0,0,0,.73-.634.7.7,0,0,0,0-.091v-2.172A.656.656,0,0,0,269.347,364.745Zm-9.28,5.576a2.182,2.182,0,0,1-2.192-2.172h4.384a2.182,2.182,0,0,1-2.192,2.172ZM268.1,366.7H252.03v-1.158L254,363.586a.657.657,0,0,0,.219-.506v-3.621a5.845,5.845,0,0,1,11.69,0v3.621a.657.657,0,0,0,.219.506l1.973,1.956Z"
                transform="translate(-250.566 -350.769)" fill="#616e7c" />
            </svg>
            <span class="notification-count" *ngIf="notificationCount">{{notificationCount}}</span>

            <!-- <div class=""> -->
            <div class="notification-panel dropdown-menu ">
              <div [appLoading]="loadingNoti" class="notification-header">
                <h3>{{'general.Notification' | translate}}</h3>
              </div>
              <div *ngIf="loadingNoti&&!notificationsList">
                <!-- <mat-spinner class="m-auto" diameter="50"></mat-spinner> -->
                <app-loading></app-loading>
              </div>
              <div [hidden]="loadingNoti&&!notificationsList">
                <div *ngIf="!notificationsList?.length" class="no-data text-center px-2 py-5">
                  {{ 'general.You have no notifications' | translate }}!
                </div>
                <div (scrolled)="getNextPage()" *ngIf="notificationsList?.length" [infiniteScrollDistance]="1"
                  [infiniteScrollThrottle]="pageCount" [scrollWindow]="false" class="notification-list" infiniteScroll>
                  <div *ngFor="let notif of notificationsList" [ngClass]="{ new: !notif?.is_seen }"
                    (click)="navigate(notif)">
                    <a *ngIf="notif?.message_text" class="d-cusror">
                      <p class="title">{{ notif?.message_text }}</p>
                      <span class="date">{{ notif?.created_at | dateUTC }}</span>
                    </a>
                  </div>
                </div>

              </div>
            </div>
            <!-- </div> -->
          </a>

          <a [matMenuTriggerFor]="menu" [ngClass]="{'user_info2': !isHome,'user_info1': isHome}">
            <img *ngIf="infoUser.user.image" [src]="infoUser.user.image" class="img-profile"
              (error)="infoUser.user.image=''">
            <span *ngIf="!infoUser.user.image" class="img-profile">
              {{(infoUser.user.firstname+' '+infoUser.user.lastname)|shortName}}
            </span>
            <span class="helo">{{"general.Hello"|translate}},</span>
            <span class="username">{{userName|titlecase}}</span>
            <svg class="user-signin-arrow-down" xmlns="http://www.w3.org/2000/svg" width="8" height="5"
              viewBox="0 0 8 5">
              <path id="arrow-down" d="M-2.313-6.729.75-3.627l3.063-3.1.937.949-4,4.051-4-4.051Z"
                transform="translate(3.25 6.729)" fill="#999" />
            </svg></a>
          <mat-menu #menu="matMenu" class="w-100">
            <button mat-menu-item *ngIf="this.infoUser?.user?.role_code == 'General'" (click)="NavigateToProfile()"
              class="w-100 menu_btn">
              <svg height="17" viewBox="0 0 14 17" width="14">
                <path
                  d="M7,8.5a12.35,12.35,0,0,1,5,1c1.3.6,2,1.4,2,2.4v1.7H0V11.9A2.954,2.954,0,0,1,2,9.5,12.35,12.35,0,0,1,7,8.5ZM2.6,15.3H4.4V17H2.6ZM7,0A3.74,3.74,0,0,1,9.5,1a2.965,2.965,0,0,1,1,2.4,2.965,2.965,0,0,1-1,2.4A3.74,3.74,0,0,1,7,6.8a3.317,3.317,0,0,1-2.5-1,3.1,3.1,0,0,1-1-2.4A2.965,2.965,0,0,1,4.5,1,3.74,3.74,0,0,1,7,0ZM6.1,15.3H7.8V17H6.1Zm3.5,0h1.8V17H9.6Z"
                  fill="#999999" id="my-profile-icon"></path>
              </svg>
              <span>{{"breadcrumb.profile"|translate}}</span>
            </button>

            <button mat-menu-item *ngIf="this.infoUser?.user?.role_code != 'General'" (click)="NavigateToDashboard()"
              class="w-100 menu_btn">
              <svg height="17" viewBox="0 0 14 17" width="14">
                <path
                  d="M7,8.5a12.35,12.35,0,0,1,5,1c1.3.6,2,1.4,2,2.4v1.7H0V11.9A2.954,2.954,0,0,1,2,9.5,12.35,12.35,0,0,1,7,8.5ZM2.6,15.3H4.4V17H2.6ZM7,0A3.74,3.74,0,0,1,9.5,1a2.965,2.965,0,0,1,1,2.4,2.965,2.965,0,0,1-1,2.4A3.74,3.74,0,0,1,7,6.8a3.317,3.317,0,0,1-2.5-1,3.1,3.1,0,0,1-1-2.4A2.965,2.965,0,0,1,4.5,1,3.74,3.74,0,0,1,7,0ZM6.1,15.3H7.8V17H6.1Zm3.5,0h1.8V17H9.6Z"
                  fill="#999999" id="my-profile-icon"></path>
              </svg>
              <span>{{"items-requests.Dashboard"|translate}}</span>
            </button>

            <button mat-menu-item (click)="changeLanguage()" class="lang-btn ">
              <img src="./assets/images/icons/language-icon.svg" alt="">
              <span>{{ 'general.language' | translate }}</span>
            </button>
            <button mat-menu-item (click)="signOut()" class="menu_btn">
              <svg height="17.006" viewBox="0 0 17.001 17.006" width="17.001">
                <path
                  d="M6223.19,12103h-13.4a1.815,1.815,0,0,1-1.8-1.808v-13.383a1.815,1.815,0,0,1,1.811-1.808h13.4a1.817,1.817,0,0,1,1.792,1.808v4.146l-1.659-1.559v-2.74h-13.687v13.575h13.687v-2.625l1.659-1.461v4.165a1.766,1.766,0,0,1-.529,1.267,1.69,1.69,0,0,1-1.123.43C6223.3,12103.006,6223.243,12103,6223.19,12103Zm-4.514-5.039,2.691-2.688h-9.562v-1.54h9.562l-2.691-2.691,1.167-1.169,4.52,4.629-4.52,4.629Z"
                  fill="#999" id="logout-icon" transform="translate(-6208 -12086)"></path>
              </svg>
              <span>{{'navbar.siginOut' | translate}}</span>
            </button>
          </mat-menu>

        </ng-container>
      </div>
    </div>
  </div>
</nav>