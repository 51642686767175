<nav class="nav-bar">

    <div class="collapse_btn">
        <a class="collapse-menu" href="javascript:void(0)" (click)="collapse_menu()">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                <g id="Minimize_Sidebar" data-name="Minimize Sidebar" transform="translate(-35 -18)">
                    <rect id="bg" width="44" height="44" rx="22" transform="translate(35 18)" fill="#efefef"
                        opacity="0" />
                    <g id="minimize-sidebar-icon" transform="translate(-103.298 -223.531)">
                        <path id="Path_13106" data-name="Path 13106"
                            d="M160.295,256.531a.911.911,0,1,0,0,1.822h10.072a.911.911,0,1,0,0-1.822Z" fill="#52606d" />
                        <path id="Path_13107" data-name="Path 13107"
                            d="M160.292,271.531h10.076a.907.907,0,1,0,0-1.813H160.295a.907.907,0,1,0,0,1.813Z"
                            fill="#52606d" />
                        <path id="Path_13108" data-name="Path 13108"
                            d="M151.315,264.264c.057.1.078.148.1.194l.058.14,3.66,3.9a.928.928,0,0,0,.768.4h.008a.914.914,0,0,0,.506-.151l.045-.03a.91.91,0,0,0,.214-1.228l-2.736-2.546h16.426a.911.911,0,1,0,0-1.822H153.951l2.734-2.546a.9.9,0,0,0-.257-1.259.946.946,0,0,0-1.284.252l-3.7,3.955c-.055.135-.076.185-.091.236l-.048.118.007.079-.018.085Z"
                            fill="#52606d" />
                    </g>
                </g>
            </svg> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                <path id="collapse-ic"
                    d="M111.561,345.065a1.1,1.1,0,0,1-.8-.32,1.065,1.065,0,0,1-.325-.79,1.047,1.047,0,0,1,.325-.784,1.107,1.107,0,0,1,.8-.314h15.2a1.1,1.1,0,0,1,.8.32,1.065,1.065,0,0,1,.325.79,1.047,1.047,0,0,1-.325.784,1.107,1.107,0,0,1-.8.314Zm0-6.957a1.1,1.1,0,0,1-.8-.32,1.066,1.066,0,0,1-.325-.79,1.047,1.047,0,0,1,.325-.784,1.107,1.107,0,0,1,.8-.314H122.8a1.1,1.1,0,0,1,.8.32,1.065,1.065,0,0,1,.325.79,1.047,1.047,0,0,1-.325.784,1.107,1.107,0,0,1-.8.314Zm0-6.827a1.1,1.1,0,0,1-.8-.32,1.121,1.121,0,0,1,0-1.578,1.1,1.1,0,0,1,.8-.318h15.2a1.09,1.09,0,0,1,.8.324,1.078,1.078,0,0,1,.325.794,1.047,1.047,0,0,1-.325.784,1.107,1.107,0,0,1-.8.314Zm18.517,5.755,4.036,3.959a.994.994,0,0,1,.32.774,1.111,1.111,0,0,1-.353.774,1.132,1.132,0,0,1-.809.33,1.105,1.105,0,0,1-.8-.33l-4.825-4.732a1.057,1.057,0,0,1,0-1.547l4.825-4.732a1.105,1.105,0,0,1,.8-.33,1.132,1.132,0,0,1,.809.33,1.094,1.094,0,0,1,0,1.58Z"
                    transform="translate(-110.435 -329.065)" fill="#322075" />
            </svg>

        </a>
    </div>

    <ul class="user">

        <li>
            <a class="notification dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
                (click)="MarkAllNotificationAsSeen()">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21">
                    <path id="notifications-icon"
                        d="M269.347,364.745l-1.974-1.955v-3.331a7.314,7.314,0,0,0-6.575-7.241v-1.449h-1.462v1.449a7.374,7.374,0,0,0-6.576,7.241v3.331l-1.973,1.955a.66.66,0,0,0-.219.507v2.172a.687.687,0,0,0,.731.725h5.115a3.653,3.653,0,0,0,7.306,0h5.115a.684.684,0,0,0,.73-.634.7.7,0,0,0,0-.091v-2.172A.656.656,0,0,0,269.347,364.745Zm-9.28,5.576a2.182,2.182,0,0,1-2.192-2.172h4.384a2.182,2.182,0,0,1-2.192,2.172ZM268.1,366.7H252.03v-1.158L254,363.586a.657.657,0,0,0,.219-.506v-3.621a5.845,5.845,0,0,1,11.69,0v3.621a.657.657,0,0,0,.219.506l1.973,1.956Z"
                        transform="translate(-250.566 -350.769)" fill="#616e7c" />
                </svg>
                <span class="notification-count" *ngIf="notificationCount">{{notificationCount}}</span>

                <!-- <div class=""> -->
                <div class="notification-panel dropdown-menu ">
                    <div [appLoading]="loadingNoti" class="notification-header">
                        <h3>{{'general.Notification' | translate}}</h3>
                    </div>
                    <div *ngIf="loadingNoti&&!notificationsList">
                        <!-- <mat-spinner class="m-auto" diameter="50"></mat-spinner> -->
                        <app-loading></app-loading>
                    </div>
                    <div [hidden]="loadingNoti&&!notificationsList">
                        <div *ngIf="!notificationsList?.length" class="no-data text-center px-2 py-5">
                            {{ 'general.You have no notifications' | translate }}!
                        </div>
                        <div (scrolled)="getNextPage()" *ngIf="notificationsList?.length" [infiniteScrollDistance]="1"
                            [infiniteScrollThrottle]="pageCount" [scrollWindow]="false" class="notification-list"
                            infiniteScroll>
                            <div *ngFor="let notif of notificationsList" [ngClass]="{ new: !notif?.is_seen }"
                                (click)="navigate(notif)">
                                <a *ngIf="notif?.message_text" class="d-cusror">
                                    <p class="title">{{ notif?.message_text }}</p>
                                    <span class="date">{{ notif?.created_at | dateUTC }}</span>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- </div> -->
            </a>
        </li>
        <li>
            <a href="javascript:void(0)" class="dropdown-toggle user-signin" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img *ngIf="infoUser.user.image" [src]="infoUser.user.image" class="img-profile"
                    (error)="infoUser.user.image=''">
                <span *ngIf="!infoUser.user.image" class="img-profile">
                    {{(infoUser.user.firstname+' '+infoUser.user.lastname)|shortName}}
                </span>
                <span class="helo">{{"general.Hello"|translate}},</span>
                <span class="username">{{userName|titlecase}}</span>
                <svg class="user-signin-arrow-down" xmlns="http://www.w3.org/2000/svg" width="8" height="5"
                    viewBox="0 0 8 5">
                    <path id="arrow-down" d="M-2.313-6.729.75-3.627l3.063-3.1.937.949-4,4.051-4-4.051Z"
                        transform="translate(3.25 6.729)" fill="#999" />
                </svg>
                <div class="dropdown-menu">
                    <ul>
                        <li>
                            <a (click)="OnViewMyProfile()" class="dropdown-item" href="#">
                                <svg height="17" viewBox="0 0 14 17" width="14">
                                    <path
                                        d="M7,8.5a12.35,12.35,0,0,1,5,1c1.3.6,2,1.4,2,2.4v1.7H0V11.9A2.954,2.954,0,0,1,2,9.5,12.35,12.35,0,0,1,7,8.5ZM2.6,15.3H4.4V17H2.6ZM7,0A3.74,3.74,0,0,1,9.5,1a2.965,2.965,0,0,1,1,2.4,2.965,2.965,0,0,1-1,2.4A3.74,3.74,0,0,1,7,6.8a3.317,3.317,0,0,1-2.5-1,3.1,3.1,0,0,1-1-2.4A2.965,2.965,0,0,1,4.5,1,3.74,3.74,0,0,1,7,0ZM6.1,15.3H7.8V17H6.1Zm3.5,0h1.8V17H9.6Z"
                                        fill="#999999" id="my-profile-icon"></path>
                                </svg>
                                <span>{{"breadcrumb.profile"|translate}}</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="changeLanguage()" class=" dropdown-item lang-btn ">
                                <img src="./assets/images/icons/language-icon.svg" alt="">
                                <span>{{ 'general.language' | translate }}</span>
                            </a>
                        </li>
                        <li>
                            <a (click)="signOut()" class="dropdown-item" href="javascript:void(0)">
                                <svg height="17.006" viewBox="0 0 17.001 17.006" width="17.001">
                                    <path
                                        d="M6223.19,12103h-13.4a1.815,1.815,0,0,1-1.8-1.808v-13.383a1.815,1.815,0,0,1,1.811-1.808h13.4a1.817,1.817,0,0,1,1.792,1.808v4.146l-1.659-1.559v-2.74h-13.687v13.575h13.687v-2.625l1.659-1.461v4.165a1.766,1.766,0,0,1-.529,1.267,1.69,1.69,0,0,1-1.123.43C6223.3,12103.006,6223.243,12103,6223.19,12103Zm-4.514-5.039,2.691-2.688h-9.562v-1.54h9.562l-2.691-2.691,1.167-1.169,4.52,4.629-4.52,4.629Z"
                                        fill="#999" id="logout-icon" transform="translate(-6208 -12086)"></path>
                                </svg>
                                <span>{{'navbar.siginOut' | translate}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </a>
        </li>
    </ul>

</nav>