import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionsModal } from 'src/app/core/modal/actions/actions-modal';
import { ConfigService } from 'src/app/core/service/config.service';
import { ApiUserService } from 'src/app/features/user-managment/core/service/api-user.service';

@Component({
  selector: 'app-delete',
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent {
 constructor(
  @Inject(MAT_DIALOG_DATA) public data: ActionsModal,
  private _users : ApiUserService,
  public dialogRef: MatDialogRef<ConfirmComponent>

  ){
  

   
  
 }

 confirm(){
  this.dialogRef.close('success');
}


 
}
