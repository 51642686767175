<!-- <div class="dialog-title">
    <h3> {{data.title}}</h3>

    <span mat-button mat-dialog-close>
        <svg xmlns="http://www.w3.org/2000/svg" width="13.998" height="13.998" viewBox="0 0 13.998 13.998">
            <path id="close-btn"
                d="M8.177-11.911,6.768-13.32l-5.59,5.59-5.59-5.59L-5.82-11.911l5.59,5.59L-5.82-.732-4.411.677l5.59-5.59L6.768.677,8.177-.732l-5.59-5.59Z"
                transform="translate(5.82 13.32)" fill="#bbb" />
        </svg>
    </span>
</div>

<mat-dialog-content>
    <div class="confirm_img">
        <mat-icon>{{data.icon}}</mat-icon>
    </div>
    <div class="confirm_text">
        <p>
            {{data.text}}
        </p>
    </div>
    <div class="group_btn mt-3">
        <button mat-dialog-close mat-button class="btnLight_fill">{{data.cancel}}</button>
        <button (click)="confirm()" mat-button class="btn_fill">{{data.confirm}}</button>
    </div>
</mat-dialog-content>
 -->




<div class="dialog">
    <p class="text-end">
        <span (click)="dialogRef.close()">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.998" height="13.998" viewBox="0 0 13.998 13.998">
                <path id="close-btn"
                    d="M8.177-11.911,6.768-13.32l-5.59,5.59-5.59-5.59L-5.82-11.911l5.59,5.59L-5.82-.732-4.411.677l5.59-5.59L6.768.677,8.177-.732l-5.59-5.59Z"
                    transform="translate(5.82 13.32)" fill="#bbb" />
            </svg>
        </span>
    </p>
    <div class="suspend">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="71.375" viewBox="0 0 80 71.375">
            <g id="warning" transform="translate(-880 -296.937)">
                <path id="warning-2" data-name="warning"
                    d="M10.868,75.367h63.2a8.391,8.391,0,0,0,7.26-12.59l-31.6-54.6a8.389,8.389,0,0,0-14.52,0l-31.6,54.6a8.391,8.391,0,0,0,7.26,12.59Z"
                    transform="translate(877.531 292.944)" fill="#f7c600" />
                <path id="Union_36" data-name="Union 36"
                    d="M10779,15249.478a4,4,0,1,1,4,4A4,4,0,0,1,10779,15249.478Zm0-13.574v-15.394a4.2,4.2,0,1,1,8.395,0v15.394a4.2,4.2,0,0,1-8.395,0Z"
                    transform="translate(-9863 -14896.164)" fill="#fff" />
            </g>
        </svg>




        <h4 class="title mt-4"> {{data.title}}</h4>
        <div class="hint">
            {{data.text}}</div>

        <div class="group_btn mt-3">
            <button mat-dialog-close mat-button class="btnLight_fill">{{data.cancel}}</button>
            <button (click)="confirm()" mat-button class="btn_fill">{{data.confirm}}</button>
        </div>
    </div>
</div>