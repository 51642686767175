import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ApiService } from 'src/app/core/service/api.service';
import { ConfigService } from 'src/app/core/service/config.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiAuctionsService {

  constructor(private apiService: ApiService, private _config: ConfigService) { }

  getOneAuction(id: string) {
    const roleCode = this._config.getAuthUserInfo()?.user?.role_code;

    if (roleCode == 'Admin' || roleCode == 'Auctioneer' || roleCode == 'Master') {
      return this.apiService
        .get(`${environment.apiUrl}admin/auction/auctions/${id}`)
        .pipe(catchError(this.handleError));
    } else {
      return this.apiService
        .get(`${environment.apiUrl}auction/bidder/auctions/${id}`)
        .pipe(catchError(this.handleError));
    }
  }

  getAuctionDetails(id: string) {
    return this.apiService
      .get(`${environment.apiUrl}auction/auctions-details/${id}`)
      .pipe(catchError(this.handleError));

  }


  getAuctionBidders(id: string) {
    return this.apiService
      .get(`${environment.apiUrl}auction/auctions/${id}/bidders`)
      .pipe(catchError(this.handleError));
  }

  getRoomBidders(id: string) {
    return this.apiService
      .get(`${environment.apiUrl}auction/rooms/${id}/bidders`)
      .pipe(catchError(this.handleError));
  }

  auctionEnd(model: any) {
    return this.apiService
      .post(`${environment.apiUrl}userManagement/withdraw-money-from-wallet`, model)
      .pipe(catchError(this.handleError));
  }

  getAllAuctions(model: any) {
    return this.apiService
      .get(`${environment.apiUrl}auction/auctions`, model)
      .pipe(catchError(this.handleError));
  }


  joinAuction(id: string) {
    return this.apiService
      .post(`${environment.apiUrl}auction/join/auctions/${id}`)
      .pipe(catchError(this.handleError));
  }

  withdrawAuction(id: string) {
    return this.apiService
      .post(`${environment.apiUrl}auction/leave/auctions/${id}`)
      .pipe(catchError(this.handleError));
  }

  removeBidders(id: string, body: any) {
    return this.apiService
      .post(`${environment.apiUrl}auction/remove-user/auctions/${id}`, body)
      .pipe(catchError(this.handleError));
  }

  getAuctionVedioStreamList(StreamId: any) {
    return this.apiService
      .get(`${environment.apiUrl}admin/auction/streams/${StreamId}`)
      .pipe(catchError(this.handleError));
  }

  getSingleVedioStream(vedioName: string, modal: any) {
    return this.apiService
      .get(`${environment.streamUrlPublish}LiveApp/streams/${vedioName}`, modal)
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }

}


