<app-main-layout>
    <div class="wrapper" aria-expanded="true" [ngClass]="{'collapse-menu': collapseMenu}">
        <app-sidebar></app-sidebar>
        <div class="main">
            <div class="background-showed"></div>
            <app-navbar></app-navbar>
            <div class="main-content">
                <router-outlet></router-outlet>
            </div>
    
        </div>
    </div>
</app-main-layout>