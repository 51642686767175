import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StaffService } from '../../../core/service/staff.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { ConfigService } from 'src/app/core/service/config.service';
import { AuctionList } from 'src/app/shared/Components/auction-listing/interface/auction-list';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-create-staff',
  templateUrl: './create-staff.component.html',
  styles: [
  ]
})
export class CreateStaffComponent implements OnInit {
  StaffForm!: FormGroup;
  SearchCountryField = SearchCountryField;
  CountryISO: any = CountryISO.SaudiArabia;
  PhoneNumberFormat = PhoneNumberFormat;
  formSubmitAttempt: boolean = false;
  loading: boolean = false;
  loadingData: boolean = false;
  loadingAuctions: boolean = false;
  AuctionsList: any[] = [];
  AuctionsModal: AuctionList = {
    sort: 'DESC',
    sortBy: 'created_at',
    perPage: 10,
    page: 1,
    search: '',
    is_multi_auction: 0
  };
  CanLoadMore: boolean = true;
  roles: any[] = [{ name: "Master", value: "Master" }, { name: "Auctioneer", value: "Auctioneer" }];

  // auctions search parameters
  separatorKeysCodes: number[] = [ENTER, COMMA];
  // @ViewChild('fruitInput') fruitInput!: ElementRef<HTMLInputElement>;
  selectedAuctions: any[] = []
  AuctionsPagesCount: number = 0;
  constructor(private _staffService: StaffService,
    private _fb: FormBuilder,
    private _config: ConfigService,
    public dialogRef: MatDialogRef<CreateStaffComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit(): void {
    this.GetAllAuctions();
    if (this.data) {
      this.getStaff();
    } else {
      this.StaffForm = this._fb.group(this._staffService.staffFormValidations())
    }
    this.StaffForm.valueChanges.subscribe(data => {
      this.formSubmitAttempt = false;
    })
  }

  OnSubmit() {
    if (this.StaffForm.invalid) {
      return
    }
    if (this.data) {
      this.OnUpdate();
    } else {
      this.OnCreate();
    }
  }

  OnCreate() {
    this.loading = true;
    let modal = { ...this.StaffForm.value };
    modal.phone = this.StaffForm.value['phone'].number.replaceAll(/\s/g, '');
    modal.phone_code = this.StaffForm.value['phone'].countryCode;

    if (modal.is_national_number) {
      delete modal['resident_number']
    } else {
      delete modal['national_number']
    }

    this._staffService.addNewStaff(modal).subscribe({
      next: (res) => {
        this.loading = false;
        this._config.toastrService.success(res.message);
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.loading = false;
        this._config.showErrors(err);
      }
    })
  }

  OnUpdate() {
    this.loading = true;
    let modal = { ...this.StaffForm.value };
    modal.phone = this.StaffForm.value['phone'].number.replaceAll(/\s/g, '');
    modal.phone_code = this.StaffForm.value['phone'].countryCode;

    if (modal.is_national_number) {
      delete modal['resident_number']
    } else {
      delete modal['national_number']
    }

    this._staffService.UpdateStaff(modal, this.data).subscribe({
      next: (res) => {
        this.loading = false;
        this._config.toastrService.success(res.message);
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.loading = false;
        this._config.showErrors(err);
      }
    })
  }
  GetAllAuctions() {
    this.loadingAuctions = true;
    this._staffService.getAllAuctions(this.AuctionsModal).subscribe(
      {
        next: (res) => {
          // setTimeout(() => {
          this.loadingAuctions = false;
          this.AuctionsList = this.AuctionsList.concat(res.data.list);
          this.CanLoadMore = res.data.list.length < this.AuctionsModal.perPage ? false : true;
          this.AuctionsPagesCount = res?.data?.total

          // }, 2000);

        },
        error: (err) => {
          this._config.showErrors(err)
        }
      }

    )
  }

  getNextPage() {
    if (this.AuctionsModal.page <= this.AuctionsPagesCount - 1) {
      this.AuctionsModal.page += 1;
      this.GetAllAuctions()
    }
  }

  getStaff() {
    this.loadingData = true;
    this._staffService.getStaffById(this.data).subscribe({
      next: (res) => {
        this.loadingData = false;
        // this.staffDetailes = res.data;
        this.StaffForm = this._fb.group(this._staffService.staffFormValidations(res.data));
        // this.AuctionsList = this.AuctionsList.concat(res?.data?.rooms)
        this.selectedAuctions = res?.data?.rooms;
        this.CountryISO = res.data?.phone_code.toLowerCase();
        // this._config.toastrService.success(res.message);
      },
      error: (err) => {
        this.loadingData = false;
        this._config.showErrors(err);
      }
    })
  }

  SearchInAuctions() {
    if (this.AuctionsModal?.search?.length >= 3 || this.AuctionsModal?.search?.length == 0) {
      this.AuctionsList = [];
      this.AuctionsModal.page = 1;
      this.GetAllAuctions();
    }
  }
  hasError(controlName: string, errorName: string) {
    return this.StaffForm.controls[controlName]?.hasError(errorName);
  }


  remove(item: any): void {
    const index = this.selectedAuctions.indexOf(item);
    if (index >= 0) {
      this.selectedAuctions.splice(index, 1);
      let temp = this.StaffForm.controls['rooms']?.value?.filter((element: any) => element != item?.id)
      this.StaffForm.controls['rooms'].patchValue(temp);
    }
  }

  selected(item: any): void {
    if (!this.selectedAuctions.some(element => element.id === item.id)) {
      this.selectedAuctions.push(item);
      this.AuctionsModal.search = '';
      if (this.StaffForm.controls['rooms'].value?.length) {
        this.StaffForm.controls['rooms'].patchValue([...this.StaffForm.controls['rooms'].value, item.id])
      } else {
        this.StaffForm.controls['rooms'].patchValue([item.id])
      }
    }
  }

}


