export enum SocketEventType {
    Price_Not_Valid = "priceNotVaild",
    Not_Enough_Fund = "notEnoughFund",
    Get_Bidders = "getBidders",
    Auth = "auth",
    Auction_Not_Valid = "auctionNotVaild",
    Update_Blance = "UpdateBlance",
    Get_Remaining = "getRemaining",
    Get_My_Blance = "getMyBlance",
    RoomOrAuctionUpdated = "room-or-auction-updated",

}


export interface SocketEvent<TDataShape> {
    type: SocketEventType,
    data?: any
}