import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    //private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    public location: Location,
    private dialog: MatDialog

  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.dialog.closeAll();
          localStorage.clear();
          this.router.navigate(['auth/login']);
        } else if (err.status === 0) {
          this.showError('No Internet Connection');
        } else if (err.status === 404) {
          this.dialog.closeAll();
          if (request.url.includes("/api/auction/rooms/") && request.method === "GET") {
            this.location.back();
          } else {
            this.router.navigate(['/PageNotFound']);
          }
        } else if (err.status === 403) {
          this.dialog.closeAll();
          this.router.navigate(['/NoPermission']);
        }
        // const error = err.error.message || err.statusText;
        return throwError(err.error);
      })
    );
  }

  showError(msg: string) {
    this.toastr.error(msg);
  }
}
