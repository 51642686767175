import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Gallery, ImageItem, ImageSize, ThumbnailsPosition } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ConfigService } from 'src/app/core/service/config.service';
import { ApiAuctionsService } from 'src/app/features/bidders/core/service/api-auctions.service';
import { SideNavConfigService } from 'src/app/layout/main-layout/side-nav-config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-show-item-detailes',
  templateUrl: './show-item-detailes.component.html',
  styles: [
  ]
})
export class ShowItemDetailesComponent implements OnInit {
  @Input() itemId: string = ''
  auctionData: any;
  detailsItem: any;
  slider: any;
  MediaList: any;
  safeUrl: any;

  vedioStreamList: any[] = [];
  LoadingStream: boolean = false;
  showRecordStream: boolean = false;
  customOptions: OwlOptions = {
    rtl: localStorage.getItem("language") == "ar" ? true : false,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    center: true,
    navSpeed: 700,
    // navText: ['', ''],
    margin: 25,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      },
      1200: {
        items: 6
      }
    },
    nav: false
  }


  loading: boolean = false;
  constructor(
    // public dialogRef: MatDialogRef<ShowItemDetailesComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    public gallery: Gallery,
    public lightbox: Lightbox,
    private _sideNavService: SideNavConfigService,
    public _config: ConfigService,
    public _auctionApi: ApiAuctionsService,) {

  }


  ngOnInit(): void {
    // this.GetItemDetails(this.data.itemId)




  }
  ngOnChanges(changes: any): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.itemId) {
      this.GetItemDetails(this.itemId)
    }
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  MediaConfig() {

    /** Basic Gallery Example */

    // Creat gallery items
    this.MediaList = this.slider.map((item: any) => new ImageItem({ src: item.path, thumb: item.path }));


    /** Lightbox Example */

    // Get a lightbox gallery ref
    const lightboxRef = this.gallery.ref('lightbox');

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Bottom,
      autoHeight: true
    });

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.MediaList);
  }


  GetItemDetails(id: any) {
    this.loading = true;
    this._auctionApi.getOneAuction(id).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.auctionData = res.data;
        this.detailsItem = this.auctionData.details;
        this.slider = this.auctionData.files.filter((file: any) => file.isMain == 0);
        this.MediaConfig();
      },
      error: (error: any) => {
        this.loading = false;
        this._config.showErrors(error);
      },
    })
  }
  onClose() {
    // this.menu.ToggleWallet(false)
    this._sideNavService.ChangeSideNav({ status: false, type: 'item_details' });
  }
}
