<div class="footer1">
  <footer *ngIf="isHome">
    <div class="section1">
      <div class="container-fluid">
        <div class="d-flex justify-content-between">
          <img src="./assets/images/logo_white.svg" alt="">
          <div class="links">
            <div class="link"> <a><img src="./assets/images/icons/linkedin.svg" alt=""></a></div>
            <div class="link"><a><img src="./assets/images/icons/facebook.svg" alt=""></a></div>
            <div class="link"><a><img src="./assets/images/icons/twitter.svg" alt=""></a></div>
          </div>
        </div>
      </div>
    </div>
    <div class="section2">
      <div class="container-fluid">
        <div class="d-flex justify-content-between">
          <div class="by"><span>Developed by </span> <strong><a target="_blank" href="https://inndeve.com"> Inndeve</a></strong></div>
          <div class="copy_right">Copyright © {{year}} <span>Inndeve</span> All rights reserved</div>
        </div>
      </div>
    </div>
  </footer>
</div>


<footer *ngIf="!isHome">
  <div class="footer_light">
    <p>Copyright © {{year}} <span>Inndeve</span> All rights reserved</p>
  </div>
</footer>
