import { Component } from '@angular/core';
import { ConfigService } from 'src/app/core/service/config.service';

@Component({
  selector: 'app-no-permission',
  templateUrl: './no-permission.component.html',
  styles: [
  ]
})
export class NoPermissionComponent {
  constructor(public _config: ConfigService) {
this._config.getAuthUserInfo().user.role_code=='Admin'
  }

}
