import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SocketService } from './features/bidders/core/service/socket.service';
import { ConfigService } from './core/service/config.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { SharedService } from './shared/services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { OfflineComponent } from './shared/Components/offline/offline.component';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'auction';
  firstTime = true;
  constructor(
    public translate: TranslateService,
    public socket: SocketService,
    public _config: ConfigService,
    private router: Router,
    private _SharedService: SharedService,
    private dialog: MatDialog
  ) {


    const lang = localStorage.getItem('language') || 'en';
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    translate.use(lang);
    if (lang == "ar") {
      moment.updateLocale("en",{
        months :["يناير","فبراير","مارس","أبريل","مايو","يونيو","يوليو","أغسطس","سبتمبر","أكتوبر","نوفمبر","ديسمبر"]})
    }
    this.router.events.subscribe((e: any) => {
      this.navigationInterceptor(e);
    });
  }

  ngOnInit(): void {
    this._SharedService.SetOnlineStatus()
    this._SharedService.GetOnlineStatus().subscribe(status => {
      // debugger
      if (!status) {
        const dialogRef = this.dialog.open(OfflineComponent, {
          width: '550px',
          disableClose: true,
          panelClass: 'customDialogStyle'
        });
      }
    })

  }
  // Sets initial value to true to show loading spinner on first load
  loading = true


  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true
      console.log("Hello");
    }
    if (event instanceof NavigationEnd) {
      if (this.firstTime) {
        setTimeout(() => {
          this.loading = false;
          this.firstTime = false;
        }, 100);
      } else {
        this.loading = false;
      }
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false
    }
    if (event instanceof NavigationError) {
      this.loading = false
    }
  }
}
