<div [ngClass]="{ active: docsEditable }" [class.loading]="loading" class="files-preview-container">
  <a *ngIf="renderBtn" class="attach-btn">
    <img alt="" src="assets/images/icons/change-image-profile.svg" />
    <input #fileDropRef (change)="fileBrowseHandler($event)" [attr.id]="'fileDropRef_' + managerIndex"
      [disabled]="Disapled"
      accept="image/png, image/jpeg, application/pdf, .doc, .docx, .xlsx, .xls, .zip, .rar, .ppt, .pptx"
      [multiple]="!maxFiles" type="file" />
  </a>
  <div *ngIf="!renderBtn">
    <div [ngSwitch]="Mode">
      <div *ngSwitchCase="'small'" class="file_Small">

        <div (fileDropped)="onFileDropped($event)" [hidden]="!docsEditable" appDnd class="dnd-container">
          <input #fileDropRef (change)="fileBrowseHandler($event)" [attr.id]="'fileDropRef_' + managerIndex"
            [disabled]="Disapled"
            accept="image/png, image/jpeg, application/pdf, .doc, .docx, .xlsx, .xls, .zip, .rar , .ppt, .pptx"
            type="file" />
          <img *ngIf="!CurrentImageSelectedBase64" src="assets/images/icons/cameraIcon.svg"
            onerror="this.src='assets/images/default-img.png'" alt="Camera Icon">
          <img *ngIf="CurrentImageSelectedBase64" class="originImage"
            [src]="CurrentImageSelectedBase64.length==1?CurrentImageSelectedBase64[0].path:CurrentImageSelectedBase64"
            onerror="this.src='assets/images/default-img.png'" alt="Camera Icon">
        </div>
        <div>
          <div class="lable">{{label}}</div>
          <div class="hint">
            <ng-container *ngIf="allowedExt.length < 14">
              <span *ngFor="let item of allowedExt; index as i" class="text-uppercase">{{ i != 0 ? ', ' : '' }}
                {{item}}</span>
            </ng-container>
            <span *ngIf="allowedExt.length == 14">JPG or PNG</span>
            <span class="maxSize">({{MaxFileSize }}{{"forms.MB max"|translate}}) </span>
          </div>
          <div class="actions">
            <label *ngIf="selectedfilesCount>0" [attr.for]="'fileDropRef_' + managerIndex">{{'items-requests.CHANGE' | translate}}</label>
            <label *ngIf="selectedfilesCount==0" [attr.for]="'fileDropRef_' + managerIndex">{{'items-requests.UPLOAD' | translate}}</label>
          </div>

        </div>




      </div>
      <div *ngSwitchCase="'banner'" class="file_Banner">
        <div class="lable">{{label}}</div>
        <div class="hint">
          <ng-container *ngIf="allowedExt.length < 14">
            <span *ngFor="let item of allowedExt; index as i" class="text-uppercase">{{ i != 0 ? ', ' : '' }}
              {{item}}</span>
          </ng-container>
          <span *ngIf="allowedExt.length == 14">JPG or PNG</span>
          <span class="maxSize">({{MaxFileSize }}{{"forms.MB max"|translate}})</span>
        </div>
        <div class="actions">
          <label *ngIf="selectedfilesCount>0" [attr.for]="'fileDropRef_' + managerIndex">{{'items-requests.CHANGE' | translate}}</label>
          <label *ngIf="selectedfilesCount==0" [attr.for]="'fileDropRef_' + managerIndex">{{'items-requests.UPLOAD' | translate}}</label>
        </div>

        <div (fileDropped)="onFileDropped($event)" [hidden]="!docsEditable" appDnd class="dnd-container">
          <input #fileDropRef (change)="fileBrowseHandler($event)" [attr.id]="'fileDropRef_' + managerIndex"
            [disabled]="Disapled"
            accept="image/png, image/jpeg, application/pdf, .doc, .docx, .xlsx, .xls, .zip, .rar , .ppt, .pptx"
            type="file" />
          <img *ngIf="!CurrentImageSelectedBase64" src="assets/images/icons/cameraIcon.svg"
            onerror="this.src='assets/images/default-img.png'" alt="Camera Icon">
          <img *ngIf="CurrentImageSelectedBase64" class="originImage"
            [src]="CurrentImageSelectedBase64.length==1?CurrentImageSelectedBase64[0].path:CurrentImageSelectedBase64"
            onerror="this.src='assets/images/default-img.png'" alt="Camera Icon 123">
        </div>
      </div>
      <div *ngSwitchCase="'multi'" class="file_Multi">
        <div class="lable">{{label}}</div>
        <div class="hint">
          <ng-container *ngIf="allowedExt.length < 14">
            <span *ngFor="let item of allowedExt; index as i" class="text-uppercase">{{ i != 0 ? ', ' : '' }}
              {{item}}</span>
          </ng-container>
          <span *ngIf="allowedExt.length == 14">JPG or PNG</span>
          <span class="maxSize">({{MaxFileSize }}{{"forms.MB max"|translate}})</span>
        </div>
        <div class="actions">
          <label *ngIf="selectedfilesCount || files.length" [attr.for]="'fileDropRef_' + managerIndex">{{'items-requests.CHANGE' | translate}}</label>
          <label *ngIf="!selectedfilesCount && !files.length" [attr.for]="'fileDropRef_' + managerIndex">{{'items-requests.UPLOAD' | translate}}</label>
        </div>

        <div (fileDropped)="onFileDropped($event)" [hidden]="!docsEditable" [hidden]="selectedfilesCount|| files.length"
          appDnd class="dnd-container">
          <input #fileDropRef (change)="fileBrowseHandler($event)" [attr.id]="'fileDropRef_' + managerIndex"
            [disabled]="Disapled"
            accept="image/png, image/jpeg, application/pdf, .doc, .docx, .xlsx, .xls, .zip, .rar , .ppt, .pptx" multiple
            type="file" />
          <img src="assets/images/icons/cameraIcon.svg" alt="Camera Icon">
        </div>
      </div>


      <div *ngSwitchCase="'all'" class="mb-3">

          <button *ngIf="renderBtn" class="attach-btn" mat-icon-button>
            <img alt="" src="./assets/images/icons/attachment-icon.svg" />
            <input
              #fileDropRef
              (change)="fileBrowseHandler($event)"
              [attr.id]="'fileDropRef_' + managerIndex"
              [disabled]="Disapled"
              accept="image/png, image/jpeg, application/pdf, .doc, .docx, .xlsx, .xls, .zip, .rar, .ppt, .pptx"
              multiple
              type="file"
            />
          </button>
          <div
            (fileDropped)="onFileDropped($event)"
            *ngIf="!renderBtn"
            [class.loading]="loading"
            [hidden]="!docsEditable"
            appDnd
            class="dnd-container"
            style="display: block !important;"
          >
            <input
              #fileDropRef
              (change)="fileBrowseHandler($event)"
              [attr.id]="'fileDropRef_' + managerIndex"
              [disabled]="Disapled"
              accept="image/png, image/jpeg, application/pdf, .doc, .docx, .xlsx, .xls, .zip, .rar , .ppt, .pptx"
              multiple
              type="file"
            />
            <div class="mt-2">
              <svg
                height="25"
                viewBox="0 0 19 25"
                width="19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11362.336-8571a2.322,2.322,0,0,1-2.336-2.3v-15.742h4.725a2.321,2.321,0,0,0,2.335-2.3V-8596h9.605a2.317,2.317,0,0,1,2.335,2.3v20.4a2.317,2.317,0,0,1-2.335,2.3Zm6.421-5.439a.738.738,0,0,0,.743.732.738.738,0,0,0,.743-.732v-5.292l1.916,1.887a.75.75,0,0,0,1.051,0,.723.723,0,0,0,0-1.035l-3.185-3.138a.749.749,0,0,0-1.05,0l-3.185,3.138a.725.725,0,0,0,0,1.035.75.75,0,0,0,1.051,0l1.915-1.887Zm-3.184-10.2a.738.738,0,0,0,.743.732h6.368a.738.738,0,0,0,.743-.732.737.737,0,0,0-.743-.732h-6.368A.738.738,0,0,0,11365.573-8586.638Zm-5.573-3.871v-.052a.729.729,0,0,1,.219-.518l4.776-4.707a.747.747,0,0,1,.524-.215h.054v4.654a.844.844,0,0,1-.849.837Z"
                  fill="#322075"
                  id="upload-icon"
                  transform="translate(-11360 8596)"
                />
              </svg>
            </div>
            <h4>{{ label ? label : 'forms.Drag and drop' | translate }}</h4>
            <div>
              <span *ngIf="label"> {{'forms.Or' | translate}} </span>
              <span *ngIf="!label"> {{'forms.Supporting Documents here Or' | translate}}</span>
              <label [attr.for]="'fileDropRef_' + managerIndex"
                > {{'forms.Browse Your Files' | translate}}</label
              >
            </div>
            <div class="hint">
              {{'forms.Supported Formats' | translate}}:
              <ng-container *ngIf="allowedExt.length < 14">
                <span
                  *ngFor="let item of allowedExt; index as i"
                  class="text-uppercase"
                  >{{ i != 0 ? ', ' : '' }} {{item}}</span
                >
              </ng-container>
              <span *ngIf="allowedExt.length == 14">PDF, DOC, JPG, and PNG</span>
            </div>
            <!--
            <div class="hint">
              {{ 'forms.SupportsFiles' | translate }}
            </div> -->
          </div>
          <!-- <div class="hint-listing">
                <div [ngClass]="{'has-error': selectedfilesCount > maxFiles}">{{ 'forms.Max-files-is' | translate }} {{maxFiles}}</div>
                <!- - <div [ngClass]="{'has-error': !validFile}">{{ 'forms.InvalidFile' | translate }}</div> - ->
                <div [ngClass]="{'has-error': !validFile}">{{ 'forms.AcceptableFiles' | translate }}</div>
                <div [ngClass]="{'has-error': fileMaxSiZeErorr}">{{ 'forms.Max-file-size' | translate }}</div>
                <div [ngClass]="{'has-error': !files.length && isRequired && isSubmited}">{{ 'forms.Required' | translate }}</div>
            </div> -->
          <div *ngIf="selectedfilesCount > maxFiles && maxFiles > 1" class="has-error">
            {{ 'forms.Maximum allowed files is' | translate}} {{ maxFiles }}
          </div>
          <div
            *ngIf="selectedfilesCount > maxFiles && maxFiles === 1"
            class="has-error"
          >
            {{ 'forms.Only One File is allowed' | translate }}
          </div>
          <div *ngIf="!validFile" class="has-error">{{ 'forms.Invalid File Extensions' | translate}}</div>
          <div *ngIf="fileMaxSiZeErorr" class="has-error">{{ 'forms.Max File Size' | translate}} {{MaxFileSize}} {{ 'forms.MB' | translate}}</div>
          <div *ngIf="fileMaxNameSiZeErorr" class="has-error"></div>
          <div *ngIf="!files.length && isRequired && isSubmited" class="has-error">
            {{ 'forms.Required' | translate}}
          </div>
          <div *ngIf="!files.length" [hidden]="docsEditable" class="dnd-container">
            <h4>{{ 'forms.No Files' | translate}}</h4>
          </div>
          <div class="files-list" style="display: block !important;">
            <div class="row">
              <div
                *ngFor="let file of files; let i = index"
                [ngClass]="{ 'col-md-6': cols == 2 }"
                class="col-12"
              >
                <div *ngIf="file" class="file-item" >
                  <div class="file-details">
                    <div class="file-icon">
                      <img class="img-icon"
                        *ngIf="fileType(file.name) == 'doc'"
                        src="./assets/images/file-icons/file-doc-icon.svg"
                      />
                      <img class="img-icon"
                        *ngIf="fileType(file.name) == 'jpg'"
                        src="./assets/images/file-icons/file-jpg-icon.svg"
                      />
                      <img class="img-icon"
                        *ngIf="fileType(file.name) == 'pdf'"
                        src="./assets/images/file-icons/file-pdf-icon.svg"
                      />
                      <img class="img-icon"
                        *ngIf="fileType(file.name) == 'png'"
                        src="./assets/images/file-icons/file-png-icon.svg"
                      />
                      <img class="img-icon"
                        *ngIf="fileType(file.name) == 'pptx'"
                        src="./assets/images/file-icons/file-ppt-icon.svg"
                      />
                      <img class="img-icon"
                        *ngIf="fileType(file.name) == 'xls'"
                        src="./assets/images/file-icons/file-xls-icon.svg"
                      />
        
                      <img class="img-icon"
                        *ngIf="fileType(file.name) == 'zip'"
                        src="./assets/images/file-icons/file-zip-icon.svg"
                      />
                    </div>
                    <div>
                      <label [title]="file?.name">{{ file?.name }}</label>
                      <span>{{ formatBytes(file?.size) }}</span>
                    </div>
                  </div>
                  <div class="file-btn">
                    <a *ngIf="download" [download]="file.path" [href]="file.path" target="_blank" class="download">
                      <svg height="20" viewBox="0 0 20 20" width="20">
                        <path
                          d="M2.4,20A2.5,2.5,0,0,1,0,17.508V11.42a.294.294,0,0,1,.093-.2l3.07-5.918a.432.432,0,0,1,.419-.219H5.349a.489.489,0,0,1,0,.977H3.861L1.3,10.953h2.6A3.047,3.047,0,0,1,6.7,12.836a2.154,2.154,0,0,0,1.953,1.3h2.7a2.154,2.154,0,0,0,1.953-1.3,3.047,3.047,0,0,1,2.791-1.883h2.651L16.582,6.064h-1.93a.489.489,0,0,1,0-.977h2.233a.457.457,0,0,1,.442.244l2.6,5.893a.464.464,0,0,1,.07.2v6.088A2.5,2.5,0,0,1,17.6,20Zm7.279-8.68L6.512,7.973a.511.511,0,0,1,0-.588.453.453,0,0,1,.651-.1L9.535,9.781V.49a.466.466,0,1,1,.93,0V9.781l2.372-2.494a.446.446,0,0,1,.558,0,.507.507,0,0,1,.093.686L10.326,11.32a.284.284,0,0,1-.027.029.428.428,0,0,1-.624-.029Z"
                          fill="#86241d"
                          id="download-btn"
                        />
                      </svg>
                    </a>
                    <button
                      (click)="deleteFile(i, file.attachmentId)"
                      class="btn-delete"
                    >
                      <svg height="20" viewBox="0 0 20 20" width="20">
                        <path
                          d="M0,10A9.938,9.938,0,0,1,10,0,9.938,9.938,0,0,1,20,10,9.938,9.938,0,0,1,10,20,9.938,9.938,0,0,1,0,10Zm1.429,0A8.571,8.571,0,1,0,10,1.429,8.6,8.6,0,0,0,1.429,10ZM10,11.143,6.143,15,5,13.857,8.857,10,5,6.143,6.143,5,10,8.857,13.857,5,15,6.143,11.143,10,15,13.857,13.857,15Z"
                          data-name="Union 4"
                          fill="#959595"
                          id="Union_4"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        




      </div>




      <!-- <div>
        <svg height="25" viewBox="0 0 19 25" width="19" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11362.336-8571a2.322,2.322,0,0,1-2.336-2.3v-15.742h4.725a2.321,2.321,0,0,0,2.335-2.3V-8596h9.605a2.317,2.317,0,0,1,2.335,2.3v20.4a2.317,2.317,0,0,1-2.335,2.3Zm6.421-5.439a.738.738,0,0,0,.743.732.738.738,0,0,0,.743-.732v-5.292l1.916,1.887a.75.75,0,0,0,1.051,0,.723.723,0,0,0,0-1.035l-3.185-3.138a.749.749,0,0,0-1.05,0l-3.185,3.138a.725.725,0,0,0,0,1.035.75.75,0,0,0,1.051,0l1.915-1.887Zm-3.184-10.2a.738.738,0,0,0,.743.732h6.368a.738.738,0,0,0,.743-.732.737.737,0,0,0-.743-.732h-6.368A.738.738,0,0,0,11365.573-8586.638Zm-5.573-3.871v-.052a.729.729,0,0,1,.219-.518l4.776-4.707a.747.747,0,0,1,.524-.215h.054v4.654a.844.844,0,0,1-.849.837Z"
            fill="#7d4393" id="upload-icon" transform="translate(-11360 8596)" />
        </svg>
      </div>

      <h4>{{ label ? label : 'fileManager.Drag and drop' | translate }}</h4>
      <div>
        <span *ngIf="label"> {{'fileManager.Or' | translate}} </span>
        <span *ngIf="!label"> {{'fileManager.Supporting Documents here Or' | translate}}</span>
        <label [attr.for]="'fileDropRef_' + managerIndex"> {{'fileManager.Browse Your Files' | translate}}</label>
      </div>
      <div class="hint">
        {{'fileManager.Supported Formats' | translate}}:
        <ng-container *ngIf="allowedExt.length < 14">
          <span *ngFor="let item of allowedExt; index as i" class="text-uppercase">{{ i != 0 ? ', ' : '' }}
            {{item}}</span>
        </ng-container>
        <span *ngIf="allowedExt.length == 14">PDF, DOC, JPG, and PNG</span>
      </div> -->
      <!--
    <div class="hint">
      {{ 'fileManager.SupportsFiles' | translate }}
    </div> -->
    </div>
  </div>

  <!-- <div class="hint-listing">
        <div [ngClass]="{'has-error': selectedfilesCount > maxFiles}">{{ 'fileManager.Max-files-is' | translate }} {{maxFiles}}</div>
        <!- - <div [ngClass]="{'has-error': !validFile}">{{ 'fileManager.InvalidFile' | translate }}</div> - ->
        <div [ngClass]="{'has-error': !validFile}">{{ 'fileManager.AcceptableFiles' | translate }}</div>
        <div [ngClass]="{'has-error': fileMaxSiZeErorr}">{{ 'fileManager.Max-file-size' | translate }}</div>
        <div [ngClass]="{'has-error': !files.length && isRequired && isSubmited}">{{ 'fileManager.Required' | translate }}</div>
    </div> -->
  <div *ngIf="(selectedfilesCount > maxFiles && maxFiles > 1)&&!renderBtn" class="has-error">
    {{ 'fileManager.Maximum allowed files is' | translate}} {{ maxFiles }}
  </div>
  <div *ngIf="(selectedfilesCount > maxFiles && maxFiles === 1)&&!renderBtn" class="has-error">
    {{ 'fileManager.Only One File is allowed' | translate }}
  </div>
  <div *ngIf="!validFile&&!renderBtn" class="has-error">{{ 'fileManager.Invalid File Extensions' | translate}}</div>
  <div *ngIf="fileMaxSiZeErorr&&!renderBtn" class="has-error">{{ 'fileManager.Max File Size' | translate}}
    {{MaxFileSize}} {{
    'fileManager.MB' | translate}}</div>
  <!-- <div *ngIf="fileMaxNameSiZeErorr" class="has-error"></div> -->
  <div *ngIf="(!files.length && isRequired && isSubmited)&&!renderBtn&& Mode!=='all'" class="has-error">
    {{ 'fileManager.Required' | translate}}
  </div>
  <div *ngIf="!files.length&&!renderBtn" [hidden]="docsEditable" class="dnd-container">
    <h4>{{ 'fileManager.No Files' | translate}}</h4>
  </div>
  <div class="files-list" *ngIf="(files.length && Mode=='multi')&&!renderBtn">
    <div *ngFor="let file of files; let i = index">
      <!-- {{file |json}} -->
      <img width="100" height="100" *ngIf="file.path" [src]="file?.path" (error)="file.path = ''" />
      <img width="100" height="100" *ngIf="!file?.path" src="assets/images/default-img.png"
        style="object-fit: cover;" />
      <div (click)="deleteFile(i, file.uploadedFileName)" *ngIf="!loading" class="closeButton rounded-circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="8.4" height="8.4" viewBox="0 0 8.4 8.4">
          <path id="close-ic"
            d="M206.2,303.2l-2.992,2.992a.705.705,0,1,1-1-1L205.2,302.2l-2.992-2.992a.705.705,0,1,1,1-1L206.2,301.2l2.992-2.992a.705.705,0,1,1,1,1L207.2,302.2l2.992,2.992a.705.705,0,1,1-1,1Z"
            transform="translate(-202 -298)" fill="#322075" />
        </svg>
      </div>

    </div>
  </div>
</div>