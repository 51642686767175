import { Component, Input, OnInit } from '@angular/core';
import { MenuService } from 'src/app/layout/adminpanel/menu.service';
import { WalletService } from '../../services/wallet.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/core/service/config.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateComponent } from 'src/app/features/user-managment/component/all-users/create/create.component';
import { TopUpWalletComponent } from '../top-up-wallet/top-up-wallet.component';
import { SideNavConfigService } from 'src/app/layout/main-layout/side-nav-config.service';

@Component({
  selector: 'app-wallet-detailes',
  templateUrl: './wallet-detailes.component.html',
  styles: [],
})
export class WalletDetailesComponent implements OnInit {
  userID: any;
  authUser: any = localStorage.getItem('auth');
  admin = false;
  TransactionDays: any;
  balance: any;
  loading: boolean = false;
  userInfo = this._config.getAuthUserInfo();
  is_profile_completed: boolean = false;
  constructor(
    // private menu: MenuService,
    private walletService: WalletService,
    public _config: ConfigService,
    private dialog: MatDialog,
    private _sideNavService: SideNavConfigService
  ) {
    if (this._config.getAuthUserInfo()) {
      this.admin =
        this._config.getAuthUserInfo().user.role_code == 'Admin' ? true : false;
    }
  }

  ngOnInit(): void {
    this._sideNavService.getSideNav().subscribe((data) => {
      if (data.status && data.type == 'wallet') {
        // let Url = this._config._router.url
        // if (Url.includes("/users/profile/")) {
        //   this.userID = Url.split('/').reverse()[0]
        // } else {
        //   this.userID = JSON.parse(this.authUser)?.user?.id
        // }
        this.userID = data.id;
        this.getWalletDetails();
      }
    });
  }

  onClose() {
    // this.menu.ToggleWallet(false)
    this._sideNavService.ChangeSideNav({ status: false, type: 'wallet' });
  }

  getWalletDetails() {
    this.loading = true;
    this.walletService.userTransactionsDays(this.userID).subscribe(
      (res: any) => {
        this.loading = false;
        this.TransactionDays = res.data.list;
        this.balance = res.data.current_balance;
        this.is_profile_completed = res.data.is_profile_completed;
      },
      (error: any) => {
        this.loading = false;
        this._config.showErrors(error);
      }
    );
  }

  getTransactiontDetails(date: any, dayIndex: number) {
    this.TransactionDays[dayIndex].loading = true;
    this.walletService.transactionsDayDetailes(date, this.userID).subscribe(
      (res: any) => {
        this.TransactionDays[dayIndex].loading = false;
        this.TransactionDays[dayIndex].transactions = res.data;
      },
      (error: any) => {
        this.TransactionDays[dayIndex].loading = false;
        this._config.showErrors(error);
      }
    );
  }

  TopupWallet(id = null) {
    if (!this.is_profile_completed) {
      this._config.toastrService.clear();
      this._config.toastrService.error(this._config.translate.instant("bidders.Please complete your profile and try again later."));
      return;
    }
    const modle = {
      paymentProvider: id,
    };
    const dialogRef = this.dialog.open(TopUpWalletComponent, {
      width: '550px',
      disableClose: true,
      panelClass: 'customDialogStyle',
      data: modle,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.TopupWallet(res);
      }
    });
  }
}
