import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideNavConfigService {
  public RightSideNav = new BehaviorSubject<SideNavModal>({ status: false });

  constructor() { }

  ChangeSideNav(data: SideNavModal) {
    this.RightSideNav.next(data)
  }

  getSideNav() {
    return this.RightSideNav.asObservable()
  }


}


export interface SideNavModal {
  status?: boolean,
  type?: 'wallet' | 'staffProfile' | 'history' | 'item_details',
  id?: string,
  disableClose?: boolean;
  width?: string;
}