import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from 'src/app/core/service/config.service';
import { SuspendUnsuspendComponent } from 'src/app/features/user-managment/component/profile/suspend-unsuspend/suspend-unsuspend.component';
import { StaffProfileComponent } from '../staff-profile.component';
import { StaffService } from 'src/app/features/user-managment/core/service/staff.service';

@Component({
  selector: 'app-change-status-staff',
  templateUrl: './change-status-staff.component.html',
  styles: [
  ]
})
export class ChangeStatusStaffComponent implements OnInit {
  suspendForm!: FormGroup
  loading: boolean = false;
  constructor(private _formBuilder: FormBuilder,
    private _config: ConfigService,
    private _staffService: StaffService,
    public dialogRef: MatDialogRef<StaffProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log("Dialog Data", this.data);

  }

  ngOnInit(): void {
  }

  OnDeactivateStaff() {
    this.loading = true;
    this._staffService.DeactivateStaff(this.data.user.id).subscribe({
      next: (res) => {
        this.loading = false;
        this._config.toastrService.success(res.message)
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.loading = false;
        this._config.showErrors(err);
      }
    })
  }

  OnActivateStaff() {
    this.loading = true;
    this._staffService.ActivateStaff(this.data.user.id).subscribe({
      next: (res) => {
        this.loading = false;
        this._config.toastrService.success(res.message);
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.loading = false;
        this._config.showErrors(err);
      }
    })
  }
}
