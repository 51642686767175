
export const environment = {
    production: true,
    apiUrl: 'https://auctiontestapi.mosandah.com.sa/api/',
    socketUrl: 'https://auctiontest.mosandah.com.sa',
    streamUrlPublish: 'wss://origin.inndeve.com:443/WebRTCAppEE/websocket?target=origin',
    streamUrlPlay: 'wss://edge.inndeve.com:443/WebRTCAppEE/websocket',
    streamUrl: 'https://origin.inndeve.com:443'

};
