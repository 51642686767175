import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  jwt: string | undefined;
  infoUser: any;
  auth: any;
  constructor() { }


  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const language = localStorage.getItem('language') || 'en';
    const requestHeader = {
      'accept-language': language,
      lang: language
    } as any;

    if (localStorage.getItem('auth')) {
      this.auth = localStorage.getItem("auth");
      this.infoUser = JSON.parse(this.auth);

      this.jwt = this.infoUser.token;
      requestHeader.Authorization = `Bearer ${this.jwt}`;
    }
    request = request.clone({
      setHeaders: requestHeader,
      setParams: this.setDateParams(request.params)
    });
    // this.shiftDates(request.body);
    return next.handle(request);
  }


  makeDate(date: Date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return year + '/' + month + '/' + day;
  }

  setDateParams(params: HttpParams) {
    const paramsValue: any = {};
    params.keys().forEach(p => {
      const value = params.get(p) as any;
      if (value instanceof Date) {
        paramsValue[p] = this.makeDate(value);
      } else if (value !== undefined) {
        paramsValue[p] = value;
      }
    });
    return paramsValue;
  }

  dateReplacer(obj: any) {
    if (obj) {
      for (const k in obj) {
        if (obj.hasOwnProperty(k)) {
          if (obj[k] instanceof Object) {
            obj[k] = this.dateReplacer(obj[k]);
          }
          if (obj[k] instanceof Date) {
            obj[k] = new Date(`${obj[k]} UTC`);
          }
        }
      }
      return obj;
    }
  }

  shiftDates(body: any) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
        body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes()
          , value.getSeconds()));
      } else if (typeof value === 'object') {
        this.shiftDates(value);
      }
    }
  }

}
