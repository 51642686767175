import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private LessThan60Second = new BehaviorSubject<any>({});

  SetSeconds(seconds: any) {
    this.LessThan60Second.next(seconds)
  }

  GetSeconds(): Observable<any> {
    return this.LessThan60Second.asObservable()
  }

  constructor() { }

}
