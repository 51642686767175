import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ApiService } from 'src/app/core/service/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private apiService: ApiService) { }

  userTransactionsDays(userId: any) {
    return this.apiService
      .get(`${environment.apiUrl}userManagement/transactions-days/${userId}`)
      .pipe(catchError(this.handleError));
  }

  transactionsDayDetailes(modal: any, userId: any) {
    return this.apiService
      .get(`${environment.apiUrl}userManagement/transactions-for-one-day/${userId}`, modal)
      .pipe(catchError(this.handleError));
  }

  topupMyWallet(modal: any) {
    return this.apiService
      .post(`${environment.apiUrl}userManagement/top-up-my-wallet`, modal)
      .pipe(catchError(this.handleError));
  }

  paymentRequest(modal: any) {
    return this.apiService
      .post(`${environment.apiUrl}userManagement/payment-request`, modal)
      .pipe(catchError(this.handleError));
  }

  paymentConfirmation(modal: any) {
    return this.apiService
      .put(`${environment.apiUrl}userManagement/payment-confirmation/`+ modal)
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }


}
