import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { AdminpanelComponent } from './layout/adminpanel/adminpanel.component';
import { AuthComponent } from './features/auth/auth.component';
import { BidersComponent } from './layout/biders/biders.component';
import { NoPermissionComponent } from './shared/Components/no-permission/no-permission.component';
import { PagNotFoundComponent } from './shared/Components/pag-not-found/pag-not-found.component';
import { IsAdminGuard } from './core/guard/is-admin.guard';
import { PaymentConfirmationPageComponent } from './shared/Components/payment-confirmation-page/payment-confirmation-page.component';

const routes: Routes = [

  { path: 'paymentconfirmation/:token', component: PaymentConfirmationPageComponent },
  {
    path: 'NoPermission',
    component: NoPermissionComponent
  },
  {
    path: 'PageNotFound',
    component: PagNotFoundComponent
  },
  {
    path: 'auth',
    component: AuthComponent,
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin/requests',
    component: AdminpanelComponent,
    loadChildren: () => import('./features/items-requests/items-requests.module').then(m => m.ItemsRequestsModule)
  },
  {
    path: 'admin',
    component: AdminpanelComponent,
    canActivate: [AuthGuard, IsAdminGuard],
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
  },

  {
    path: 'admin',
    component: AdminpanelComponent,
    canActivate: [AuthGuard, IsAdminGuard],
    loadChildren: () => import('./features/user-managment/user-managment.module').then(m => m.UserManagmentModule)
  },


  {
    path: 'rooms',
    component: AdminpanelComponent,
    canActivate: [AuthGuard, IsAdminGuard],
    loadChildren: () => import('./features/rooms/rooms.module').then(m => m.RoomsModule)
  },
  {
    path: 'setting',
    component: AdminpanelComponent,
    canActivate: [AuthGuard, IsAdminGuard],
    loadChildren: () => import('./features/setting/setting.module').then(m => m.SettingModule)
  },
  {
    path: 'pages',
    component: BidersComponent,
    loadChildren: () => import('./features/pages/pages.module').then(m => m.PagesModule)
  },
  { 
    path: 'mulit-auction', 
    component:BidersComponent,
    loadChildren: () => import('./features/multi-auction-bidder/multi-auction-bidder.module').then(m => m.MultiAuctionBidderModule) 
  
  },


  {
    path: '',
    component: BidersComponent,
    loadChildren: () => import('./features/bidders/bidders.module').then(m => m.BiddersModule)
  },

  

  // {
  //   path: 'NoPermission',
  //   component: NoPermissionComponent
  // },
  // {
  //   path: 'PageNotFound',
  //   component: PagNotFoundComponent
  // }
  //Wild Card Route for 404 request
  // {
  // path: '**', pathMatch: 'full',
  //  component: PagNotFoundComponent
  //  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
