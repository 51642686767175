import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { ApiService } from 'src/app/core/service/api.service';
import { ConfigService } from 'src/app/core/service/config.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ItemRequestsService {

    constructor(private apiService: ApiService, private _config: ConfigService) { }

    GetAllRequests(modal: any) {
        console.log(modal);
        // delete modal.type;
        modal.created_from = modal.created_from ? moment(modal.created_from).format('YYYY-MM-DD') : '';
        modal.created_to = modal.created_to ? moment(modal.created_to).format('YYYY-MM-DD') : '';
        return this.apiService
            .get(`${environment.apiUrl}auction/requests`, modal)
            .pipe(catchError(this.handleError));
    }

    AddNewRequest(modal: any) {
        return this.apiService
            .post(`${environment.apiUrl}auction/requests`, modal)
            .pipe(catchError(this.handleError));
    }

    UpdateRequest(modal: any, RequestId: string) {
        return this.apiService
            .post(`${environment.apiUrl}auction/requests/${RequestId}`, modal)
            .pipe(catchError(this.handleError));
    }


    GetRequestById(id: string) {
        return this.apiService
            .get(`${environment.apiUrl}auction/auctions/${id}`)
            .pipe(catchError(this.handleError));
    }

    GetRequestByIdForAdmin(id: string) {
        return this.apiService
            .get(`${environment.apiUrl}admin/auction/auctions/${id}`)
            .pipe(catchError(this.handleError));
    }

    GetRequestLog(id: string) {
        return this.apiService
            .get(`${environment.apiUrl}auction/history/requests/${id}`)
            .pipe(catchError(this.handleError));
    }

    getCategories() {
        return this.apiService
            .get(`${environment.apiUrl}auction/categories?perPage=20&sort=ASC`)
            .pipe(catchError(this.handleError));
    }

    getCategorytemplate(category_id: string) {
        return this.apiService
            .get(`${environment.apiUrl}auction/templates/${category_id}`)
            .pipe(catchError(this.handleError));
    }
    declineChange(id: string, modal: any) {
        return this.apiService
            .post(`${environment.apiUrl}auction/decline-changes/requests/${id}`, modal)
            .pipe(catchError(this.handleError));
    }
    getChanges(id: string) {
        return this.apiService
            .get(`${environment.apiUrl}auction/changes-request/requests/${id}`)
            .pipe(catchError(this.handleError));
    }


    approveChange(id: string) {
        return this.apiService
            .post(`${environment.apiUrl}auction/approve-changes/requests/${id}`)
            .pipe(catchError(this.handleError));
    }


    DeclineRequest(modal: any) {
        return this.apiService
            .post(`${environment.apiUrl}auction/decline/requests/${modal.id}`, modal)
            .pipe(catchError(this.handleError));
    }

    RemoveRequest(modal: any) {
        return this.apiService
            .remove(`${environment.apiUrl}auction/auctions/${modal.id}`)
            .pipe(catchError(this.handleError));
    }

    handleError(error: any) {
        return throwError(error);
    }
}
