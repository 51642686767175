import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = localStorage.getItem('auth');
    if (currentUser) {
      return true;
    }
    // not logged in so redirect to login page with the return url


    // with return Url
    // this.router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}});

    // without return Url
    this.router.navigate(['/auth/login']);
    return false;
  }
}
