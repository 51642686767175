import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appSelectTranslatedText]'
})
export class SelectTranslatedTextDirective {
  @Input() ArKey: any;
  @Input() EnKey: any;

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
  }

  SelectText() {
    switch (localStorage.getItem('language')) {
      case "en":
        this.elementRef.nativeElement.innerHTML = this.EnKey
        break;
      case "ar":
        this.elementRef.nativeElement.innerHTML = this.ArKey
        break;

      default:
        this.elementRef.nativeElement.innerHTML = this.EnKey
        break;
    }
  }
  ngOnChanges(changes: any): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.SelectText();

  }
}
