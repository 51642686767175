import { Directive, ElementRef, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/core/service/config.service';

@Directive({
  selector: '[IsAdmin]'
})
export class IsAdminDirective implements OnInit {

  constructor(private _elRef: ElementRef, private _config: ConfigService) { }

  ngOnInit(): void {
    this.CkeckIfAdmin();
  }

  CkeckIfAdmin() {
    let IsAdminRole = this._config.getAuthUserInfo()?.user?.role_code == 'Admin';
    if (!IsAdminRole) {
      this._elRef.nativeElement.style.display = 'none';
    }
  }
}
