import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ApiService } from 'src/app/core/service/api.service';
import { environment } from 'src/environments/environment';
import { RegisterModal } from '../modal/register-modal';
import { ResetPassModal } from '../modal/reset-pass-modal';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  constructor(private apiService: ApiService) { }

  allStaff(modal: any) {
    modal.role = 'staff';
    modal.created_from = modal.created_from ? moment(modal.created_from).format('YYYY-MM-DD') : '';
    modal.created_to = modal.created_to ? moment(modal.created_to).format('YYYY-MM-DD') : '';
    return this.apiService
      .get(`${environment.apiUrl}userManagement/users`, modal)
      .pipe(catchError(this.handleError));
  }
  // onGetAllRoles() {
  //   return this.apiService
  //     .get(`${environment.apiUrl}userManagement/roles`)
  //     .pipe(catchError(this.handleError));
  // }
  // getUser(id: string) {
  //   return this.apiService
  //     .get(`${environment.apiUrl}userManagement/users/${id}`)
  //     .pipe(catchError(this.handleError));
  // }
  // register(modal: RegisterModal) {
  //   return this.apiService
  //     .post(`${environment.apiUrl}userManagement/users`, modal)
  //     .pipe(catchError(this.handleError));
  // }
  // delete(id: string) {
  //   return this.apiService
  //     .remove(`${environment.apiUrl}userManagement/users/${id}`,)
  //     .pipe(catchError(this.handleError));
  // }
  // changeStatus(id: string, modal: any) {
  //   return this.apiService
  //     .update(`${environment.apiUrl}userManagement/users/change-status/${id}`, modal)
  //     .pipe(catchError(this.handleError));
  // }
  // EditUser(id: string, modal: any) {
  //   return this.apiService
  //     .update(`${environment.apiUrl}userManagement/users/${id}`, modal)
  //     .pipe(catchError(this.handleError));
  // }

  // resetPass(id: string, modal: ResetPassModal) {
  //   return this.apiService
  //     .update(`${environment.apiUrl}userManagement/users/update-password/${id}`, modal)
  //     .pipe(catchError(this.handleError));
  // }


  // SendOtpToUpdateMail(modal: any, UserId: string) {
  //   return this.apiService
  //     .update(`${environment.apiUrl}userManagement/users-update-email-send-otp/${UserId}`, modal)
  //     .pipe(catchError(this.handleError));
  // }
  // UpdateEmail(modal: any, UserId: string) {
  //   return this.apiService
  //     .update(`${environment.apiUrl}userManagement/users-update-email/${UserId}`, modal)
  //     .pipe(catchError(this.handleError));
  // }

  // UpdatePrivacySetting(modal: any) {
  //   return this.apiService
  //     .post(`${environment.apiUrl}userManagement/users/privacy`, modal)
  //     .pipe(catchError(this.handleError));
  // }



  // Staff Functions

  staffFormValidations(data?: any): object {
    return {
      firstname: new FormControl(data?.firstname || null, [Validators.required]),
      lastname: new FormControl(data?.lastname || null, [Validators.required]),
      email: new FormControl(data?.email || '', [Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-z]{2,4}$")]),
      phone: [data?.phone || null, [Validators.required, Validators.maxLength(20)]],
      national_number: new FormControl(data?.national_number || '', []),
      resident_number: new FormControl(data?.resident_number || null, []),
      is_national_number: new FormControl(data ? data?.is_national_number : true),
      role: new FormControl(data?.roles[0]?.role_code || '', [Validators.required]),
      // gender: new FormControl(data?.gender || null, [Validators.required]),
      rooms: new FormControl(data?.rooms.map((item: any) => item.id) || null, [Validators.required]),
    }
  }

  getAllAuctions(modal: any) {
    return this.apiService
      .get(`${environment.apiUrl}admin/auction/rooms`, modal)
      .pipe(catchError(this.handleError));
  }

  addNewStaff(modal: any) {
    return this.apiService
      .post(`${environment.apiUrl}userManagement/staff`, modal)
      .pipe(catchError(this.handleError));
  }

  UpdateStaff(modal: any, staffId: string) {
    return this.apiService
      .update(`${environment.apiUrl}userManagement/staff/${staffId}`, modal)
      .pipe(catchError(this.handleError));
  }

  getStaffById(id: string) {
    return this.apiService
      .get(`${environment.apiUrl}userManagement/users/${id}`)
      .pipe(catchError(this.handleError));
  }

  deleteStaff(id: string) {
    return this.apiService
      .remove(`${environment.apiUrl}userManagement/users/${id}`,)
      .pipe(catchError(this.handleError));
  }

  ResendActivationCodeForStaff(id: any) {
    return this.apiService
      .post(`${environment.apiUrl}userManagement/resend-verfication-staff/${id}`)
      .pipe(catchError(this.handleError));
  }

  DeactivateStaff(staffId: string) {
    return this.apiService
      .update(`${environment.apiUrl}userManagement/users/suspend/${staffId}`, { reason_suspend: "no reason" })
      .pipe(catchError(this.handleError));
  }

  ActivateStaff(staffId: string) {
    return this.apiService
      .update(`${environment.apiUrl}userManagement/users/unsuspend/${staffId}`)
      .pipe(catchError(this.handleError));
  }

  changeProfileImage(id: any, model: any) {
    return this.apiService
      .upload(`${environment.apiUrl}userManagement/users/update-profile-image/${id}`, model)
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }

}
