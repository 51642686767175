<mat-sidenav-container class="example-container" [ngClass]="{'pages-sidenav-container': isHome}">
  <mat-sidenav #sidenav mode="over" [disableClose]="toggleDrawer.disableClose" [opened]="toggleDrawer.status"
    (closed)="onClose($event)" class="example-sidenav" [position]="'end'" [fixedInViewport]="true" [fixedTopGap]="0"
    [fixedBottomGap]="0" [ngStyle]="{'width': toggleDrawer.width?toggleDrawer.width:'34.375rem'}">
    <span [ngSwitch]="toggleDrawer.type">
      <p *ngSwitchCase="'wallet'">
        <app-wallet-detailes></app-wallet-detailes>
      </p>
      <p *ngSwitchCase="'staffProfile'">
        <app-staff-profile></app-staff-profile>
      </p>
      <p *ngSwitchCase="'history'">
        <app-request-log></app-request-log>
      </p>
      <p *ngSwitchCase="'item_details'">
        <app-show-item-detailes [itemId]="toggleDrawer.id||''"></app-show-item-detailes>
      </p>
      <p *ngSwitchDefault>
        <app-wallet-detailes></app-wallet-detailes>
      </p>
    </span>
  </mat-sidenav>

  <mat-sidenav-content>
    <ng-content #heaer></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>