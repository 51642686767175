<div>
    <div class="successConfirm d-flex justify-content-center align-items-center flex-column">
        <div class="w-100 text-end">
            <span mat-button mat-dialog-close>
                <svg xmlns="http://www.w3.org/2000/svg" width="13.998" height="13.998" viewBox="0 0 13.998 13.998">
                    <path id="close-btn"
                        d="M8.177-11.911,6.768-13.32l-5.59,5.59-5.59-5.59L-5.82-11.911l5.59,5.59L-5.82-.732-4.411.677l5.59-5.59L6.768.677,8.177-.732l-5.59-5.59Z"
                        transform="translate(5.82 13.32)" fill="#bbb" />
                </svg>
            </span>
        </div>
        <img src="assets/images/icons/successIcon.svg" alt="">
        <h4>{{'items-requests.Top-up Completed Successfully' | translate}}</h4>
        <p class="balance">{{"Auction Item.SR"|translate}} <span>{{data.amount | number}}</span></p>
        <p class="hint">{{'items-requests.has been added to your wallet' | translate}}</p>
        <div class="text-center">
            <button mat-button mat-dialog-close class="btn_fill" >{{'items-requests.Ok, I got it' |
                translate}}</button>
        </div>
    </div>
</div>