import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateUTC'
})
export class DateUTCPipe implements PipeTransform {

  transform(value: Date, format: string = 'LL h:mm A'): unknown {
    return moment(value).format(format)
  }

}
