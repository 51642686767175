import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/service/api.service';
import { environment } from 'src/environments/environment';
import { catchError, throwError } from 'rxjs';
import { RegisterModal } from '../modal/register-modal';
import { ResetPassModal } from '../modal/reset-pass-modal';

@Injectable({
  providedIn: 'root'
})
export class ApiUserService {

  constructor(private apiService: ApiService) { }

  allUser(modal: any) {
    modal.role = 'general'
    return this.apiService
      .get(`${environment.apiUrl}userManagement/users`, modal)
      .pipe(catchError(this.handleError));
  }
  onGetAllRoles() {
    return this.apiService
      .get(`${environment.apiUrl}userManagement/roles`)
      .pipe(catchError(this.handleError));
  }
  getUser(id: string) {
    return this.apiService
      .get(`${environment.apiUrl}userManagement/users/${id}`)
      .pipe(catchError(this.handleError));
  }
  register(modal: RegisterModal) {
    return this.apiService
      .post(`${environment.apiUrl}userManagement/users`, modal)
      .pipe(catchError(this.handleError));
  }
  delete(id: string) {
    return this.apiService
      .remove(`${environment.apiUrl}userManagement/users/${id}`,)
      .pipe(catchError(this.handleError));
  }
  changeStatus(id: string, modal: any) {
    return this.apiService
      .update(`${environment.apiUrl}userManagement/users/change-status/${id}`, modal)
      .pipe(catchError(this.handleError));
  }
  EditUser(id: string, modal: any) {
    return this.apiService
      .update(`${environment.apiUrl}userManagement/users/${id}`, modal)
      .pipe(catchError(this.handleError));
  }

  resetPass(id: string, modal: ResetPassModal) {
    return this.apiService
      .update(`${environment.apiUrl}userManagement/users/update-password/${id}`, modal)
      .pipe(catchError(this.handleError));
  }
  changeProfileImage(id: string, model: any) {

    return this.apiService
      .upload(`${environment.apiUrl}userManagement/users/update-profile-image/${id}`, model)
      .pipe(catchError(this.handleError));
  }

  SendOtpToUpdateMail(modal: any, UserId: string) {
    return this.apiService
      .update(`${environment.apiUrl}userManagement/users-update-email-send-otp/${UserId}`, modal)
      .pipe(catchError(this.handleError));
  }
  UpdateEmail(modal: any, UserId: string) {
    return this.apiService
      .update(`${environment.apiUrl}userManagement/users-update-email/${UserId}`, modal)
      .pipe(catchError(this.handleError));
  }

  SendOtpToUpdatePhone(modal: any, UserId: string) {
    return this.apiService
      .update(`${environment.apiUrl}userManagement/users-update-phone-send-otp/${UserId}`, modal)
      .pipe(catchError(this.handleError));
  }
  UpdatePhone(modal: any, UserId: string) {
    return this.apiService
      .update(`${environment.apiUrl}userManagement/users-update-phone/${UserId}`, modal)
      .pipe(catchError(this.handleError));
  }

  UpdatePrivacySetting(modal: any) {
    return this.apiService
      .post(`${environment.apiUrl}userManagement/users/privacy`, modal)
      .pipe(catchError(this.handleError));
  }

  getMyPublishedRequests() {
    return this.apiService
      .get(`${environment.apiUrl}auction/bidder/auctions/requests/my-publish-requests`)
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }






}
