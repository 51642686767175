<div>
  <!-- <div *ngSwitchCase="true">
        <div class="successConfirm d-flex justify-content-center align-items-center flex-column">
            <div class="w-100 text-end">
                <span mat-button (click)="reload()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.998" height="13.998" viewBox="0 0 13.998 13.998">
                        <path id="close-btn"
                            d="M8.177-11.911,6.768-13.32l-5.59,5.59-5.59-5.59L-5.82-11.911l5.59,5.59L-5.82-.732-4.411.677l5.59-5.59L6.768.677,8.177-.732l-5.59-5.59Z"
                            transform="translate(5.82 13.32)" fill="#bbb" />
                    </svg>
                </span>
            </div>
            <img src="assets/images/icons/successIcon.svg" alt="">
            <h4>{{'items-requests.Top-up Completed Successfully' | translate}}</h4>
            <p class="balance">SR <span>{{this.topUpForm.value.amount | number}}</span></p>
            <p class="hint">{{'items-requests.has been added to your wallet' | translate}}</p>
            <div class="text-center">
                <button mat-button class="btn_fill" (click)="reload()">{{'items-requests.Ok, I got it' |
                    translate}}</button>
            </div>
        </div>
    </div> -->
  <div>
    <div class="dialog-title">
      <h3>{{'items-requests.Top-up Wallet' | translate}}</h3>
      <span mat-button mat-dialog-close>
        <svg xmlns="http://www.w3.org/2000/svg" width="13.998" height="13.998" viewBox="0 0 13.998 13.998">
          <path id="close-btn"
            d="M8.177-11.911,6.768-13.32l-5.59,5.59-5.59-5.59L-5.82-11.911l5.59,5.59L-5.82-.732-4.411.677l5.59-5.59L6.768.677,8.177-.732l-5.59-5.59Z"
            transform="translate(5.82 13.32)" fill="#bbb" />
        </svg>
      </span>
    </div>
    <div mat-dialog-content>
      <div class="topupWallet">
        <ng-container *ngIf="!paymentProvider">
          <form [formGroup]="topUpForm" class="mb-4">
            <p>{{'items-requests.Enter amount to top-up' | translate}}</p>
            <div class="inputs-form-section">
              <mat-form-field appearance="outline" class="form-group">
                <mat-label>{{'items-requests.Top-up amount' | translate}}</mat-label>
                <input matInput appOnlynumber formControlName="amount" maxlength="9" placeholder="Ex: 2,000">
                <span matSuffix>{{"Auction Item.SR"|translate}}&nbsp;&nbsp;</span>
                <mat-error *ngIf="topUpForm.controls['amount'].hasError('required') ">
                  {{'Auctions.Field is Required'|translate}}
                </mat-error>
              </mat-form-field>
            </div>
            <!-- <div class="group_btn mt-3  ">
                        <button class="btnLight_fill" mat-button mat-dialog-close *ngIf="!loading">
                            {{'users.CANCEL' | translate}}
                        </button>
                        <button class="btn_fill" mat-button type="submit" [appLoading]="loading">
                            {{'Auctions.Submit' | translate}}
                        </button>
                    </div> -->
          </form>
          <h5>{{'pages.Choose payment method below' | translate}}</h5>
          <div class="e-payment-cards-container">
            <div class="row">
              <div class="col-md-6">
                <div class="e-payment-card" (click)="changeMethod(1)" [class.active]="paymentProvider == 1">
                  <img src="./assets/images/visa-card.png" alt="">
                  <div class="e-payment-title">{{'pages.PAY WITH VISA, MASTER,AMEX, STC_PAY' | translate}}</div>
                </div>
              </div>
              <!-- <div class="col-lg-4">
                                <div class="e-payment-card" (click)="changeMethod(2)"
                                    [class.active]="paymentProvider == 2">
                                     <img src="./assets/images/apple-pay.webp" alt="">
                                    <div class="e-payment-title">PAY WITH APPLE PAY</div>
                                </div>
                            </div> -->
              <div class="col-md-6">
                <div class="e-payment-card" (click)="changeMethod(3)" [class.active]="paymentProvider == 3">
                  <img src="./assets/images/mada_logo.png" alt="">
                  <div class="e-payment-title">{{'pages.PAY WITH MADA' | translate}}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <form *ngIf="!dataLoading && paymentProvider == 1" id="eFinanceForm" [action]="redirectLink"
          class="paymentWidgets" data-brands="VISA MASTER"></form>
        <!-- <form *ngIf="!dataLoading && paymentProvider == 2" id="eFinanceForm" [action]="redirectLink"
                    class="paymentWidgets" data-brands="VISA MASTER MADA"></form> -->
        <form *ngIf="!dataLoading && paymentProvider == 3" id="eFinanceForm" [action]="redirectLink"
          class="paymentWidgets" data-brands="MADA"></form>

      </div>
    </div>
  </div>
</div>
