<div class="dialog-title">
    <h3>{{"Auction Item.Item Detailes"|translate}}</h3>
    <span mat-button (click)="onClose()">
        <svg xmlns="http://www.w3.org/2000/svg" width="13.998" height="13.998" viewBox="0 0 13.998 13.998">
            <path id="close-btn"
                d="M8.177-11.911,6.768-13.32l-5.59,5.59-5.59-5.59L-5.82-11.911l5.59,5.59L-5.82-.732-4.411.677l5.59-5.59L6.768.677,8.177-.732l-5.59-5.59Z"
                transform="translate(5.82 13.32)" fill="#bbb" />
        </svg>
    </span>
</div>
<div class="auction-details p-4" *ngIf="!loading">
    <div class="auction-main-image">
        <img [src]="auctionData?.main_image" alt="" style="max-height: 15rem;" class="image"
            onerror="this.src='assets/images/default-img.png';">
    </div>

    <div class="auction-description">
        <!-- <div class="menu-item">
                <div (click)="scroll(Description)" class="item-name"><span>{{"Auction
                        Item.Description"|translate}}</span>
                </div>
                <div (click)="scroll(Details)" class="item-name"><span>{{"Auction Item.Item Details"|translate}}</span>
                </div>
                <div (click)="scroll(Media)" class="item-name active"><span>{{"Auction Item.Media"|translate}}</span>
                </div>
            </div> -->
        <div class="details-item">
            <div #Description class="description">
                <div class="category">
                    <span class="badge rounded-pill bg-secondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                            <g id="category_ic" transform="translate(-839 -1942.867)">
                                <rect id="Container" width="12" height="12" transform="translate(839 1942.867)"
                                    fill="none" />
                                <path id="category"
                                    d="M6874.252,14872.064a2.7,2.7,0,0,1-.8-1.971,2.722,2.722,0,1,1,.8,1.971Zm.856-3.085a1.605,1.605,0,1,0,1.115-.454A1.492,1.492,0,0,0,6875.108,14868.979Zm-7.415,3.507a.68.68,0,0,1-.495-.2.7.7,0,0,1-.2-.494v-3.472a.7.7,0,0,1,.2-.491.69.69,0,0,1,.495-.2h3.473a.681.681,0,0,1,.5.208.7.7,0,0,1,.2.491v3.468a.685.685,0,0,1-.695.694Zm.5-1.2h2.469v-2.468H6868.2Zm2.275-5.442a.678.678,0,0,1-.609-.357.639.639,0,0,1,.021-.71l2.222-3.586a.653.653,0,0,1,.584-.329.679.679,0,0,1,.6.325l2.222,3.594a.64.64,0,0,1,.014.706.677.677,0,0,1-.609.357Zm.9-1.2h2.656l-1.33-2.136Z"
                                    transform="translate(-6027.999 -12917.998)" fill="#ed793e" />
                            </g>
                        </svg>
                        {{auctionData?.category?.name|titlecase}}
                    </span>
                    <span class="badge rounded-pill  bg-light" *ngIf="auctionData?.room">
                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12.002" viewBox="0 0 11 12.002">
                            <g id="auction_hall_ic" transform="translate(-6851.751 -14883.748)">
                                <path id="auction"
                                    d="M162.615,237.083a.291.291,0,0,1-.215-.095.306.306,0,0,1-.092-.22.3.3,0,0,1,.1-.22.3.3,0,0,1,.222-.095h3.93a.291.291,0,0,1,.215.095.306.306,0,0,1,.092.22.3.3,0,0,1-.1.22.3.3,0,0,1-.222.095Zm1.494-2.5-1.072-1.066a.7.7,0,0,1-.215-.516.788.788,0,0,1,.2-.536l.241-.254,2.149,2.119-.255.254a.733.733,0,0,1-1.054,0Zm2.836-1.772-2.13-2.138.255-.24a.809.809,0,0,1,.541-.2.706.706,0,0,1,.517.214l1.072,1.066a.724.724,0,0,1,0,1.048Zm1.665,3.61-4.782-4.747.447-.445,4.782,4.757a.3.3,0,0,1,0,.435.309.309,0,0,1-.447,0Z"
                                    transform="translate(6691.521 14656.747)" fill="#322075" />
                                <path id="Union_5" data-name="Union 5"
                                    d="M1.249,12a1.207,1.207,0,0,1-.882-.367A1.189,1.189,0,0,1,0,10.754V4.428a1.253,1.253,0,0,1,.127-.562A1.179,1.179,0,0,1,.5,3.432L4.749.248A1.219,1.219,0,0,1,5.5,0a1.2,1.2,0,0,1,.747.254l4.248,3.178a1.137,1.137,0,0,1,.381.434A1.35,1.35,0,0,1,11,4.428v6.326A1.265,1.265,0,0,1,9.751,12Zm.02-7.559v6.285H9.725V4.443L5.5,1.281Z"
                                    transform="translate(6851.751 14883.748)" fill="#322075" />
                            </g>
                        </svg>

                        {{auctionData?.room?.name|titlecase}}
                    </span>
                </div>
                <div class="item-name">
                    <h2> {{auctionData?.name|titlecase}}</h2>
                </div>
                <div class="description-txt">
                    {{auctionData?.description}}
                </div>
                <div class="mb-2 description-txt" IsAdmin>
                    <span [ngSwitch]="auctionData?.is_published">
                        <p *ngSwitchCase="1" class="published">
                            {{'Main Auction.published' | translate}}
                        </p>
                        <p *ngSwitchCase="0" class="unpublished">
                            {{'items-requests.Unpublished' | translate}}
                        </p>
                        <p *ngSwitchDefault class="unpublished">
                            {{'items-requests.Unpublished' | translate}}
                        </p>
                    </span>
                </div>
                <div class="description-time">
                    <span *ngIf="auctionData?.start_at">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <g id="calendar-fill" transform="translate(-798 -278)">
                                <rect id="container" width="20" height="20" transform="translate(798 278)"
                                    fill="none" />
                                <path id="calendar-alt"
                                    d="M14.888,2.531h-.758V1.766a.758.758,0,1,0-1.516,0v.766H6.549V1.766a.758.758,0,1,0-1.516,0v.766H4.274A2.286,2.286,0,0,0,2,4.829v10.72a2.286,2.286,0,0,0,2.274,2.3H14.888a2.286,2.286,0,0,0,2.274-2.3V4.829A2.286,2.286,0,0,0,14.888,2.531Zm.758,4.594H3.516v-2.3a.762.762,0,0,1,.758-.766h.758v.766a.758.758,0,1,0,1.516,0V4.063h6.065v.766a.758.758,0,1,0,1.516,0V4.063h.758a.762.762,0,0,1,.758.766Z"
                                    transform="translate(798.527 278.685)" fill="#e9580e" />
                                <g id="Group_9251" data-name="Group 9251" transform="translate(803.56 288.874)">
                                    <ellipse id="Ellipse_167" data-name="Ellipse 167" cx="0.758" cy="0.766" rx="0.758"
                                        ry="0.766" transform="translate(3.79 3.063)" fill="#fbdecf" />
                                    <ellipse id="Ellipse_168" data-name="Ellipse 168" cx="0.758" cy="0.766" rx="0.758"
                                        ry="0.766" transform="translate(7.581 3.063)" fill="#fbdecf" />
                                    <ellipse id="Ellipse_169" data-name="Ellipse 169" cx="0.758" cy="0.766" rx="0.758"
                                        ry="0.766" transform="translate(7.581)" fill="#fbdecf" />
                                    <ellipse id="Ellipse_170" data-name="Ellipse 170" cx="0.758" cy="0.766" rx="0.758"
                                        ry="0.766" transform="translate(3.79)" fill="#fbdecf" />
                                    <ellipse id="Ellipse_171" data-name="Ellipse 171" cx="0.758" cy="0.766" rx="0.758"
                                        ry="0.766" transform="translate(0)" fill="#fbdecf" />
                                    <ellipse id="Ellipse_172" data-name="Ellipse 172" cx="0.758" cy="0.766" rx="0.758"
                                        ry="0.766" transform="translate(0 3.063)" fill="#fbdecf" />
                                </g>
                            </g>
                        </svg>
                        <i>{{auctionData?.start_date | dateUTC:'LL '}}</i>
                    </span>
                    <span *ngIf="auctionData?.start_at">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <g id="clock-fill" transform="translate(-94 -366)">
                                <rect id="container" width="20" height="20" transform="translate(94 366)" fill="none" />
                                <path id="clock" d="M10.333,2a8.333,8.333,0,1,0,8.333,8.333A8.333,8.333,0,0,0,10.333,2Z"
                                    transform="translate(93.667 365.667)" fill="#e9580e" />
                                <path id="clock-2" data-name="clock"
                                    d="M14.415,11.528l-1.748-1.009V6.833a.833.833,0,0,0-1.667,0V11a.833.833,0,0,0,.417.722l2.165,1.25a.833.833,0,0,0,.833-1.444Z"
                                    transform="translate(92.167 365.001)" fill="#fbdecf" />
                            </g>
                        </svg>
                        <i>{{auctionData?.start_date | dateUTC:'h:mm A'}}
                            <ng-container *ngIf="auctionData?.end_at">
                                - {{auctionData?.end_date | dateUTC:'h:mm A'}}</ng-container> </i>
                    </span>
                </div>
            </div>



            <div #Details class="item-details">
                <h2 class="head">{{"Auction Item.Item Details"|translate}}</h2>
                <ng-container *ngFor="let item of detailsItem; let even = even">
                    <div class="item" [class.item-bg]="even">
                        <span class="title" appSelectTranslatedText [EnKey]="item.key_en" [ArKey]="item.key_ar">
                        </span>
                        <span class="value" appSelectTranslatedText [EnKey]="item.value_en"
                            [ArKey]="item.value_ar"></span>
                    </div>
                </ng-container>

            </div>

            <div #Media class="item-media" *ngIf="slider?.length">
                <h2 class="head">{{"Auction Item.Media"|translate}}</h2>

                <owl-carousel-o [options]="customOptions">
                    <ng-template *ngFor="let slide of slider;let i = index" carouselSlide>
                        <div class="carousel_item" gallerize dir="ltr">
                            <img (click)=" lightbox.open(i, 'lightbox' , {panelClass: 'fullscreen'})"
                                src="{{slide.path}}" alt="">
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

        </div>
    </div>
</div>
<app-loading *ngIf="loading"></app-loading>