import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MenuService } from './menu.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from 'src/app/core/service/config.service';

@Component({
  selector: 'app-adminpanel',
  templateUrl: './adminpanel.component.html',
})
export class AdminpanelComponent implements OnInit, AfterViewInit {
  collapseMenu = true;
  toggleDrawer: boolean = false;
  public currentUserSubject: BehaviorSubject<any>;

  constructor(
    private menu: MenuService,
    private breakpointObserver: BreakpointObserver,
    private _config: ConfigService
  ) {

    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('auth')!)
    );
    window.addEventListener(
      'storage',
      () => {
        let userStorage: any = localStorage.getItem('auth');
        const userToken = JSON.parse(userStorage);
        if (
          (this.currentUserValue && localStorage.getItem('auth') && this.currentUserValue.token !== userToken.token) ||
          (this.currentUserValue && !localStorage.getItem('auth')) ||
          (!this.currentUserValue && localStorage.getItem('auth'))
        ) {
          this._config._router.navigate(['/'])
            .then(() => {
              window.location.reload();
            });
        }
      },
      false
    );
  }

  ngOnInit(): void {
    this.changeMenu()
    this.menu.getWalletStatus().subscribe((res: boolean) => {

      this.toggleDrawer = res

    })
  }
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.breakpointObserver
        .observe([
          Breakpoints.Handset = '(max-width: 1200px)',

        ])
        .subscribe(result => {
          for (const query of Object.keys(result.breakpoints)) {
            if (result.breakpoints[query]) {
              this.menu.changeMessage(true);
            } else {
              this.menu.changeMessage(false);
            }
          }
        });

    }, 0);


  }

  changeMenu() {
    this.menu.menuShow.subscribe(m => {
      this.collapseMenu = m;

    })
  }


}
