<div class="dialog">

    <div class="suspend">
        <img src="/assets/images/offline.png" alt="" srcset="">

        <h4 class="title mt-4">Offline Network</h4>
        <div class="hint">

            <div class="actions mt-2">

            </div>
        </div>
    </div>