import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/service/api.service';
import { environment } from 'src/environments/environment';
// import { DatePipe } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private OnlineStatus = new BehaviorSubject<boolean>(true);

  constructor(private apiService: ApiService) {
    this.SetOnlineStatus();
  }



  documentDownloader(model: any) {
    return this.apiService
      .getDownload(`${environment.apiUrl}admin/auction/download-files`, model)
      .pipe(catchError(this.handleError));
  }
  downloadFile(model: any) {
    return this.apiService
      .getDownload(
        `${environment.apiUrl}api/system/cab/download_attachments_request`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  SetOnlineStatus() {
    window.addEventListener("online", (event) => {
      this.OnlineStatus.next(true);
    });

    window.addEventListener("offline", (event) => {
      this.OnlineStatus.next(false);
    });
  }

  GetOnlineStatus(): Observable<boolean> {
    return this.OnlineStatus.asObservable();
  }


  handleError(error: any) {
    return throwError(error);
  }
}
