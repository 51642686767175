import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {
  constructor(private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const currentUser = localStorage.getItem('auth');
    let Role = ""
    if (currentUser) {
      Role = JSON.parse(currentUser).user.role_code
    }

    if (Role == "Admin" || Role == "Auctioneer"  || Role == "Master") {
      return true;
    }
    else {
      this.router.navigate(['NoPermission'])
      return false;
    }
  }

}
