import { Component, OnInit, ElementRef, ViewChild, OnDestroy, } from '@angular/core';
import { ConfigService } from 'src/app/core/service/config.service';
import { MenuService } from '../menu.service';
import { SideNavConfigService } from '../../main-layout/side-nav-config.service';
import { Subscription } from 'rxjs';
import { SocketService } from 'src/app/features/bidders/core/service/socket.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild('collapseMenu') collapseMenu: ElementRef | any;
  userName: string | undefined;
  infoUser: any;
  auth: any;
  language = localStorage.getItem('language') || 'en';
  notificationMenu: boolean = false;
  notificationCount: number = 0;
  notificationsList: any[] = []
  NotificationsObject: { per_page: number, page: number } = { per_page: 4, page: 1 }
  pageCount!: number;
  loadingNoti: boolean = false;
  subscribtions: Subscription[] = [];
  constructor(
    public menu: MenuService,
    private _config: ConfigService,
    private _sideNavService: SideNavConfigService,
    private _SocketService: SocketService
  ) {
    this.auth = localStorage.getItem("auth");
    this.infoUser = JSON.parse(this.auth);
    console.log("object", this.infoUser);
    this.userName = this.infoUser?.user.firstname + " " + (this.infoUser?.user.lastname ? this.infoUser?.user.lastname : '');


  }

  ngOnInit(): void {
    this.auth = localStorage.getItem("auth");
    this.infoUser = JSON.parse(this.auth);

    this.userName = this.infoUser?.user.firstname + " " + (this.infoUser?.user.lastname ? this.infoUser?.user.lastname : '');
    console.log(this.infoUser.image);
    this.getNotifications(this.NotificationsObject)
  }
  collapse_menu() {
    this.menu.status = this.menu.status ? false : true;
    this.menu.changeMessage(this.menu.status);
  }

  signOut() {
    localStorage.removeItem("auth");
    localStorage.removeItem("menu");
    this._config._router.navigate(['/'])
    // .then(() => {
    //   window.location.reload();
    // });
    this.infoUser = null;
    this._SocketService.onCloseSocket();


  }

  changeLanguage() {
    if (this.language === 'en') {
      localStorage.setItem('language', 'ar');
    } else {
      localStorage.setItem('language', 'en');
    }
    let modal = { language: localStorage.getItem('language') }
    this.menu.ChangeLanguage(modal).subscribe({
      next: (response) => {
        window.location.reload();
      },
      error: (err) => {
        this._config.showErrors(err);
      }
    });
  }
  getNextPage() {
    if (this.NotificationsObject.page <= this.pageCount - 1) {
      this.NotificationsObject.page += 1;
      this.getNotifications(this.NotificationsObject);
    }
  }

  getNotifications(modal: any) {
    this.loadingNoti = true;
    this.subscribtions.push(
      this.menu.getNotificationsList(modal).subscribe(
        {
          next: data => {
            this.notificationsList = [...this.notificationsList, ...data.data.list];
            this.notificationCount = data.data.total
            this.pageCount = Math.ceil(
              data.data.total / this.NotificationsObject.per_page
            );
            this.loadingNoti = false;
          },
          error: (err: any) => {
            this._config.showErrors(err)
            this.loadingNoti = false;
          }
        }))
  }



  navigate(notification: any) {
    if (notification.message_link) {
      const url = this._config._router.serializeUrl(
        this._config._router.createUrlTree([
          notification.message_link,
          notification.reference_token,
        ])
      );
      window.open(url, '_blank');
    }

    // this.router.navigate(['/admin/' + task.task_link, task.reference_token]);
  }

  MarkAllNotificationAsSeen() {
    this.menu.MarkAllNotificationAsSeen().subscribe({
      next: (res) => {
        this.notificationCount = 0;
      },
      error: (error) => {
        this._config.showErrors(error);
      }
    })
  }

  OnViewMyProfile() {
    switch (this.infoUser?.user?.role_code) {
      case "General":
        this._config.goTo('/admin/profile')
        break;

      default:
        this._sideNavService.ChangeSideNav({
          status: true,
          type: "staffProfile",
          id: this.infoUser?.user?.id,
          disableClose: false,
          width: "39.375rem"
        })

        break;
    }
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscribtions.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }
}
