import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styles: [
  ]
})
export class OfflineComponent implements OnInit {

  constructor(public dialogRef: DialogRef, private _SharedService: SharedService) { }

  ngOnInit(): void {
    this._SharedService.GetOnlineStatus().subscribe(status => {
      if (status) {
        this.dialogRef.close(true);
      }
    })
  }
}
