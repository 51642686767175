import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appOnlynumber]'
})
export class OnlynumberDirective {

  @Input() canApply: any = true;
  @Input() canApplyDecimal: any = false;
  @Input() disabledNumberOnly: boolean = false;

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  inputElement: HTMLElement | undefined;
  constructor(public el: ElementRef, private control: NgControl) {
    if (!this.canApply) { return; }


    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  @HostListener('keyup', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    const currentValue: string = this.el.nativeElement.value || '';
    if (!this.canApply) { return; }
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      if (!this.canApplyDecimal || (this.canApplyDecimal && e.keyCode !== 110)) {
        e.preventDefault();
        if (
          this.control &&
          (
            (e.keyCode === 66 && e.key === 'Unidentified' && e.type === 'keyup') ||
            (e.keyCode === 84 && (currentValue.indexOf('إ') > -1)) ||
            (e.keyCode === 71 && (currentValue.indexOf('أ') > -1))
          )
        ) {
          this.control.control?.setValue(currentValue.slice(0, -1));
        }
      }
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (!this.canApply) { return; }
    event.preventDefault();
    const pastedInput: any = event.clipboardData
      ?.getData('text/plain')
      ?.replace(/\D/g, ''); // get a digit-only string
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    if (!this.canApply) { return; }
    event.preventDefault();
    const textData = event.dataTransfer?.getData('text').replace(/\D/g, '');
    this.inputElement?.focus();
    document.execCommand('insertText', false, textData);
  }
}
