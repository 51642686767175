<div class="d-flex flex-column flex-shrink-0 sidebar">
  <div class="close-menu" (click)="closeMenu()">
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
      <g id="Minimize_Sidebar" data-name="Minimize Sidebar" transform="translate(-35 -18)">
        <rect id="bg" width="44" height="44" rx="22" transform="translate(35 18)" fill="#70639e" opacity="0" />
        <g id="minimize-sidebar-icon" transform="translate(-103.298 -223.531)">
          <path id="Path_13106" data-name="Path 13106"
            d="M160.295,256.531a.911.911,0,1,0,0,1.822h10.072a.911.911,0,1,0,0-1.822Z" fill="#70639e" />
          <path id="Path_13107" data-name="Path 13107"
            d="M160.292,271.531h10.076a.907.907,0,1,0,0-1.813H160.295a.907.907,0,1,0,0,1.813Z" fill="#70639e" />
          <path id="Path_13108" data-name="Path 13108"
            d="M151.315,264.264c.057.1.078.148.1.194l.058.14,3.66,3.9a.928.928,0,0,0,.768.4h.008a.914.914,0,0,0,.506-.151l.045-.03a.91.91,0,0,0,.214-1.228l-2.736-2.546h16.426a.911.911,0,1,0,0-1.822H153.951l2.734-2.546a.9.9,0,0,0-.257-1.259.946.946,0,0,0-1.284.252l-3.7,3.955c-.055.135-.076.185-.091.236l-.048.118.007.079-.018.085Z"
            fill="#70639e" />
        </g>
      </g>
    </svg>
  </div>
  <div class="logo">
    <a href="/" class="text-decoration-none">
      <img src="assets/images/logo.svg" alt="" srcset="">
    </a>
  </div>
  <div class="d-flex flex-column justify-content-between"></div>
  <div class="sidebar-content">
    <ul class="nav p-0 flex-column mb-auto">
      <ng-container *ngFor="let menu of menus">
        <li *ngIf="checkHaveIsMenu(menu.childParent)" >
          <a [class.active-parnt]="_config.menuActive(menu.token)" class="nav-link link-dark"  data-bs-toggle="collapse" href="#collapseExample{{menu.token}}" role="button" aria-expanded="false" aria-controls="collapseExample">
            <div class="parent" >

                <div [innerHtml]="sanitizerSVG(menu.icon_menu)" class="icon-menu"></div>
                <span>{{menu.name}}</span>
                <svg class="arrow-down" xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7">
                  <path id="menu-icon" d="M-3.21-9.316,1-4.975,5.21-9.315,6.5-7.986,1-2.316l-5.5-5.67Z" transform="translate(4.5 9.316)" fill="#fff"/>
                </svg>
                <svg class="arrow-up"  xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7">
                  <path id="menu-icon" d="M-3.21-2.316,1-6.657,5.21-2.317,6.5-3.646,1-9.316l-5.5,5.67Z" transform="translate(4.5 9.316)" fill="#fff"/>
                </svg>
            </div>

            <div class="sub-menu collapse" id="collapseExample{{menu.token}}">
              <div class="parent-name">{{menu.name}}</div>
              <ul>
                <ng-container  *ngFor="let child of menu.childParent">
                  <li *ngIf="child.is_menu"><a routerLinkActive="active-link-sub" routerLink="/{{child.link}}">{{child.name}}</a></li>
                </ng-container>

              </ul>
            </div>
          </a>
        </li>

        <li *ngIf="!checkHaveIsMenu(menu.childParent)">
          <a routerLink="/{{menu.link}}" class="nav-link link-dark">
            <div class="parent">
              <div [innerHtml]="sanitizerSVG(menu.icon_menu)" class="icon-menu"></div>

              <span>{{menu.name}}</span>

            </div>
            <div class="sub-menu">
              <div class="parent-name">{{menu.name}}</div>
            </div>
          </a>
        </li>
      </ng-container>


    </ul>
    <!-- <ul class="nav  flex-column mb-auto">
        <li>
          <a href="#" class="nav-link link-dark">
            <div class="parent">
              <svg class="icon-menu" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
                <path id="Path_13091" data-name="Path 13091" d="M22.8,17H2.2A2.232,2.232,0,0,0,0,19.259V33.921A2.232,2.232,0,0,0,2.2,36.18H9.292l-.537,3.314H7.812a.753.753,0,0,0,0,1.506h9.375a.753.753,0,0,0,0-1.506h-.942l-.537-3.314H22.8A2.232,2.232,0,0,0,25,33.921V19.259A2.232,2.232,0,0,0,22.8,17ZM14.76,39.494H10.24l.537-3.314h3.447Zm8.775-5.573a.744.744,0,0,1-.732.753H2.2a.744.744,0,0,1-.732-.753V19.259a.744.744,0,0,1,.732-.753h9.57v.854a.733.733,0,1,0,1.465,0v-.854H22.8a.744.744,0,0,1,.732.753Z" transform="translate(0 -17)" fill="#fff"/>
              </svg>

              <span>Dashborad</span>

            </div>
            <div class="sub-menu" >
              <div class="parent-name">Dashborad</div>
            </div>
          </a>
        </li>
        <li>
          <a href="#" class="nav-link link-dark"  data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
            <div class="parent">
                <svg class="icon-menu" xmlns="http://www.w3.org/2000/svg" width="25" height="23" viewBox="0 0 25 23">
                  <g id="users-icon" transform="translate(-1604 -235)" opacity="0.9">
                    <path id="Path_13087" data-name="Path 13087" d="M21.338,231.583H18.891a3.608,3.608,0,0,0-.962.131,3.661,3.661,0,0,0-3.265-2.034H10.337a3.661,3.661,0,0,0-3.265,2.034,3.608,3.608,0,0,0-.962-.131H3.662A3.69,3.69,0,0,0,0,235.293v5.963a2.214,2.214,0,0,0,2.2,2.226H22.8a2.214,2.214,0,0,0,2.2-2.226v-5.963A3.69,3.69,0,0,0,21.338,231.583ZM6.675,233.39V242H2.2a.738.738,0,0,1-.732-.742v-5.963a2.214,2.214,0,0,1,2.2-2.226H6.109a2.163,2.163,0,0,1,.575.078C6.679,233.226,6.675,233.308,6.675,233.39ZM16.86,242H8.14V233.39a2.214,2.214,0,0,1,2.2-2.226h4.326a2.214,2.214,0,0,1,2.2,2.226Zm6.675-.742A.738.738,0,0,1,22.8,242H18.325V233.39c0-.082,0-.164-.009-.245a2.166,2.166,0,0,1,.575-.078h2.447a2.214,2.214,0,0,1,2.2,2.226Z" transform="translate(1604 14.518)" fill="#fff"/>
                    <path id="Path_13088" data-name="Path 13088" d="M36.668,126.5a3.3,3.3,0,1,0,3.254,3.3A3.279,3.279,0,0,0,36.668,126.5Zm0,5.109a1.813,1.813,0,1,1,1.789-1.813A1.8,1.8,0,0,1,36.668,131.613Z" transform="translate(1572.218 112.59)" fill="#fff" opacity="0.6"/>
                    <path id="Path_13089" data-name="Path 13089" d="M171.31,43.729a4.4,4.4,0,1,0,4.348,4.4A4.381,4.381,0,0,0,171.31,43.729Zm0,7.325a2.921,2.921,0,1,1,2.883-2.92A2.9,2.9,0,0,1,171.31,51.054Z" transform="translate(1445.19 191.271)" fill="#fff"/>
                    <path id="Path_13090" data-name="Path 13090" d="M348.548,126.5a3.3,3.3,0,1,0,3.254,3.3A3.279,3.279,0,0,0,348.548,126.5Zm0,5.109a1.813,1.813,0,1,1,1.789-1.813A1.8,1.8,0,0,1,348.548,131.613Z" transform="translate(1275.566 112.59)" fill="#fff" opacity="0.6"/>
                  </g>
                </svg>
                <span>Users Management</span>
                <svg class="arrow-down" xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7">
                  <path id="menu-icon" d="M-3.21-9.316,1-4.975,5.21-9.315,6.5-7.986,1-2.316l-5.5-5.67Z" transform="translate(4.5 9.316)" fill="#fff"/>
                </svg>
                <svg class="arrow-up"  xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7">
                  <path id="menu-icon" d="M-3.21-2.316,1-6.657,5.21-2.317,6.5-3.646,1-9.316l-5.5,5.67Z" transform="translate(4.5 9.316)" fill="#fff"/>
                </svg>
            </div>

            <div class="sub-menu collapse" id="collapseExample">
              <div class="parent-name">Users Management</div>
              <ul>
                <li><a>Hospitals</a></li>
                <li><a class="active">Clinics</a></li>
                <li><a>Labs</a></li>
                <li><a>Scanning Centers</a></li>
                <li><a>Virtual Lab</a></li>
              </ul>
            </div>
          </a>
        </li>
        <li>
          <a href="#" class="nav-link link-dark"  data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1">
            <div class="parent">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <g id="pathologists-icon" transform="translate(20473.316 10491.271)">
                  <rect id="container" width="25" height="25" transform="translate(-20473.316 -10491.271)" fill="rgba(61,26,74,0)"/>
                  <path id="Path_19517" data-name="Path 19517" d="M158.915,192.111a.732.732,0,1,0-1.268-.732.732.732,0,0,1-1.267,0,.732.732,0,0,0-1.268.733A2.2,2.2,0,0,0,158.915,192.111Z" transform="translate(-20620.762 -10672.956)" fill="#fff" opacity="0.3"/>
                  <path id="Path_19518" data-name="Path 19518" d="M21.858,13.823l.64-1.115a.732.732,0,0,0-.269-1l-.634-.366.366-.634a.732.732,0,1,0-1.268-.732l-.366.634-.634-.366a.732.732,0,0,0-1,.269l-2.678,4.662-2.782-.384V13.119a5.9,5.9,0,0,0,2.105-3.548h.824a2.2,2.2,0,0,0,0-4.394h-.732V1.685A.732.732,0,0,0,14.959,1,15,15,0,0,0,4.268.968a.76.76,0,0,0-.557.717c0,.008,0,3.483,0,3.491H2.978a2.2,2.2,0,0,0,0,4.394H3.79A5.823,5.823,0,0,0,5.908,13.16v1.627l-3.092.407A3.17,3.17,0,0,0,0,18.348v5.92A.732.732,0,0,0,.732,25H24.268A.732.732,0,0,0,25,24.267V17.76a4.046,4.046,0,0,0-3.142-3.938Zm-4.693,2.288,1.269.732L17.712,18.1l-1.268-.732ZM19.6,11.88l1.268.732-1.7,2.961-1.269-.732ZM16.16,6.641a.732.732,0,1,1,0,1.465h-.732V6.641h.732ZM13.963,2.2V4.444H5.175V2.2a13.532,13.532,0,0,1,8.788,0ZM2.246,7.374a.733.733,0,0,1,.732-.732h.732V8.106H2.978A.733.733,0,0,1,2.246,7.374ZM5.175,8.609v-2.7h8.787c0,.008,0,2.613,0,2.621A4.439,4.439,0,0,1,9.53,12.964,4.36,4.36,0,0,1,5.175,8.609Zm6.591,5.378V15.43a.734.734,0,0,0,.005.084,3.387,3.387,0,0,1-4.4-.01c.008-.075,0,0,0-1.491a5.854,5.854,0,0,0,4.394-.026Zm-10.3,4.36A1.707,1.707,0,0,1,3,16.647l3.031-.4a4.855,4.855,0,0,0,7.085.005l2.112.291-.417.726a.732.732,0,0,0,.269,1l2.537,1.465a.732.732,0,0,0,1-.269l.776-1.351h1.166v2.441H14.941a1.711,1.711,0,0,0-1.709,1.709v1.269H1.465Zm22.071,5.187H14.7V22.266a.244.244,0,0,1,.244-.244h6.349a.732.732,0,0,0,.732-.732V17.871A1.222,1.222,0,0,0,20.8,16.651h-.569l.84-1.462a2.577,2.577,0,0,1,2.462,2.572v5.775Z" transform="translate(-20473.316 -10491.271)" fill="#fff"/>
                </g>
              </svg>

                <span>Pathologists</span>
                <svg class="arrow-down" xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7">
                  <path id="menu-icon" d="M-3.21-9.316,1-4.975,5.21-9.315,6.5-7.986,1-2.316l-5.5-5.67Z" transform="translate(4.5 9.316)" fill="#fff"/>
                </svg>
                <svg class="arrow-up"  xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7">
                  <path id="menu-icon" d="M-3.21-2.316,1-6.657,5.21-2.317,6.5-3.646,1-9.316l-5.5,5.67Z" transform="translate(4.5 9.316)" fill="#fff"/>
                </svg>
            </div>

            <div class="sub-menu collapse" id="collapseExample1">
              <div class="parent-name">Pathologists</div>
              <ul>
                <li>Hospitals</li>
                <li>Clinics</li>
                <li>Labs</li>
                <li>Scanning Centers</li>
                <li>Virtual Lab</li>
              </ul>
            </div>
          </a>
        </li>

        <li>
          <a href="#" class="nav-link link-dark">
            <div class="parent">
              <svg xmlns="http://www.w3.org/2000/svg" width="25.002" height="24.998" viewBox="0 0 25.002 24.998">
                <g id="hospitals-icon" transform="translate(-21.252 -649.253)" opacity="0.9">
                  <path id="Subtraction_45" data-name="Subtraction 45" d="M24396.752-554.5h-10.25v-16.781a2.739,2.739,0,0,1,2.732-2.739h1.053v-2.745a2.736,2.736,0,0,1,2.732-2.732h11.961a2.736,2.736,0,0,1,2.732,2.732v2.745h1.053a2.742,2.742,0,0,1,2.738,2.739v16.78h-10.256v-4.086h-4.5v4.086Zm10.961-18.038h0v16.554h2.309v-15.3a1.257,1.257,0,0,0-1.256-1.256h-1.053Zm-12.443,12.468h7.461v4.086h3.5v-20.781a1.25,1.25,0,0,0-1.248-1.25h-11.961a1.25,1.25,0,0,0-1.248,1.25v20.781h3.5v-4.085Zm-6.035-12.468a1.254,1.254,0,0,0-1.25,1.256v15.3h2.3v-16.554Z" transform="translate(-24365.25 1228.75)" fill="#fff"/>
                  <g id="Group_10431" data-name="Group 10431" transform="translate(0.928 2)" opacity="0.8">
                    <rect id="Rectangle_4735" data-name="Rectangle 4735" width="1.5" height="7" rx="0.5" transform="translate(30 651)" fill="#fff"/>
                    <rect id="Rectangle_4736" data-name="Rectangle 4736" width="1.5" height="7" rx="0.5" transform="translate(34 651)" fill="#fff"/>
                    <rect id="Rectangle_4737" data-name="Rectangle 4737" width="1.5" height="5.5" transform="translate(30 655.5) rotate(-90)" fill="#fff"/>
                  </g>
                  <g id="Group_10432" data-name="Group 10432" transform="translate(-27.277 7.118)">
                    <path id="Path_19445" data-name="Path 19445" d="M150,120h1.471v1.471H150Z" transform="translate(-92.646 534.882)" fill="#fff" opacity="0.9"/>
                    <path id="Path_19446" data-name="Path 19446" d="M210,120h1.471v1.471H210Z" transform="translate(-149.705 534.882)" fill="#fff" opacity="0.5"/>
                    <path id="Path_19447" data-name="Path 19447" d="M270,120h1.471v1.471H270Z" transform="translate(-206.766 534.882)" fill="#fff" opacity="0.9"/>
                    <path id="Path_19448" data-name="Path 19448" d="M150,180h1.471v1.471H150Z" transform="translate(-92.646 477.823)" fill="#fff" opacity="0.6"/>
                    <path id="Path_19449" data-name="Path 19449" d="M210,180h1.471v1.471H210Z" transform="translate(-149.705 477.823)" fill="#fff" opacity="0.8"/>
                    <path id="Path_19450" data-name="Path 19450" d="M270,180h1.471v1.471H270Z" transform="translate(-206.766 477.823)" fill="#fff" opacity="0.6"/>
                  </g>
                </g>
              </svg>


              <span>Hospitals</span>
            </div>
            <div class="sub-menu" >
              <div class="parent-name">Hospitals</div>
            </div>
          </a>
        </li>

        <li>
          <a href="#" class="nav-link link-dark">
            <div class="parent">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24.999" viewBox="0 0 25 24.999">
                <g id="clinics-icon" transform="translate(-20.998 -621.999)" opacity="0.9">
                  <path id="Path_19451" data-name="Path 19451" d="M14.563,31.779a.781.781,0,0,0-1.562,0v4.9a.781.781,0,0,0,1.563,0Z" transform="translate(13.076 604.699)" fill="#fff" opacity="0.3"/>
                  <path id="Path_19452" data-name="Path 19452" d="M24.219,23.277H23.047V9.251a.78.78,0,0,0-.781-.779H20.7a.779.779,0,1,0,0,1.558h.781V23.277H16.8V16.264a.78.78,0,0,0-.781-.779H8.984a.78.78,0,0,0-.781.779v7.013H3.516V10.03h2.7A7.04,7.04,0,1,0,5.65,8.471H2.734a.78.78,0,0,0-.781.779V23.277H.781a.779.779,0,1,0,0,1.558H24.219a.779.779,0,1,0,0-1.558ZM12.5,1.458A5.455,5.455,0,1,1,7.031,6.913,5.462,5.462,0,0,1,12.5,1.458ZM9.766,23.277V17.043h5.469v6.234Z" transform="translate(20.998 622.163)" fill="#fff"/>
                  <ellipse id="Ellipse_312" data-name="Ellipse 312" cx="0.781" cy="0.779" rx="0.781" ry="0.779" transform="translate(39.076 642.154)" fill="#fff" opacity="0.5"/>
                  <path id="Path_19454" data-name="Path 19454" d="M48,8.444a4.222,4.222,0,0,1-2.985-7.207A4.222,4.222,0,0,1,50.466.8.742.742,0,0,1,49.6,2a2.738,2.738,0,1,0,0,4.441.742.742,0,1,1,.869,1.2,4.192,4.192,0,0,1-2.471.8Z" transform="translate(-14.473 624.9)" fill="#fff" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.8"/>
                </g>
              </svg>



              <span>Clinics</span>
            </div>
            <div class="sub-menu" >
              <div class="parent-name">Clinics</div>
            </div>
          </a>
        </li>
      </ul> -->
  </div>
  <div>
    <p class="copy-right">© {{year}} Inndeve, All Rights Reserved</p>
  </div>

</div>
