import { Observable, timer } from 'rxjs';
import { EventEmitter, Output, Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/service/config.service';
import { TimerService } from '../services/Timer.service';

@Pipe({
  name: 'TimeRemaining'
})
export class TimeRemainingPipe implements PipeTransform {
  private lastRemainingSeconds = new EventEmitter<number>();

  constructor(private _config: ConfigService, private _timer: TimerService) { }

  public transform(futureDate: any, type?: string): Observable<any> {
    if (!futureDate) {
      return null as any;
    }
    const source = timer(0, 1000);
    return source.pipe(map((_) => this.msToTime(type === 'seconds' ? futureDate = futureDate - 1 : futureDate, type)));
  }

  private msToTime(futureDate: any, type: any): string | null {
    let expired = false;
    let msRemaining = 0;
    if (type && type === 'seconds') {
      msRemaining = Math.abs(futureDate) * 1000;
      if (futureDate < 0) {
        expired = true;
      }
    } else {
      const now = new Date().getTime();
      const endDate = new Date(futureDate).getTime();
      msRemaining = endDate - now;
      if (msRemaining < 0) {
        msRemaining = now - endDate;
        expired = true;
      }
    }
    const days = Math.floor(msRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((msRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((msRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((msRemaining % (1000 * 60)) / (1000));

    if (days == 0 && hours == 0 && minutes == 0 && seconds <= 60 && this._config._router.url.includes('/auction/') && !expired) {
      this.lastRemainingSeconds.emit(seconds)
    }

    if (expired) {
      return `<div class="text-center start-in"> <strong>${this._config.translate.instant("timer.The auction has ended")}</strong></div>`
    }
    else {
      return `
    <div class="counter">
              <div class="count-value">${days !== 0 ? days : '-'}  </div>
              <div class="count-title">${this._config.translate.instant("timer.Days")}</div>
            </div>
            <span>:</span>
            <div class="counter">
              <div class="count-value">${hours !== 0 ? hours : '-'} </div>
              <div class="count-title">${this._config.translate.instant("timer.Hours")}</div>
            </div>
            <span>:</span>
            <div class="counter">
              <div class="count-value">${minutes !== 0 ? minutes : '-'} </div>
              <div class="count-title">${this._config.translate.instant("timer.Minutes")}</div>
            </div>
            <span>:</span>
            <div class="counter">
              <div class="count-value ${(days == 0 && hours == 0 && minutes == 0 && seconds < 10) ? 'text-danger' : ''}">${seconds !== 0 ? seconds : '-'}</div>
              <div class="count-title">${this._config.translate.instant("timer.Seconds")}</div>
            </div>
`;
    }

  }

  get output(): EventEmitter<number> {
    return this.lastRemainingSeconds;
  }

}
