

<!-- FULL CIRCLE LOADER  -->
<div class="spinner">
    <div class="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>