import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ConfigService } from 'src/app/core/service/config.service';
import { MenuService } from '../../adminpanel/menu.service';
import { Location } from '@angular/common';
import { SocketService } from 'src/app/features/bidders/core/service/socket.service';

@Component({
  selector: 'app-bidderNavbar',
  templateUrl: './bidderNavbar.component.html',
  styleUrls: []
})
export class BidderNavbarComponent implements OnInit {

  backgroundColor: string = "bg-transparent"
  logoPath: string = "./assets/images/logo_white.svg"
  isHome: boolean = false
  auth: any;
  infoUser: any;
  userName: string = "";
  language = localStorage.getItem('language') || 'en';
  collapse: boolean = false
  // Notification
  notificationsList: any[] = []
  notificationCount: number = 0;
  NotificationsObject: { per_page: number, page: number } = { per_page: 4, page: 1 }
  pageCount!: number;
  loadingNoti: boolean = false;
  isMobile = false;
  isTablet = false;
  isScrolled = false;
  constructor(
    private _config: ConfigService,
    private _menuService: MenuService,
    private el: ElementRef,
    private renderer: Renderer2,
    private _SocketService: SocketService) { }

  ngOnInit() {


    this.auth = localStorage.getItem("auth");
    this.infoUser = JSON.parse(this.auth);
    if (this.infoUser) {
      this.userName = this.infoUser.user?.firstname + " " + (this.infoUser.user?.lastname ? this.infoUser.user?.lastname : '');
      this.getNotifications(this.NotificationsObject)
    }



    if (this._config._router.url == "/" || this._config._router.url == "/pages/terms" || this._config._router.url == "/pages/about-us") {
      this.backgroundColor = "bg-transparent fixed-top"
      this.logoPath = "./assets/images/logo_white.svg"
      this.isHome = true
    }
    else {
      this.backgroundColor = "bg-light position-elative"
      this.logoPath = "./assets/images/logo.svg"
      this.isHome = false
    }

    this._config._router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        // this.currentRoute = event.url;
        if (event.url == "/" || this._config._router.url == "/pages/terms" || this._config._router.url == "/pages/about-us") {
          this.backgroundColor = "bg-transparent fixed-top"
          this.logoPath = "./assets/images/logo_white.svg"
          this.isHome = true
        }
        else {
          this.backgroundColor = "bg-light"
          this.logoPath = "./assets/images/logo.svg"
          this.isHome = false
        }
        console.log(event);
      }
    });
    this.checkWidth()

  }

  signOut() {
    localStorage.removeItem("auth");
    localStorage.removeItem("menu");
    this.infoUser = null;
    // this._config._router.navigate(["/"]);
    this._config._router.navigate(['/'])
    // .then(() => {
    //   window.location.reload();
    // });
    this._SocketService.onCloseSocket();

  }


  changeLanguage() {
    if (this.language === 'en') {
      localStorage.setItem('language', 'ar');
    } else {
      localStorage.setItem('language', 'en');
    }
    // window.location.reload()
    let modal = { language: localStorage.getItem('language') };
    if (localStorage.getItem("auth")) {
      this._menuService.ChangeLanguage(modal).subscribe({
        next: (response) => {
          window.location.reload();
        },
        error: (err) => {
          this._config.showErrors(err);
        }
      });
    } else {
      window.location.reload();
    }
  }

  changeBackground() {
    if (this._config._router.url == "/" && this.collapse == true) {
      this.backgroundColor = "bg-light"
      this.logoPath = "./assets/images/logo.svg"
      this.isHome = false
    }
    else if (this._config._router.url == "/" && this.collapse == false) {
      this.backgroundColor = "bg-transparent"
      this.logoPath = "./assets/images/logo_white.svg"
      this.isHome = true
    }
  }


  getNextPage() {
    if (this.NotificationsObject.page <= this.pageCount - 1) {
      this.NotificationsObject.page += 1;
      this.getNotifications(this.NotificationsObject);
    }
  }

  getNotifications(modal: any) {
    this.loadingNoti = true;
    this._menuService.getNotificationsList(modal).subscribe(
      {
        next: data => {
          this.notificationsList = [...this.notificationsList, ...data.data.list];
          this.notificationCount = data.data.total
          this.pageCount = Math.ceil(
            data.data.total / this.NotificationsObject.per_page
          );
          this.loadingNoti = false;
        },
        error: (err: any) => {
          this._config.showErrors(err)
          this.loadingNoti = false;
        }
      })
  }



  navigate(notification: any) {
    if (notification.message_link) {
      const url = this._config._router.serializeUrl(
        this._config._router.createUrlTree([
          '/' + notification.message_link,
          notification.reference_token,
        ])
      );

      window.open(url, '_blank');
    }

    // this.router.navigate(['/admin/' + task.task_link, task.reference_token]);
  }



  NavigateToProfile() {

    let x = this._config.getAuthUserInfo()
    if (x.user.role_code === 'General') {
      this._config.goTo('/myProfile');
    } else {
      this._config.goTo('/admin/profile');


    }
  }
  MarkAllNotificationAsSeen() {
    this._menuService.MarkAllNotificationAsSeen().subscribe({
      next: (res) => {
        this.notificationCount = 0;
      },
      error: (error) => {
        this._config.showErrors(error);
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  @HostListener('window:scroll', ['$event'])

  onWindowScroll(event: Event) {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const myDiv = this.el.nativeElement.querySelector('#nav');
    this.isScrolled = scrollPosition >= 10;
    if (this.isScrolled) {
      this.renderer.addClass(myDiv, 'nav-scrolled');
      this.logoPath = "./assets/images/logo.svg"
    } else {
      this.renderer.removeClass(myDiv, 'nav-scrolled');
      if (!this.isHome) {
        this.logoPath = "./assets/images/logo.svg"
      } else {
        this.logoPath = "./assets/images/logo_white.svg"
      }
      this.checkWidth();
    }
  }

  onResize(event: any) {
    this.checkWidth();
  }

  checkWidth() {
    const width = window.innerWidth;
    this.isMobile = width < 991.98;
    this.isTablet = width >= 991.98 && width < 1024;
    if (this.isMobile) {
      this.logoPath = "./assets/images/logo.svg"
    } else {
      if (!this.isHome) {
        this.logoPath = "./assets/images/logo.svg"
      } else {
        this.logoPath = "./assets/images/logo_white.svg"
      }
    }
  }


  NavigateToDashboard() {
    if (this.infoUser?.user?.role_code == "Admin") {
      this._config.goTo('/admin')
    } else {
      this._config.goTo('/rooms')

    }
  }
}
