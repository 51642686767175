import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AuthComponent } from './features/auth/auth.component';
import { AdminpanelComponent } from './layout/adminpanel/adminpanel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagNotFoundComponent } from './shared/Components/pag-not-found/pag-not-found.component';
import { NavbarComponent } from './layout/adminpanel/navbar/navbar.component';
import { SidebarComponent } from './layout/adminpanel/sidebar/sidebar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './core/service/jwt.interceptor';
import { ErrorInterceptor } from './core/service/error.interceptor';
import { AuthHeaderComponent } from './layout/auth/auth-header/auth-header.component';
import { AuthFooterComponent } from './layout/auth/auth-footer/auth-footer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from './shared/shared.module';
import { BidersComponent } from './layout/biders/biders.component';
import { BidderNavbarComponent } from './layout/biders/bidderNavbar/bidderNavbar.component';
import { BiderFooterComponent } from './layout/biders/biderFooter/biderFooter.component';
import { NoPermissionComponent } from './shared/Components/no-permission/no-permission.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ItemsRequestsModule } from './features/items-requests/items-requests.module';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    AdminpanelComponent,
    PagNotFoundComponent,
    NavbarComponent,
    SidebarComponent,
    AuthHeaderComponent,
    AuthFooterComponent,
    BidersComponent,
    BidderNavbarComponent,
    BiderFooterComponent,
    NoPermissionComponent,
    MainLayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    MatPaginatorModule,
    SharedModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    InfiniteScrollModule
  ],
  exports: [TranslateModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: localStorage.getItem('language') || 'en' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
