import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { ApiService } from 'src/app/core/service/api.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  public menuShow = new BehaviorSubject(false);
  public OpenWallet = new BehaviorSubject(false);
  status = false;
  constructor(private apiService: ApiService) { }

  changeMessage(status: boolean) {
    this.status = status;
    this.menuShow.next(status)
  }


  ToggleWallet(status: boolean) {
    this.status = status;
    this.OpenWallet.next(status)
  }

  getWalletStatus() {
    return this.OpenWallet.asObservable()
  }

  getNotificationsList(modal: any) {
    return this.apiService
      .get(`${environment.apiUrl}userManagement/notifications`, modal)
      .pipe(catchError(this.handleError));
  }

  ChangeLanguage(modal: any) {
    return this.apiService
      .post(`${environment.apiUrl}userManagement/update-my-language`, modal)
      .pipe(catchError(this.handleError));
  }


  MarkAllNotificationAsSeen() {
    return this.apiService
      .get(`${environment.apiUrl}userManagement/see-all-notifications`)
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }

}
