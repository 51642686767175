import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from 'src/app/core/service/config.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
})
export class AuthComponent implements OnInit {
  public currentUserSubject: BehaviorSubject<any>;
  constructor(private _config: ConfigService) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('auth')!)
    );
    window.addEventListener(
      'storage',
      () => {
        let userStorage: any = localStorage.getItem('auth');
        const userToken = JSON.parse(userStorage);
        if (
          (this.currentUserValue &&
            localStorage.getItem('auth') &&
            this.currentUserValue.token !== userToken.token) ||
          (this.currentUserValue && !localStorage.getItem('auth')) ||
          (!this.currentUserValue && localStorage.getItem('auth'))
        ) {

          this._config._router.navigate(['/'])
            .then(() => {
              window.location.reload();
            });
        }
      },
      false
    );
  }

  ngOnInit(): void { }
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }
}
