import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/core/service/config.service';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
})
export class FileManagerComponent implements OnInit {
  @Output() selectedFiles = new EventEmitter<[]>();
  @Output() deletedFilesList = new EventEmitter<[]>();
  @Output() UploadLoading = new EventEmitter<boolean>(false);

  @Input() isRequired: any;
  @Input() Disapled: boolean = false;
  @Input() isSubmited: any;
  @Input() cols = 1;
  @Input() label: any;

  @Input() allowedExt: any[] = [
    'pdf',
    'jpeg',
    'jpg',
    'png',
    'doc',
    'docx',
    'wpd',
    'tex',
    'ppt',
    'pptx',
    'xlsx',
    'xls',
    'zip',
    'rar',
  ];

  @Input() renderBtn = false;

  // Uploader
  @Input() isUploader = false;
  @Input() uplodaerType = 0;
  uploadedFilesArr: any = [];

  // DnD setting
  @Input() files: any = [];
  @Input() docsEditable = true;
  @Input() download = false;
  @Input() maxFiles!: number;
  @Input() MaxFileSize = 250;
  @Input() managerIndex: any = '0'; // in add numbertab_numberneeded, in details numbertab_d_numberneeded in "''"
  @Input() MinFileWidth = 500;
  @Input() InitialImage: any;
  @Input() Mode: "small" | "banner" | "all" | "multi" = "banner";

  loading = false;
  selectedfilesCount = 0;
  deletedFiles: any = [];
  validFile = true;
  fileMaxSiZeErorr = false;
  fileMaxNameSiZeErorr = false;
  CurrentImageSelectedBase64: any
  constructor(
    private translate: TranslateService,
    private _config: ConfigService
  ) {
  }

  ngOnInit() {
    //console.log(this.InitialImage);
    if (this.files.length && this.isUploader) {
      // this.files.forEach((file: { name: any; token: any; }) => {
      //   
      //   this.uploadedFilesArr.push({
      //     fileName: file.name,
      //     fileUpload: file.token
      //   });
      // });
      this.uploadedFilesArr = this.files
      this.selectedFiles.emit(this.uploadedFilesArr);
    }
  }

  ngOnChanges(changes: any): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    //console.log(changes);

    if (changes?.InitialImage?.currentValue?.length) {
      this.CurrentImageSelectedBase64 = changes?.InitialImage?.currentValue;
      this.selectedfilesCount = 1
    }
  }

  fileType(fileName: string) {
    // console.log(fileName);
    const extension = fileName.split('.')?.pop()?.toLowerCase();
    if (!this.allowedExt.includes(extension)) {
      return false;
    }
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      case 'doc':
      case 'docx':
      case 'wpd':
      case 'tex':
        return 'doc';
      case 'xlsx':
      case 'xls':
        return 'xls';
      case 'zip':
      case 'rar':
        return 'zip';
      case 'ppt':
      case 'pptx':
        return 'pptx';
      default:
        return false;
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  async fileBrowseHandler(event: any) {
    // let x = await this.convertImageToBase64(event.target.files[0])
    // console.log("HELLO HERE ====>  ", x);
    // console.info(imageDimensions); // {width: 1337, height: 42}

    this.prepareFilesList(event.target.files);
    event.target.value = '';
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number, uploadedFileName: any) {

    if (uploadedFileName) {
      this.deletedFiles.push(uploadedFileName);
    }
    // console.log(this.deletedFiles);


    const deleted = this.files[index];
    this.files.splice(index, 1);

    if (this.isUploader) {
      this.files = this.uploadedFilesArr
      this.selectedFiles.emit(this.uploadedFilesArr);
    } else {
      this.selectedFiles.emit(this.files);
      this.deletedFilesList.emit(this.deletedFiles);
    }
    this.selectedfilesCount = this.files.length;
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: any) {
    // console.log(files);

    const filesToUpload = [];
    this.validFile = true;
    if (!(this.maxFiles && this.selectedfilesCount == 1)) {
      this.selectedfilesCount = this.files.length + files.length;
    }
    // else{

    // }
    if (this.selectedfilesCount > this.maxFiles) {

      this._config.toastrService.error(this._config.translate.instant(this.maxFiles === 1 ? 'fileManager.Only One File is allowed' : "fileManager.Maximum allowed files is " + this.maxFiles))

      return;
    }
    for (const item of files) {

      // (async () => {
      //   let x = await this.convertImageToBase64(item)
      //   console.log(x);
      //   if (x?.width < 800) {
      //     console.error("Error Width image ==> " + item.name);
      //     item.width = x?.width
      //     item.height = x?.height
      //   }
      // })();

      if (this.fileType(item.name)) {
        this.validFile = true;
      } else {
        this.validFile = false;
        this._config.toastrService.error(this._config.translate.instant("fileManager.Invalid File Extensions"))
        return
      }

      // Check file and push to array files
      if (item.size / (1024 * 1024) <= this.MaxFileSize && this.fileType(item.name)) {
        item.fileType = this.fileType(item.name);

        if (this.maxFiles && this.selectedfilesCount == 1) {
          this.files = [item];
          filesToUpload.pop();
          filesToUpload.push(item);
          this.handleFileSelect(item)

        } else {
          this.files.push(item);
          filesToUpload.push(item);
        }

        if (!this.isUploader) {

          this.selectedFiles.emit(this.files);
        }

        if (item.size / (1024 * 1024) <= this.MaxFileSize) {
          this.fileMaxSiZeErorr = false;
        }
      } else {
        if (item.size / (1024 * 1024) > this.MaxFileSize) {
          this.fileMaxSiZeErorr = true;
          this._config.toastrService.error(this._config.translate.instant('fileManager.Max File Size') + " " + this.MaxFileSize);
          return
        }
      }

      if ((!files.length && this.isRequired && this.isSubmited) && this.renderBtn) {
        this._config.toastrService.error(this.label + " " + this._config.translate.instant('fileManager.Required'))

      }


    }
    if (this.isUploader) {

      this.uploadFile(filesToUpload);
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadFile(files: any) {
    this.loading = true;
    this.Disapled = true;
    this.UploadLoading.emit(true)
    this._config.upload_file(files, this.uplodaerType).subscribe(
      (response: any) => {
        this.loading = false;
        this.Disapled = false;
        this.UploadLoading.emit(false)


        response.data.forEach((element: any) => {
          const index = this.files.findIndex((item: any) => item instanceof File && item.name === element.name)

          this.files[index] = element
        });
        // this.files = response.data

        if (this.Mode == "small" || "banner") {
          this.CurrentImageSelectedBase64 = response.data[0].path
        }
        this.uploadedFilesArr = this.files

        this.selectedFiles.emit(this.uploadedFilesArr);

      },
      (err: any) => {
        this.loading = false;
        this.Disapled = false;
        this.UploadLoading.emit(true)
        this._config.showErrors(err)
      }
    );
  }


  convertImageToBase64(file: any): any {

    // Encode the file using the FileReader API
    const promise = new Promise((resolve, reject) => {

      const reader = new FileReader();
      reader.onloadend = async () => {
        const imageDimensions = await this.imageSize(reader.result);

        // console.log(imageDimensions);
        // return imageDimensions
        resolve(imageDimensions);

        // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
      };
      reader.readAsDataURL(file);
    })
    return promise
  }



  // async/promise function for retrieving image dimensions for a URL
  imageSize(url: any) {
    const img = document.createElement("img");

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        // Natural size is the actual image size regardless of rendering.
        // The 'normal' `width`/`height` are for the **rendered** size.
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Resolve promise with the width and height
        resolve({ width, height });
      };

      // Reject promise on error
      img.onerror = reject;
    });

    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;

    return promise;
  }

  // How to use in an async function
  // (async () => {
  //   const imageUrl = 'http://your.website.com/userUploadedImage.jpg';
  //   const imageDimensions = await imageSize(imageUrl);

  //   console.info(imageDimensions); // {width: 1337, height: 42}
  // })();




  handleFileSelect(file: any) {
    if (file) {
      var reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    var binaryString = readerEvt.target.result;
    this.CurrentImageSelectedBase64 = 'data:image/png;base64,' + btoa(binaryString);
    // console.log(btoa(binaryString));
  }



}
