import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscriber, Subscription } from 'rxjs';
import { MenuService } from '../menu.service';
import { MenuModal } from './menu-modal';
import { PermissionsApiService } from './permissions-api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from 'src/app/core/service/config.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit, OnDestroy {
  menus: any;
  parseMenu: any = localStorage.getItem("menu");
  subscribtions: Subscription[] = [];
  year = new Date().getFullYear();

  constructor(
    private menu: MenuService,
    private menuService: PermissionsApiService,
    private sanitizer: DomSanitizer,
    public _config: ConfigService
  ) {

  }

  ngOnInit(): void {
    let TempMenu = localStorage.getItem("menu") || "";
    this.menus = TempMenu ? JSON.parse(TempMenu) : null;
    this.onGetMenu();
  }
  closeMenu() {
    this.menu.changeMessage(true);
  }


  onGetMenu() {
    // if (!this.parseMenu) {
    //   this.menuService.menu().subscribe({
    //     next: (data: any) => {
    //       this.menus = data.data;
    //       this.menus?.push({
    //         icon_menu: `<svg xmlns="http://www.w3.org/2000/svg" width="15.999" height="19.998" viewBox="0 0 15.999 19.998">
    //         <g id="Group_12304" data-name="Group 12304" transform="translate(-36.001 -214)">
    //           <path id="Path_23440" data-name="Path 23440" d="M15.885,0H5.828A1.815,1.815,0,0,0,4,1.8V16.489a1.815,1.815,0,0,0,1.828,1.8H15.885a1.815,1.815,0,0,0,1.828-1.8V1.8A1.815,1.815,0,0,0,15.885,0ZM8.716,3.543a2.205,2.205,0,1,1,3.2,2.571A1.037,1.037,0,0,0,11.39,7v.057a.571.571,0,0,1-1.143,0V7a2.183,2.183,0,0,1,1.1-1.88,1.06,1.06,0,0,0,.5-1.177,1.048,1.048,0,0,0-.774-.774,1.061,1.061,0,0,0-1.175.5,1.027,1.027,0,0,0-.095.214.572.572,0,1,1-1.091-.341Zm2.672,5.313a.406.406,0,0,1-.011.114.678.678,0,0,1-.029.1l-.051.1a1.01,1.01,0,0,1-.074.086.58.58,0,0,1-.406.166.478.478,0,0,1-.217-.046.567.567,0,0,1-.183-.12,1.01,1.01,0,0,1-.074-.086l-.051-.1-.034-.1a.873.873,0,0,1-.011-.114.581.581,0,0,1,.171-.406.591.591,0,0,1,.806,0A.575.575,0,0,1,11.388,8.857ZM14.857,16h-8a.571.571,0,1,1,0-1.143h8a.571.571,0,1,1,0,1.143Zm0-2.286h-8a.571.571,0,0,1,0-1.143h8a.571.571,0,1,1,0,1.143Zm0-2.286h-8a.571.571,0,0,1,0-1.143h8a.571.571,0,1,1,0,1.143Z" transform="translate(32.001 214)" fill="#70639e"/>
    //           <path id="Path_23441" data-name="Path 23441" d="M14962,15001.285h-10.089a1.8,1.8,0,0,1-1.685-1.145h10.058a1.821,1.821,0,0,0,1.814-1.795v-14.693a1.781,1.781,0,0,0-.129-.652,1.828,1.828,0,0,1,1.843,1.795v14.693A1.821,1.821,0,0,1,14962,15001.285Z" transform="translate(-14911.811 -14767.287)" fill="#70639e"/>
    //         </g>
    //       </svg>
    //       `,
    //         is_menu: 1,
    //         link: "/admin/requests",
    //         name: "Items Requests",
    //         token: "XaMrcgMMm9rDKTcRjcfXxvrJBA88999"
    //       })
    //       localStorage.setItem("menu", JSON.stringify(this.menus));
    //     }, error: (error: any) => {

    //       this.menus = JSON.parse(this.parseMenu);
    //     }
    //   })
    // } else {
    //   this.menus = JSON.parse(this.parseMenu);
    // }


    this.subscribtions.push(
      this.menuService.menu().subscribe({
        next: (data: any) => {
          this.menus = data.data;
          localStorage.setItem("menu", JSON.stringify(this.menus));

        }, error: (error: any) => {

        }
      }))

  }
  checkHaveIsMenu(child: any) {
    return child.find((a: any) => a.is_menu == 1);
  }
  sanitizerSVG(svg: string) {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscribtions.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }
}
