import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public lang = localStorage.getItem("language");
  constructor(
    public toastrService: ToastrService,
    public translate: TranslateService,
    public _router: Router,
    public _activatedRoute: ActivatedRoute,
    private apiService: ApiService
  ) { }

  showToastr(msg: string, status = 'error') {
    this.toastrService.clear();
    if (status == 'error') {
      this.toastrService.error(msg, '');
    } else {
      this.toastrService.success(msg, msg);
    }
  }

  upload_file(files: any, type: any) {

    const formData = new FormData();
    if (files && files.length) {
      files.forEach((file: any) => {
        formData.append('files[][file]', file);
      });
    }
    return this.apiService
      .post(`${environment.apiUrl}uploader`, formData)
      .pipe(catchError(this.handleError));
  }
  handleError(error: any) {
    return throwError(error);
  }

  goTo(url: string) {
    this._router.navigate([url])
  }

  showErrors(errors: any) {
    this.toastrService.clear();
    if (errors.errors) {
      errors.errors.forEach((err: any) => {
        this.toastrService.error(err.massage);
      });
    } else if (errors.message) {
      this.toastrService.error(errors.message);
    }

  }


  getAuthUserInfo() {
    const authUser: any = localStorage.getItem("auth");
    return JSON.parse(authUser);
  }


  menuActive(permissions: any) {
    //console.log(permissions.childParent);

  }

  checkPermission(permissionToken: string) {

  }
}
