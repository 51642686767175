import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WalletService } from '../../services/wallet.service';
import { ConfigService } from 'src/app/core/service/config.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-top-up-wallet',
  templateUrl: './top-up-wallet.component.html',
  styles: [],
})
export class TopUpWalletComponent implements OnInit {
  TransactionDone: boolean = false;
  dataLoading: boolean = false;
  paymentProvider = null;
  redirectLink: any = null;

  topUpForm: FormGroup = this._formbuilder.group({
    amount: new FormControl('', [Validators.required]),
  });
  loading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<TopUpWalletComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formbuilder: FormBuilder,
    private WalletService: WalletService,
    private _config: ConfigService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.paymentProvider = this.data.paymentProvider;
    if (this.paymentProvider) {
      this.getDataToPaymentGetway();
    }
  }

  getDataToPaymentGetway() {
    if (this.topUpForm.valid) {
      this.dataLoading = true;
      const data = {
        payment_provider: this.paymentProvider,
        amount: this.topUpForm.controls['amount'].value,
      };
      this.WalletService.paymentRequest(data).subscribe(
        (res) => {
          this.redirectLink =
            res.data.paymentConfirmationDomain +
            'paymentconfirmation/' +
            res.data.paymentConfirmationToken;
          console.log(this.redirectLink);
          // this.redirectLink =  'http://localhost:4200//paymentconfirmation/' + res.paymentConfirmationToken;
          console.log(this.loadJsScript(res.data.paymentGatewayURL));
          this.loadJsScript(res.data.paymentGatewayURL);

          this.dataLoading = false;
        },
        (err) => {
          this.dataLoading = false;
          this._config.showErrors(err);
          this.dialogRef.close();
          //this.toastr.warning(err.message);
        }
      );
    }
  }

  public loadJsScript(src: string): HTMLScriptElement {
    const oldScript = document.getElementById('e-payment-script');

    const script = this.renderer.createElement('script');
    script.id = 'e-payment-script';
    script.src = src;
    if (oldScript) {
      this.renderer.removeChild(this.document.body, oldScript);
    }
    // this.renderer.destroyNode(oldForm);
    this.renderer.appendChild(this.document.body, script);
    return script;
  }

  changeMethod(id: any) {
    if (this.topUpForm.valid) {
      // if (id != this.paymentProvider && this.dataLoading) {
      //   const oldScript = document.getElementById('e-payment-script');
      //   if (oldScript) {
      //     this.renderer.removeChild(this.document.body, oldScript);
      //   }
      //   const oldForm = document.getElementById('eFinanceForm');

      //   if (oldForm) {
      //     oldForm.remove();
      //   }
      //   this.dialogRef.close(id);
      // }
      if (!this.dataLoading && id) {
        const oldForm = document.getElementById('eFinanceForm');
        if (oldForm) {
          oldForm.remove();
        }
        this.paymentProvider = id;
        this.getDataToPaymentGetway();
      }
    } else {
      this.topUpForm.controls['amount'].markAsTouched();
    }
  }

  closePayment() {
    // this.dialogRef.close(false);
    window.location.reload();
  }

  onSubmit() {
    if (this.topUpForm.invalid) {
      return;
    }
    this.loading = true;

    this.WalletService.topupMyWallet({ ...this.topUpForm.value }).subscribe(
      (res: any) => {
        this.loading = false;

        this.TransactionDone = true;
      },
      (error: any) => {
        this.loading = false;
        this._config.showErrors(error);
      }
    );
  }

  reload() {
    location.reload();
  }
}
