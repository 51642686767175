<div class="dialog-title staffProfile" *ngIf="!loading">
    <div class="d-flex justify-content-between align-items-center w-100">
        <h3 *ngIf="staffDetailes?.roles[0]?.role_code=='Admin'">Admin Profile</h3>
        <h3 *ngIf="staffDetailes?.roles[0]?.role_code=='Auctioneer'||
        staffDetailes?.roles[0]?.role_code=='Master'">{{'items-requests.Staff Profile' | translate}}</h3>

        <div class="actions d-flex justify-content-end align-items-center" *ngIf="staffDetailes?.roles[0]?.role_code!='Admin'&&
         AuthUser?.user?.role_code=='Admin'">
            <!-- Delete Button -->
            <button mat-icon-button class="btnRed_fill rounded-circle" (click)="OnDeleteStaff(staffDetailes)"><svg
                    xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                    <path id="Path_19036" data-name="Path 19036"
                        d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V9a2.006,2.006,0,0,0-2-2H8A2.006,2.006,0,0,0,6,9ZM18,4H15.5l-.71-.71a1.009,1.009,0,0,0-.7-.29H9.91a1.009,1.009,0,0,0-.7.29L8.5,4H6A1,1,0,0,0,6,6H18a1,1,0,0,0,0-2Z"
                        transform="translate(-5 -3)" fill="#f53649" />
                </svg>
            </button>
            <!-- Update Button -->
            <button mat-icon-button *ngIf="staffDetailes?.is_verified" class="btnLight_fill rounded-circle"
                (click)="OnUpdateStaff()"><svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001"
                    viewBox="0 0 16.001 16.001">
                    <path id="Union_27" data-name="Union 27"
                        d="M.446,16A.44.44,0,0,1,0,15.557v-2.7a.437.437,0,0,1,.133-.32l9.7-9.7L13.164,6.17l-9.706,9.7A.417.417,0,0,1,3.147,16ZM10.782,1.886,12.408.26a.886.886,0,0,1,1.254,0l2.08,2.08a.885.885,0,0,1,0,1.253L14.115,5.22Z"
                        transform="translate(-0.001 0)" fill="#70639e" />
                </svg>
            </button>
            <!-- Resend Activation Code -->
            <button mat-button *ngIf="staffDetailes?.is_active&&!staffDetailes?.is_verified" class="btnLight_fill"
                (click)="ResendActivationCode(staffDetailes)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <path id="resend-verification-mail-icon"
                        d="M3.839,17.87A9.574,9.574,0,0,1-.392,7.228,9.7,9.7,0,0,1,3,2.043,9.877,9.877,0,0,1,7.624.009V-1.528A.556.556,0,0,1,8.465-2L11.978.456a.58.58,0,0,1,0,.967L8.465,3.872A.549.549,0,0,1,7.624,3.4L7.6,2.068A7.884,7.884,0,0,0,4.036,3.457,8.236,8.236,0,0,0,.919,7.625,8.458,8.458,0,0,0,4.209,17.3a.349.349,0,0,1,.075.472.333.333,0,0,1-.275.151A.3.3,0,0,1,3.839,17.87ZM4.5,16.694A1.131,1.131,0,0,1,3.443,15.5V7.822l7.58,3.369a1,1,0,0,0,.341.085.994.994,0,0,0,.341-.085l7.58-3.369V15.5a1.168,1.168,0,0,1-1.137,1.194ZM3.48,6.926V6.414a1.162,1.162,0,0,1,1.1-1.194H18.223a1.131,1.131,0,0,1,1.061,1.194v.512l-7.921,3.5Z"
                        transform="translate(0.715 2.078)" fill="#70639e" />
                </svg>
                {{'items-requests.Resend Verification Mail' | translate}}</button>

            <!-- Active Button -->
            <button mat-button *ngIf="!staffDetailes?.is_active && staffDetailes?.is_verified"
                (click)="OnChangeStatus('activate')" class="btnLight_fill">
                <svg xmlns="http://www.w3.org/2000/svg" width="18.004" height="10.802" viewBox="0 0 18.004 10.802">
                    <path id="activate-ic"
                        d="M933.473,458h-7.2a5.4,5.4,0,0,0,0,10.8h7.2a5.4,5.4,0,0,0,0-10.8Zm-7.2,9.3a3.9,3.9,0,1,1,3.9-3.9A3.9,3.9,0,0,1,926.271,467.3Zm10.259-5.308-3.225,3.44-.025.026a.4.4,0,0,1-.559-.026l-1.35-1.44a.463.463,0,0,1,0-.624l.013-.012a.4.4,0,0,1,.572.012l1.058,1.129,2.933-3.129.024-.024a.4.4,0,0,1,.56.024v0A.462.462,0,0,1,936.53,461.993Z"
                        transform="translate(-920.87 -458)" fill="#70639e" />
                </svg>
                {{'items-requests.Activate' | translate}}</button>


            <!-- Deactive Button -->
            <button mat-button *ngIf="staffDetailes?.is_active && staffDetailes?.is_verified"
                (click)="OnChangeStatus('deactivate')" class="btnLight_fill">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
                    <path id="deactivate-icon"
                        d="M910.643,466.955h-8a6,6,0,0,0,0,12h8a6,6,0,0,0,0-12Zm-8.093,8.25a.75.75,0,0,1-1.5,0v-4.5a.75.75,0,0,1,1.5,0Zm8.093,2.083a4.333,4.333,0,1,1,4.332-4.333A4.332,4.332,0,0,1,910.643,477.288Z"
                        transform="translate(-896.643 -466.955)" fill="#70639e" />
                </svg>
                {{'items-requests.Deactivate' | translate}}
            </button>

        </div>

    </div>
</div>

<div class="staffProfile" *ngIf="!loading">
    <div class="mainInfo">
        <div class="profileImage">
            <div class="image">
                <img *ngIf="staffDetailes?.image" [src]="staffDetailes?.image" alt="Staff Image Profile"
                    (error)="staffDetailes.image=''">
                <h3 *ngIf="!staffDetailes?.image">{{(staffDetailes?.firstname + ' ' +
                    staffDetailes?.lastname)|shortName}}
                </h3>
            </div>
            <div class="changeIcon">
                <app-file-manager label="Staff Profile Image" [renderBtn]="true" (selectedFiles)="onSelectFile($event)"
                    [maxFiles]="1" [allowedExt]="['png','jpg','jpeg']" managerIndex='staff_profile_image'
                    [MaxFileSize]="5" [Mode]="'small'"></app-file-manager>
            </div>
        </div>
        <h2 class="name">{{(staffDetailes?.firstname + " " +staffDetailes?.lastname)|titlecase}}</h2>

        <p class="role">{{staffDetailes?.roles[0]?.role_name | titlecase}}</p>

    </div>
    <div class="lastAction">
        <p class="inactive" *ngIf="!staffDetailes?.is_active">{{'users.inactive' | translate}}</p>
        <p class="active" *ngIf="staffDetailes?.is_active&& staffDetailes?.is_verified">{{'users.active' | translate}}
        </p>
        <p class="inactive" *ngIf="staffDetailes?.is_active && !staffDetailes?.is_verified">{{'users.not_verified' |
            translate}}</p>
        <div>
            <p class="date"><span>{{'items-requests.Added On' | translate}}:</span>{{staffDetailes?.created_at | dateUTC
                }}</p>
            <p class="date" *ngIf="staffDetailes?.suspend_at"><span>{{'items-requests.Deactivated on' |
                    translate}}:</span>
                {{staffDetailes?.suspend_at | dateUTC }}
            </p>
        </div>

    </div>
    <div class="detailes">
        <div class="item">
            <div class="lable">{{'auth.email' | translate}}</div>
            <div class="value"><a [href]="'mailto:'+staffDetailes?.email">{{staffDetailes?.email}}</a></div>
        </div>
        <div class="item">
            <div class="lable">{{'auth.phone' | translate}}</div>
            <div class="value">({{staffDetailes?.country_code}}){{staffDetailes?.phone}}</div>
        </div>
        <div class="item" *ngIf="staffDetailes?.is_national_number">
            <div class="lable">{{'items-requests.National ID' | translate}}</div>
            <div class="value">{{staffDetailes?.national_number}}</div>
        </div>
        <div class="item" *ngIf="!staffDetailes?.is_national_number">
            <div class="lable">{{'items-requests.Resident ID (Iqama)' | translate}}</div>
            <div class="value">{{staffDetailes?.resident_number}}</div>
        </div>
        <!-- <div class="item">
            <div class="lable">Gender</div>
            <div class="value">Male</div>
        </div> -->
    </div>
</div>

<app-loading *ngIf="loading"></app-loading>