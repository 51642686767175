<div class="request-timeline">
    <div class="request-timeline-container">
        <div class="row m-0">
            <div class="col-sm-12 header-timeline">{{'bidders.Request Log / History' | translate}}
                <span mat-button (click)="onClose()" class="p-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.998" height="13.998" viewBox="0 0 13.998 13.998">
                        <path id="close-btn"
                            d="M8.177-11.911,6.768-13.32l-5.59,5.59-5.59-5.59L-5.82-11.911l5.59,5.59L-5.82-.732-4.411.677l5.59-5.59L6.768.677,8.177-.732l-5.59-5.59Z"
                            transform="translate(5.82 13.32)" fill="#bbb" />
                    </svg>

                </span>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-sm-12" *ngIf="!loading">
                <div *ngFor="let item of requestLog; let i = index" class="container-timeline">
                    <!-- <div class="timeline-date">
                        {{ item.createdAt | dateUTC }}
                    </div> -->
                    <div class="badge-circle primary">
                        <div [class]="'timeline-line-' + i"></div>
                        <!-- <div [class]="item.color"></div> -->
                        <div class="timeline-line-tall" *ngIf="!requestLog.length"></div>
                    </div>
                    <div class="timeline-info">
                        <div class="timeline-date">
                            {{ item.createdAt | dateUTC }}
                        </div>
                        <div class="timeline-request-name">
                            {{ item.status }}
                        </div>
                        <div class="timeline-request-body" *ngIf="item.note">
                            {{'Auctions.Note' | translate}}: <i> {{ item.note }}</i>
                        </div>
                        <div class="info-badge bg-transparent p-0">
                            <div class="badge-img rounded-circle">
                                <img *ngIf="item.user_profile_img " [src]="item.user_profile_img "
                                    (error)='item.user_profile_img  = ""'>
                                <span *ngIf="!item.user_profile_img ">
                                    {{item?.createdBy | shortName }}</span>
                            </div>
                            <div class="badge-text">
                                <span class="label">{{'items-requests.Done by' | translate}}</span>
                                <p>{{item?.createdBy |titlecase}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div (click)="onViewDetails(item, item.circulated_token)" class="timeline-view-details">
                        View Details
                    </div> -->
                </div>
            </div>
            <app-loading *ngIf="loading"></app-loading>
        </div>
    </div>
</div>