import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, filter } from 'rxjs';
import { io } from "socket.io-client";
import { ConfigService } from 'src/app/core/service/config.service';
import { environment } from 'src/environments/environment';
import { SocketEvent, SocketEventType } from '../modal/socket-event-type';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  socket: any;
  token: string | undefined;
  private socketEvent!: Subject<any>;

  public message$: BehaviorSubject<string> = new BehaviorSubject('');
  private ListOfListener: string[] = [];
  constructor(
    public _config: ConfigService
  ) {
    this.socketEvent = new Subject<any>();

  }


  onOpenSocket() {
    this.token = this._config.getAuthUserInfo() ? this._config.getAuthUserInfo().token : '';
    this.socket = io(environment.socketUrl, {
      transports: ['websocket'],
      auth: {
        token: `${this.token}`
      }
    });
    this.socket?.on('room-or-auction-updated', (data: any) => {
      this.onMessage({ type: SocketEventType.RoomOrAuctionUpdated, data });
    });

  }

  onCloseSocket(){
    this.socket.disconnect();
    this.socket.close();
  }


  setupSocketEvents() {

    this.socket?.on('auth', (data: any) => {
      this.onMessage({ type: SocketEventType.Auth, data });
    });
    this.socket?.on('auctionNotVaild', (data: any) => {
      this.onMessage({ type: SocketEventType.Auction_Not_Valid, data });
    });
    this.socket?.on('UpdateBlance' + this._config.getAuthUserInfo().user.id, (data: any) => {
      this.onMessage({ type: SocketEventType.Update_Blance, data });
    });
    this.socket?.on('getRemaining', (data: any) => {
      this.onMessage({ type: SocketEventType.Get_Remaining, data });
    });

    this.socket?.on('getMyBlance', (data: any) => {
      this.onMessage({ type: SocketEventType.Get_My_Blance, data });
    });
    // this.socket?.on( auctionId, (data: any) => {
    //   this.onMessage({ type: SocketEventType.Auction_ID, data: { id: auctionId, response: data } });
    // });

    // this.socket?.on('reloadPage' + auctionId, (data: any) => {
    //   this.onMessage({ type: SocketEventType.Reload_Page, data: { id: auctionId, response: data } });
    // });
    this.socket?.on('priceNotVaild', (data: any) => {
      this.onMessage({ type: SocketEventType.Price_Not_Valid, data });
    });
    this.socket?.on('notEnoughFund', (data: any) => {
      this.onMessage({ type: SocketEventType.Not_Enough_Fund, data });
    });
    this.socket?.on('getBidders', (data: any) => {
      this.onMessage({ type: SocketEventType.Get_Bidders, data });
    });
  }

  ListenOnAuctionSocket(auctionId: string) {
    let Auction_ID_Finded = this.ListOfListener.some(listener => listener == auctionId)
    // debugger
    if (Auction_ID_Finded) {
      return
    } else {
      this.ListOfListener.push(auctionId);
      this.socket?.on(auctionId, (data: any) => {
        this.onMessageDynamicly({ type: auctionId, data: { id: auctionId, response: data } });
      });
      this.socket?.on("getRemaining"+auctionId, (data: any) => {
        this.onMessageDynamicly({ type: "getRemaining"+auctionId, data: { id: auctionId, response: data } });
      });

      this.socket?.on('reloadPage' + auctionId, (data: any) => {
        this.onMessageDynamicly({ type: `reloadPage${auctionId}`, data: { id: auctionId, response: data } });
      });
    }

  }

  ListenOnRoomSocket(roomID: string) {

    this.socket?.on(roomID, (data: any) => {
      this.onMessageDynamicly({ type: roomID, data: { id: roomID, response: data } });
    });

    this.socket?.on('reloadPage' + roomID, (data: any) => {
      this.onMessageDynamicly({ type: `reloadPage${roomID}`, data: { id: roomID, response: data } });
    });




    this.socket?.on(`itemClosed${roomID}`, (data: any) => {
      this.onMessageDynamicly({ type: `itemClosed${roomID}`, data: { id: roomID, response: data } });
    });



  }
  roomNotVaild(roomID: string) {
    this.socket?.on(`roomNotVaild${roomID}`, (data: any) => {
      this.onMessageDynamicly({ type: `roomNotVaild${roomID}`, data: { id: roomID, response: data } });
    });

  }
  roomClosed(roomID: string) {
    this.socket?.on(`roomClosed${roomID}`, (data: any) => {
      this.onMessageDynamicly({ type: `roomClosed${roomID}`, data: { id: roomID, response: data } });
    });
  }


  getActiveItem(roomID: string) {
    this.socket?.on(`getActiveItem`, (data: any) => {
      this.onMessageDynamicly({ type: `getActiveItem`, data: { id: roomID, response: data } });
    });
  }



  // public sendMessage(message:any) {
  //   this.socket.emit('message', message);
  // }

  // public getNewMessage = () => {
  //   this.socket.on('message', (message) =>{
  //     this.message$.next(message);
  //   });

  //   return this.message$.asObservable();
  // };

  private onMessage<TDataShape>(payload: SocketEvent<TDataShape>) {
    this.socketEvent.next(payload);
  }

  private onMessageDynamicly(payload: any) {
    this.socketEvent.next(payload);
  }

  getDataStream<TDataShape>(type: SocketEventType | string): Observable<SocketEvent<TDataShape>> {
    return this.socketEvent.asObservable().pipe(filter(event => type === event.type))
  }

}
