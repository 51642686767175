<mat-dialog-content>
    <p class="text-end p-3">
        <span mat-button mat-dialog-close>
            <svg xmlns="http://www.w3.org/2000/svg" width="13.998" height="13.998" viewBox="0 0 13.998 13.998">
                <path id="close-btn"
                    d="M8.177-11.911,6.768-13.32l-5.59,5.59-5.59-5.59L-5.82-11.911l5.59,5.59L-5.82-.732-4.411.677l5.59-5.59L6.768.677,8.177-.732l-5.59-5.59Z"
                    transform="translate(5.82 13.32)" fill="#bbb" />
            </svg>
        </span>
    </p>
    <div class="suspend">
        <ng-container *ngIf="data?.type=='activate'; else elseTemplate">
            <img src="assets/images/Unsuspend.svg" alt="">
            <h4 class="title">{{'items-requests.Activate staff account' | translate}}</h4>
            <div class="info-badge">
                <div class="badge-img rounded-circle">
                    <img *ngIf="data.user.image" [src]="data.user.image" (error)='data.user.image = ""'>
                    <span *ngIf="!data.user.image">
                        {{data.user.firstname +" "+ data.user.lastname | shortName }}</span>
                </div>
                <div class="badge-text">
                    <p class="name">{{data.user.firstname +" "+ data.user.lastname |titlecase}}</p>
                    <p class="head">{{data.user?.roles[0]?.role_name}}</p>
                </div>
            </div>
            <div class="hint">
                {{'items-requests.This user will be able to access our platform again' | translate}}
            </div>


            <div class="actions group_btn">
                <button mat-button mat-dialog-close class="btnLight_fill" *ngIf="!loading">
                    {{'items-requests.No, Cancel' | translate}}
                </button>
                <button mat-button class="btn_fill" type="submit" (click)="OnActivateStaff()"
                    [appLoading]="loading">{{'items-requests.Yes, activate account' | translate}}</button>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <img src="assets/images/suspend.svg" alt="">
            <h4 class="title">{{'items-requests.Deactivate staff account' | translate}}?</h4>
            <div class="info-badge">
                <div class="badge-img rounded-circle">
                    <img *ngIf="data.user.image" [src]="data.user.image" (error)='data.user.image = ""'>
                    <span *ngIf="!data.user.image">
                        {{data.user.firstname +" "+ data.user.lastname | shortName }}</span>
                </div>
                <div class="badge-text">
                    <p class="name">{{data.user.firstname +" "+ data.user.lastname |titlecase}}</p>
                    <p class="head">{{data.user.roles[0].role_name|titlecase}}</p>
                </div>
            </div>
            <div class="hint">
                {{'items-requests.This user will no longer have access to our platform' | translate}} </div>


            <div class="actions group_btn">
                <button mat-button mat-dialog-close class="btnLight_fill" *ngIf="!loading">{{'items-requests.No, Keep it' | translate}}</button>
                <button mat-button class="btn_fill" type="submit" (click)="OnDeactivateStaff()"
                    [appLoading]="loading">{{'items-requests.Yes, Deactivate account' | translate}}</button>
            </div>


        </ng-template>
    </div>
</mat-dialog-content>


<!-- </div> -->