import { Component, Input, OnInit } from '@angular/core';
import { MenuService } from '../adminpanel/menu.service';
import { SideNavConfigService, SideNavModal } from './side-nav-config.service';
import { ConfigService } from 'src/app/core/service/config.service';
import { SocketService } from 'src/app/features/bidders/core/service/socket.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styles: [
  ]
})
export class MainLayoutComponent implements OnInit {
  toggleDrawer!: SideNavModal;
  showOverlay: boolean = false;
  public currentUserSubject: BehaviorSubject<any>;
  @Input() isHome = false
  constructor(private _sideNavService: SideNavConfigService, private _config: ConfigService, private _socket: SocketService) {
    if (_config.getAuthUserInfo()) {
      _socket.onOpenSocket();
      _socket.setupSocketEvents();
    }

    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('auth')!)
    );
    window.addEventListener(
      'storage',
      () => {
        let userStorage: any = localStorage.getItem('auth');
        const userToken = JSON.parse(userStorage);
        if (
          (this.currentUserValue && localStorage.getItem('auth') && this.currentUserValue.token !== userToken.token) ||
          (this.currentUserValue && !localStorage.getItem('auth')) ||
          (!this.currentUserValue && localStorage.getItem('auth'))
        ) {
          this._config._router.navigate(['/'])
            .then(() => {
              window.location.reload();
            });
        }
      },
      false
    );
  }
  ngOnInit(): void {
    this._sideNavService.getSideNav().subscribe((res: any) => {
      this.toggleDrawer = res
    })
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  onClose(data: any) {
    console.log(data);
    this._sideNavService.ChangeSideNav({ status: false, type: this.toggleDrawer.type })
  }

  toggleOverlay() {
    this.showOverlay = !this.showOverlay
  }
}
