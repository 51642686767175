
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MaterialModule } from './material.module';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbComponent } from './Components/breadcrumb/breadcrumb.component';
import { FileManagerComponent } from './Components/file-manager/file-manager.component';
import { DateUTCPipe } from './pipes/date-utc.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { OnlynumberDirective } from './directives/onlynumber.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TimeRemainingPipe } from './pipes/TimeRemaining.pipe';
import { TimerComponent } from './Components/timer/timer.component';
import { LoadingComponent } from './Components/loading/loading.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { LoadingDirective } from './directives/loading.directive';
import { YoutubeStreamPipe } from './pipes/youtubeStream.pipe';
import { UserInfoCardComponent } from './Components/user-info-card/user-info-card.component';
import { ShortNamePipe } from './pipes/short-name.pipe';
import { AuctionListingComponent } from './Components/auction-listing/auction-listing.component';
import { WalletDetailesComponent } from './Components/wallet-detailes/wallet-detailes.component';
import { TopUpWalletComponent } from './Components/top-up-wallet/top-up-wallet.component';
import { MainAuctionCardComponent } from './Components/main-auction-card/main-auction-card.component';
import { AuctionDetailsComponent } from './Components/auction/auction-details/auction-details.component';
import { AdminAuctionConfigComponent } from './Components/auction/admin-auction-config/admin-auction-config.component';
import { ListBiddersComponent } from './Components/auction/list-bidders/list-bidders.component';
import { AuctionItemCardComponent } from './Components/auction-item-card/auction-item-card.component';
import { StartAuctionComponent } from './Components/auction/admin-auction-config/start-auction/start-auction.component';
import { EndAuctionComponent } from './Components/auction/admin-auction-config/end-auction/end-auction.component';
import { ReservePriceComponent } from './Components/auction/reserve-price/reserve-price.component';
import { IncreasePriceComponent } from './Components/auction/reserve-price/increase-price/increase-price.component';
import { AuctionEndCountDownComponent } from './Components/auction/auction-end-count-down/auction-end-count-down.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChangeStatusComponent } from './Components/auction/admin-auction-config/change-status/change-status.component';
import { SelectTranslatedTextDirective } from './directives/select-translated-text.directive';
import { DndDirective } from './directives/dnd.directive';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { OwnerAuctionConfigComponent } from './Components/auction/owner-auction-config/owner-auction-config.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { StaffProfileComponent } from './Components/staff-profile/staff-profile.component';
import { ChangeStatusStaffComponent } from './Components/staff-profile/change-status-staff/change-status-staff.component';
import { IsAdminDirective } from './directives/is-admin.directive';
import { AuctioneerAuctionConfigComponent } from './Components/auction/auctioneer-auction-config/auctioneer-auction-config.component';
import { AppMasterAuctionConfigComponent } from './Components/auction/app-master-auction-config/app-master-auction-config.component';
import { DeliveredAndCollectedComponent } from './Components/auction/app-master-auction-config/delivered-and-collected/delivered-and-collected.component';
import { ItemInfoComponent } from './Components/auction/item-info/item-info.component';
import { LIGHTBOX_CONFIG, LightboxModule } from 'ng-gallery/lightbox';
import { RequestLogComponent } from './Components/request-log/request-log.component';
import { ListRequestsComponent } from './Components/list-requests/list-requests.component';
import { AntMediaComponent } from './Components/auction/item-info/ant-media/ant-media.component';
import { BroadcastComponent } from './Components/auction/item-info/broadcast/broadcast.component';
import { KFormatPipe } from './pipes/k-format.pipe';
import { EfinanceFormComponent } from './Components/efinance-form/efinance-form.component';
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import { PaymentConfirmationPageComponent } from './Components/payment-confirmation-page/payment-confirmation-page.component';
import { AuctionWinnerPopUpComponent } from './Components/auction/auction-winner-pop-up/auction-winner-pop-up.component';
import { AuctionEndedPopUpComponent } from './Components/auction/auction-ended-pop-up/auction-ended-pop-up.component';
import { FilesViewerComponent } from './Components/files-viewer/files-viewer.component';
import { MatPaginationIntlService } from '../core/service/mat-pagination-intl.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MainRoomComponent } from './Components/multi-auction/main-room/main-room.component';
import { ListItemsAuctionComponent } from './Components/multi-auction/main-room/list-items-auction/list-items-auction.component';
import { MultiLiveBiddingComponent } from './Components/multi-auction/main-room/multi-live-bidding/multi-live-bidding.component';
import { JoinAndWithdrawRoomComponent } from './Components/multi-auction/main-room/multi-live-bidding/join-and-withdraw-room/join-and-withdraw-room.component';
import { ItemDetailesMultiComponent } from './Components/multi-auction/main-room/item-detailes-multi/item-detailes-multi.component';
import { ActionsBiddingMultiComponent } from './Components/multi-auction/main-room/multi-live-bidding/actions-bidding-multi/actions-bidding-multi.component';
import { JoinMultiRoomComponent } from './Components/multi-auction/main-room/multi-live-bidding/join-and-withdraw-room/join-multi-room/join-multi-room.component';
import { WithdrawMultiRoomComponent } from './Components/multi-auction/main-room/multi-live-bidding/join-and-withdraw-room/withdraw-multi-room/withdraw-multi-room.component';
import { TimerCircleMultiComponent } from './Components/multi-auction/main-room/multi-live-bidding/actions-bidding-multi/timer-circle-multi/timer-circle-multi.component';
import { ShowItemDetailesComponent } from './Components/multi-auction/main-room/show-item-detailes/show-item-detailes.component';
import { AuctionWinnerComponent } from './Components/multi-auction/main-room/multi-live-bidding/auction-winner/auction-winner.component';
import { AuctionNotWinnerComponent } from './Components/multi-auction/main-room/multi-live-bidding/auction-not-winner/auction-not-winner.component';
import { PaymentConfirmationComponent } from './Components/payment-confirmation/payment-confirmation.component';
import { HardRedirectComponent } from './Components/hard-redirect/hard-redirect.component';
import { OfflineComponent } from './Components/offline/offline.component';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    FileManagerComponent,
    DateUTCPipe,
    KFormatPipe,
    YoutubeStreamPipe,
    OnlynumberDirective,
    TimeRemainingPipe,
    TimerComponent,
    LoadingComponent,
    LoadingDirective,
    IsAdminDirective,
    UserInfoCardComponent,
    ShortNamePipe,
    AuctionListingComponent,
    WalletDetailesComponent,
    PaymentConfirmationPageComponent,
    TopUpWalletComponent,
    EfinanceFormComponent,
    MainAuctionCardComponent,

    AuctionDetailsComponent,
    AdminAuctionConfigComponent,
    ListBiddersComponent,
    AuctionItemCardComponent,
    StartAuctionComponent,
    EndAuctionComponent,
    ChangeStatusComponent,
    ReservePriceComponent,
    IncreasePriceComponent,
    AuctionEndCountDownComponent,
    SelectTranslatedTextDirective,
    DndDirective,
    OwnerAuctionConfigComponent,
    StaffProfileComponent,
    ChangeStatusStaffComponent,
    AuctioneerAuctionConfigComponent,
    DeliveredAndCollectedComponent,
    AppMasterAuctionConfigComponent,
    ItemInfoComponent,
    RequestLogComponent,
    ListRequestsComponent,
    AntMediaComponent,
    BroadcastComponent,
    AuctionWinnerPopUpComponent,
    AuctionEndedPopUpComponent,
    FilesViewerComponent,
    MainRoomComponent,
    ListItemsAuctionComponent,
    MultiLiveBiddingComponent,
    JoinAndWithdrawRoomComponent,
    ItemDetailesMultiComponent,
    ActionsBiddingMultiComponent,
    JoinMultiRoomComponent,
    WithdrawMultiRoomComponent,
    TimerCircleMultiComponent,
    ShowItemDetailesComponent,
    AuctionWinnerComponent,
    AuctionNotWinnerComponent,
    PaymentConfirmationComponent,
    HardRedirectComponent,
    OfflineComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({}),
    NgxIntlTelInputModule,
    MatDatepickerModule,
    CarouselModule,
    NgxMatTimepickerModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    LightboxModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      outerStrokeWidth: 5,
      innerStrokeWidth: 0,
      outerStrokeColor: "#FF6B6B",
      innerStrokeColor: "#C7E596",
      animationDuration: 0,
      backgroundStroke: '#FFE4E4',
      backgroundStrokeWidth: 5,
      backgroundPadding: -2.5,
      responsive: true,
      subtitle: 'Minute',
      showUnits: false,
      titleFontSize: '50',
      titleFontWeight: '700',
      titleColor: "#F53649",
      subtitleFontSize: "20",
      subtitleFontWeight: '400'
    }),
    InfiniteScrollModule

  ],

  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    NgxIntlTelInputModule,
    NgCircleProgressModule,
    BreadcrumbComponent,
    FileManagerComponent,
    DateUTCPipe,
    KFormatPipe,
    YoutubeStreamPipe,
    OnlynumberDirective,
    CarouselModule,
    TimeRemainingPipe,
    TimerComponent,
    NgxMatTimepickerModule,
    MatFormFieldModule,
    LoadingComponent,
    NgxMatTimepickerModule,
    LoadingDirective,
    IsAdminDirective,
    UserInfoCardComponent,
    ShortNamePipe,
    AuctionListingComponent,
    WalletDetailesComponent,
    PaymentConfirmationPageComponent,
    TopUpWalletComponent,
    EfinanceFormComponent,
    MainAuctionCardComponent,
    AuctionDetailsComponent,
    AdminAuctionConfigComponent,
    ListBiddersComponent,
    AuctionItemCardComponent,
    StartAuctionComponent,
    EndAuctionComponent,
    ChangeStatusComponent,
    ReservePriceComponent,
    IncreasePriceComponent,
    AuctionEndCountDownComponent,
    SelectTranslatedTextDirective,
    DndDirective,
    OwnerAuctionConfigComponent,
    AuctioneerAuctionConfigComponent,
    AppMasterAuctionConfigComponent,
    DeliveredAndCollectedComponent,
    InfiniteScrollModule,
    StaffProfileComponent,
    ItemInfoComponent,
    LightboxModule,
    RequestLogComponent,
    ListRequestsComponent,
    AntMediaComponent,
    BroadcastComponent,
    FilesViewerComponent,
    MainRoomComponent,
    ListItemsAuctionComponent,
    MultiLiveBiddingComponent,
    JoinAndWithdrawRoomComponent,
    ItemDetailesMultiComponent,
    ActionsBiddingMultiComponent,
    JoinMultiRoomComponent,
    WithdrawMultiRoomComponent,
    TimerCircleMultiComponent,
    ShowItemDetailesComponent,
    AuctionWinnerComponent,
    AuctionNotWinnerComponent,
    PaymentConfirmationComponent,
    HardRedirectComponent,
    OfflineComponent
  ],
  providers: [
    TimeRemainingPipe,
    DatePipe,
    DateUTCPipe,
    KFormatPipe,
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: false
      }
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginationIntlService,
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {
}

registerLocaleData(localeAr);
