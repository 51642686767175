import { Injectable } from '@angular/core';
  import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/core/service/api.service';
import { catchError, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PermissionsApiService {

  constructor(private apiService: ApiService,) { }



 menu() {
  return this.apiService
    .post(`${environment.apiUrl}userManagement/get_permissions_link`)
    .pipe(catchError(this.handleError));
}

  handleError(error:any) {
    return throwError(error);
  }
}
