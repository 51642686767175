import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SideNavConfigService, SideNavModal } from 'src/app/layout/main-layout/side-nav-config.service';
import { ChangeStatusStaffComponent } from './change-status-staff/change-status-staff.component';
import { StaffService } from 'src/app/features/user-managment/core/service/staff.service';
import { ConfigService } from 'src/app/core/service/config.service';
import { ConfirmComponent } from '../../actions/delete/confirm.component';
import { CreateStaffComponent } from 'src/app/features/user-managment/component/list-staff/create-staff/create-staff.component';

@Component({
  selector: 'app-staff-profile',
  templateUrl: './staff-profile.component.html',
  styles: [
  ]
})
export class StaffProfileComponent implements OnInit {
  sideNavDetailes!: SideNavModal;
  staffDetailes: any;
  loading: boolean = false;
  AuthUser: any;
  constructor(private _sideNavService: SideNavConfigService,
    private _staffService: StaffService,
    private _config: ConfigService,
    private dialog: MatDialog) {

  }

  ngOnInit(): void {
    this._sideNavService.getSideNav().subscribe((res: any) => {
      if (res.status && res.type == "staffProfile") {
        this.sideNavDetailes = res;
        this.getStaff();
        this.AuthUser = this._config.getAuthUserInfo()
      }
    })
  }

  onClose() {
    this._sideNavService.ChangeSideNav({ status: false, type: 'staffProfile' })
  }

  onSelectFile(files: any) {
    if (files.length == 0) {
      return;
    }
    // this.loading = true;
    const formData = new FormData();
    formData.append('image', files[0], files[0].name);
    formData.append('_method', 'PUT');

    this._staffService.changeProfileImage(this.sideNavDetailes.id, formData).subscribe({
      next: (data) => {
        this._config.toastrService.success(this._config.translate.instant('users.imageChange'));
        // let user = this._config.getAuthUserInfo()
        // if (user?.user?.id == data?.data?.id) {
        //   user.user = data.data
        //   localStorage.setItem("auth", JSON.stringify(user));
        //   window.location.reload();
        // }
        if (this._config.getAuthUserInfo()?.user?.id === this.staffDetailes.id) {
          let LogedUser = this._config.getAuthUserInfo()
          LogedUser.user = data.data;
          localStorage.setItem("auth", JSON.stringify(LogedUser));
          location.reload();
        }
        this.getStaff();
      }, error: (err) => {
        this._config.showErrors(err)
      }
    })
  }

  getStaff() {
    this.loading = true;
    this._staffService.getStaffById(this.sideNavDetailes.id || '').subscribe({
      next: (res) => {
        this.loading = false;
        this.staffDetailes = res.data;
        // this._config.toastrService.success(res.message);
      },
      error: (err) => {
        this.loading = false;
        this._config.showErrors(err);
      }
    })
  }


  OnDeleteStaff(staff: any) {
    let dataConfirmActions = {
      title: this._config.translate.instant("actions.delete"),
      icon: 'warning',
      text: this._config.translate.instant("actions.deleteTxt"),
      confirm: this._config.translate.instant("actions.confirm"),
      cancel: this._config.translate.instant("actions.cancel"),
      data: staff
    };

    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: dataConfirmActions,
      panelClass: 'customDialogStyle'
    });

    dialogRef.afterClosed().subscribe((res) => {

      if (res == 'success') {
        this.loading = true;
        this._staffService.deleteStaff(staff.id).subscribe({
          next: (data) => {
            this.loading = false;
            this._config.toastrService.success(this._config.translate.instant('users.deleteSuccessfully'));
            // this.GetStaff(this.StaffListModal);
            this.onClose();
          }, error: (error) => {
            this.loading = false;
            this._config.showErrors(error)
          }
        });

      }


    });
  }

  OnChangeStatus(type: "activate" | "deactivate") {
    const dialogRef = this.dialog.open(ChangeStatusStaffComponent, {
      width: '550px',
      data: { user: this.staffDetailes, type },
      panelClass: 'customDialogStyle'
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getStaff()
      }
    });
  }

  ResendActivationCode(staff: any) {
    const dataConfirmActions = {
      title: this._config.translate.instant("actions.confirmTitle"),
      icon: 'warning',
      text: this._config.translate.instant("actions.confirmTxt"),
      confirm: this._config.translate.instant("actions.confirm"),
      cancel: this._config.translate.instant("actions.cancel"),
    };

    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: dataConfirmActions,
      panelClass: 'customDialogStyle'
    });

    dialogRef.afterClosed().subscribe((res) => {

      if (res == 'success') {
        // this.loading = true;
        let id = staff?.id;
        this._staffService.ResendActivationCodeForStaff(id).subscribe({
          next: (data) => {
            this._config.toastrService.success("Activation code sended successfully");
          }, error: (error) => {
            this._config.showErrors(error)
          }
        });

      }


    });
  }

  OnUpdateStaff() {
    const dialogRef = this.dialog.open(CreateStaffComponent, {
      width: "540px",
      panelClass: 'customDialogStyle',
      data: this.staffDetailes.id
    })

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getStaff()
      }
    })
  }
}
