<ng-container *ngIf="dataLoading">
  <app-loading></app-loading>
</ng-container>

<ng-container *ngIf="!dataLoading">

  <div class="d-flex" style="height: 100vh">
    <div class="data-empty p-0 m-auto text-center">
      <img src="./assets/images/icons/notEnoughFund.svg" />
      <div class="w-100 mt-3 text-center">
        <h1>{{"Payment Operation Failed,"|translate}}<br> {{"bidders.please try again."|translate}}</h1>
        <!-- <p>Payment Operation Failed</p> -->

        <button mat-button class="btnLight_fill my-4" (click)="location.back();">{{'items-requests.Go Back' |
          translate}}</button>

      </div>
    </div>
  </div>
</ng-container>